import {SAVE_TOKEN} from "../reducers/Common";
import {USER_LOGOUT} from "../store/index";

const saveToken = (data) => {
  return {
    type: SAVE_TOKEN, 
    token: data
  };
};

const deleteToken = () => {
  return {
    type: USER_LOGOUT
  }
}

export default { saveToken, deleteToken };