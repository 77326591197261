import React, { useEffect, useState, useRef } from "react"; 
import { Card, message } from "antd";
import { Link, useParams } from "react-router-dom";
import { language, genFunctions, requests, VariableTrigger, ConfirmModal, projectsActions } from "../_index";
import { connect } from "react-redux";
import ReactFlow, { Background, Handle, ReactFlowProvider } from 'react-flow-renderer';
import { workflowActions } from "../../workflows/_index";

const WorkflowCard = ({workflow, medEventsData, access_token, deleteWorkflowComp, deleteWorkflowBisComp}) => {
  
  const confirmRef = useRef();
  const modalRef = useRef();
  const {id} = useParams();

  const [flow, setFlow] = useState([]);

  useEffect(() => {
  // Creation of the flow elements from the actions and connections of the project
    let flowChart = [
    ];

    const promise = new Promise((resolve, reject) => {
      workflow.actions.map((action,i) => {
        let item = {
          id: `${action.name}${i}`,
          data: {name: action.name, label: action.name, type: action.type },
          position: { x: 300, y: 20},
          className: "workflow-card"
        };
        flowChart.push(item);
      });
      resolve(flowChart);
    });

    promise.then(res => {
      Object.entries(workflow.connections).map(([key, value]) => {
        let action1 = findAction1(res, key);
        if (workflow.connections[key].next && !workflow.connections[key].onFailure){
          let action2 = findAction2(res, key, "next");
          let item = action1.data.type.includes('condition') ? createItem(action1, action2, "next") : createItem(action1, action2);
          res.push(item);

          let index = res.map(action => action.id).indexOf(action2.id);
          action2.position.y = action1.position.y + 80 ;
          if (action1.position.x !== 400) action2.position.x = action1.position.x
          res[index] = action2;

        } else if (workflow.connections[key].onFailure && !workflow.connections[key].next ){
          let action2 = findAction2(res, key, "onFailure");
          let item = action1.data.type.includes('condition') ? createItem(action1, action2, "onFailure") : createItem(action1, action2);
          res.push(item);

          let index = res.map(action => action.id).indexOf(action2.id);
          action2.position.y = action1.position.y + 80 ; 
          res[index] = action2;

        } else if (workflow.connections[key].next && workflow.connections[key].onFailure){
          let action2 = findAction2(res, key, "next");
          let action3 = findAction2(res, key, "onFailure");

          let item1 = createItem(action1, action2, "next");
          let item2 = createItem(action1, action3, "onFailure");
          res.push(item1, item2);

          let index = res.map(action => action.id).indexOf(action2.id);
          action2.position.x = action1.position.x -150 ; action2.position.y = action1.position.y + 100 ; 
          res[index] = action2;

          let index2 = res.map(action => action.id).indexOf(action3.id);
          action3.position.x = action1.position.x + 150 ; action3.position.y = action2.position.y;
          res[index2] = action3;
        };
      });

      setFlow(res);
    });

  }, []);

// ==================== FLOW FUNCTIONS ==================== //
  const findAction1 = (array, key) => {
    let action1 = array.filter(item => item.data?.name == key)[0];
    return action1;
  };

  const findAction2 = (array, key, type) => {
    let action2 = array.filter(item => item.data?.name === workflow.connections[key][type][0].name)[0];

    return action2;
  };

  const createItem = (action1, action2, label) => {
    let item ={
      id: `e${action1.id}-${action2.id}`,
      source: action1.id,
      target: action2.id,
      labelStyle: {
        textTransform: "uppercase",
        fontSize: "14px",
      }
    }
    if (label) item.label = label;

    return item;
  }

// ==================== MODAL FUNCTIONS ==================== //
  const showModal = () => {
		confirmRef.current.showModal();
	};

// ==================== WORKFLOW FUNCTIONS ==================== //
// ------------------ Delete workflow from project ------------------ //
  const deleteWorkflow = () => {
    requests.requestDelete(`/projects/${workflow.ProjectId}/workflows/${workflow.id}`, access_token)
    .then(res => {
      deleteWorkflowComp(res.data.ProjectId, res.data.id);
      deleteWorkflowBisComp(res.data.id);
      message.success(`Le workflow ${workflow.label} a bien été supprimé`);
    })
    .catch(error => {
      console.log(error.response);
      message.error(`Erreur lors de la suppression du workflow ${workflow.label}: ${error.response.data.message}`)
    })
  };

// ------------------ Save flow in localStorage to find it in update workflow ------------------ //
  const saveFlow = () => {
    localStorage.setItem('flow', JSON.stringify(flow));
  };


  return (
    <Card title={`${workflow.label}`} className="workflow-card-project">
      <div className="trash-workflow-project flex-between">
      <Link to={`/project/${id}/update-workflow/${workflow.id}`}>  <i onClick={saveFlow} className="icon icon-edit icon-hover pointer"/> </Link>
        <i className="icon icon-trash pointer icon-hover"  onClick={() => showModal("confirm")} />
      </div>
      <p>{workflow.description} </p>

      <div className="triggers-part">
        <div className="trigger">
          <h4>{language["workflow.medEvent.triggers"]} </h4>

          <ul>
            {workflow.MedEventWorkflowRules?.length ? 
              workflow.MedEventWorkflowRules.map((trigger, i)  => (
                <li key={`${i}medEvent`} className="triggers-list no-list-style">
                  <h5>{genFunctions.findMedEvent(trigger.MedEventId, medEventsData, workflow.id)} </h5>
                  <p className="trigger-paragraph">
                    <span className="margin-r-5">
                      <span className="bold">{language["workflow.diff"]} </span>
                        {trigger.secondsDiff === 0 ? 
                         language["common.none"]
                        : 
                          genFunctions.secondsToDhms(trigger.secondsDiff)
                        } 
                        <br/> 
                      <span className="bold">{language["workflow.atTime"]}</span>                     
                      <span>
                        {trigger.atTime ? trigger.atTime.substring(0,5) : language["common.none"]}
                      </span> <br/>
                    </span>
                    <span>
                      <span className="bold">{language["workflow.triggerOnCreate"]} </span> {!trigger.triggerOnCreate ? "Aucun" : `${trigger.triggerOnCreate}`} <br/>
                      <span className="bold">Extended</span>: {trigger.extended ? "Oui" :"Non"}
                    </span>
                  </p>
                </li>
              ))
              : language["workflow.no.trigger"]
            }
          </ul>
        </div>

        <div className="trigger">
          <h4>{language["workflow.patient.triggers"]}</h4>

          <ul>
            {workflow.PatientWorkflowRules?.length ?
              workflow.PatientWorkflowRules.map((trigger, i) => (
                <li key={`${i}patient`} className="triggers-list no-list-style">
                  <span className="bold">{language["workflow.triggerOnCreate"]} </span> {!trigger.triggerOnCreate ? "Aucun" : `${trigger.triggerOnCreate ? "Oui" :"Non"}`} <br/>
                </li>										
              )) : language["workflow.no.trigger"]
            }
          </ul>
        </div>

        <div className="trigger">
          <h4>{language["workflow.variables.triggers"]}</h4>
          <ul>
            {workflow.VariableWorkflowRules?.length ?
              workflow.VariableWorkflowRules.map((rule, i) => (
                <li key={Math.floor(Math.random() * 10000)} className="triggers-list no-list-style" id="variable-trigger-card">
                  <VariableTrigger key={Math.floor(Math.random() * 10000)+i} variableWorkflowRules={rule} />
                </li>
              )) : language["workflow.no.trigger"]
            }
          </ul>
        </div>
      </div>
      <div className="workflow-part">
        <div className="main-workflow">
          <ReactFlowProvider>
            <ReactFlow
              elements={flow}
              deleteKeyCode={workflow.id}
              nodesDraggable={false}
              elementsSelectable={false}
              nodesConnectable={false}
            >
            <Background
            variant="dots"
            gap={22}
            size={1}
            />
            <Handle
            position="bottom"
            />
            </ReactFlow>
          </ReactFlowProvider>
        </div>

      </div>

      <ConfirmModal ref={confirmRef} content={ <div>{language["workflows.delete"]}</div>} button={true} confirmParent={deleteWorkflow} />
    </Card>
  )
};

export default connect ( 
  (state) => ({
    medEventsData: state.workflows.medEvents,
    access_token: state.common.token,
    projectsData: state.projects
  }),
  (dispatch) => ({
    deleteWorkflowComp: (projectId, workflowId) => dispatch(projectsActions.deleteWorkflowFromProject(projectId, workflowId)),
    deleteWorkflowBisComp: (workflowId) => dispatch(workflowActions.deleteWorkflow(workflowId))
  })
) (WorkflowCard);