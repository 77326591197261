import React, { useRef, useEffect } from "react";
import { connect } from "react-redux";
import { message } from "antd";
import { language, requests, AddIcon, BasicModal, LocationCard, projectsActions,CreateLocation } from "../_index";
import { useParams } from "react-router";

const Locations = ({project, access_token, addLocationToProjectComp, projectsData }) => {
	
  const modalRef = useRef();
  const {id} = useParams();

  useEffect(() => {}, projectsData)

// ==================== MODAL FUNCTIONS ==================== //
  const showModal = () => {
      modalRef.current.showModal();
  };

  const closeModal = () => {
      modalRef.current.closeModal();
  };


// ==================== LOCATIONS FUNCTIONS ==================== //
// ------------------ Create location  ------------------ //
  const saveLocation = item => {
    requests.requestPost(`/projects/${id}/locations`, item, access_token)
    .then(res => {
      let locations = project.locations;
        locations.push(res.data);
        addLocationToProjectComp(id, locations)
        message.success('Le nouveau lieu a bien été enregistré');
    })
    .catch(error => {
      console.log(error.response);
      message.error(`Problème lors de la création du lieu: ${error.response.data.message}`);
    });
  };	

  return (
    <div className="display-list locations-height">
      <h3> {language["locations"]} </h3>
      <button onClick={() => {showModal()}} className="btn-no-style add-icon-btn no-link" size="large" > <span className="add-icon"><AddIcon /></span> {language["locations.add"]} </button>

      {project.locations?.length ? (
          project.locations.map(location => (
            <LocationCard key={location.id} locations={project.locations} location={location} projectId={project.id} />
          ))
        ):(
          <div className="gx-card-widget gx-card-profile-sm no-user-card">
            {language["no.locations"]}
          </div>
        )}

      <BasicModal ref={modalRef} content={<CreateLocation saveItemParent={saveLocation} cancelForm={closeModal} project={project} />} /> 
    </div>
  )
};

export default connect(
  (state) => ({
    access_token: state.common.token,
    projectsData: state.projects
  }),
  (dispatch) => ({
    addLocationToProjectComp: (projectId, locations) => dispatch(projectsActions.addLocationToProject(projectId, locations)),
  })
) (Locations)
