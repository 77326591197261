import React from "react";
import { language } from "../_index.js"

const CopilotConversationCard = ({action, deleteActionParent, showModalParent, type}) => {

	const showModal = (e) => {
		e.preventDefault();
		showModalParent(action)
	};

	const deleteAction = () => {
		deleteActionParent(action)
	};
	
	return(
		<div className="action-card">
				<div className="flex-between">
					<i onClick={deleteAction} className="icon icon-close icon-hover pointer icon-delete-action"/>
					<div className="action-title">
						<h3>{action.name} </h3> 
						<span>({action.type})</span>
					</div>
					{type === "update" ? <span/> : <i onClick={(e) => showModal(e, action.type)} className="icon icon-edit icon-hover pointer icon-edit-action"/>}
				</div>

				<span><span className="bold">{language["workflow.parameters"]}</span> <br/> <br/><pre className="json-display" > {JSON.stringify(action.parameters, null, 1)} </pre> </span>
					
		</div>
	)
};

export default CopilotConversationCard;