import React from "react";
import {Link} from "react-router-dom";

const SidebarLogo = () => {
  return (
    <div className="gx-layout-sider-header">

      <Link to="/" className="logo-div" >
        <img className="logo" alt="Curecall" src={("/assets/images/curecall-development.png")}/>
        <h1 className="logo-title">Curecall</h1>
      </Link>
    </div>
  );
};

export default SidebarLogo;
