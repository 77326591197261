import { SAVE_USERS_ACTION, SAVE_NEW_USER_ACTION, UPDATE_USERS_ACTION } from "../reducers/usersReducer"

const saveUsers = data => ({
    type: SAVE_USERS_ACTION,
    users: data
});

const saveNewUser = data => ({
    type: SAVE_NEW_USER_ACTION, 
    user: data
});

const updateUsers = data => ({
    type: UPDATE_USERS_ACTION,
    users: data
}); 

export default { saveUsers, saveNewUser, updateUsers }