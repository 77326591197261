import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Radio, message } from "antd";
import { requests, projectsActions } from "../_index";

const RadioGroup = Radio.Group;

const ChangeAccess = ({cancelForm, user, userProject, project, updateAccessComp, access_token, updateAccessParent}) => {
	
	const [access, setAccess] = useState(userProject.access || "");


// ==================== FORM FUNCTIONS ==================== //
	const handleState = (e) => {
		setAccess(e.target.value)
	};

	
// ==================== SAVE FUNCTIONS ==================== //
	const handleForm = (e) => {
		e.preventDefault();
		if (access !== userProject.access) {
			requests.requestPut(`/projects/${project.id}/users/${user.id}`, {access: access}, access_token)
			.then(res => {
				let newUser = {...user, UserProject: res.data};
				let index = project.Users.map(item => item.id).indexOf(user.id);
				project.Users[index] = newUser;
				updateAccessComp(project);
				// if (updateAccessParent) updateAccessParent(access);
				message.success(`Le niveau d'accès de ${user.firstName ? user.firstName : user.firstname } ${user.lastName ? user.lastName : user.lastname} a bien été modifié en: ${access}`)
			})
			.catch(error => {
				console.log(error);
				message.error(`Problème avec le changement de l'accès: ${error}`);
			});
		};
		cancelForm();
	};


  return(
		<div className="change-access">
			<h1>{user.title} {user.firstName ? user.firstName : user.firstname} {user.lastName ? user.lastName : user.lastname} </h1>

			<form>
				<div className="form">
					<RadioGroup label="access" value={access} className="radio-access">
						<Radio value="guest" className="radio-form" onClick={handleState}>Guest</Radio>
						<Radio value="owner" className="radio-form" onClick={handleState}>Owner</Radio>
          </RadioGroup>
				</div>

				<div className="button-div">
					<Button type="primary" size="small" onClick={handleForm} >Ok</Button>
				</div>
			</form>

		</div>
	)};

	export default connect(
		(state) => ({
			access_token: state.common.token
		}),
		(dispatch) => ({
			updateAccessComp: (project) => dispatch(projectsActions.updateAccessUserProject(project))
		})
	)(ChangeAccess);
	