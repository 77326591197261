import { SAVE_MED_EVENTS_ACTION, SAVE_VARIABLES_ACTION, SAVE_WORKFLOWS, ADD_WORKFLOW, DELETE_WORKFLOW } from "../reducers/workflowsReducer"

const saveWorkflows = data => ({
    type: SAVE_WORKFLOWS, 
    workflows: data
});

const addWorkflow = data => ({
    type: ADD_WORKFLOW, 
    workflow: data
});

const deleteWorkflow = data => ({
    type: DELETE_WORKFLOW,
    workflowId: data
});
 
const saveMedEvents = data => ({
    type: SAVE_MED_EVENTS_ACTION,
    medEvents: data
});

const saveVariables = data => ({
    type: SAVE_VARIABLES_ACTION,
    variables: data
})

export default { saveMedEvents, saveVariables, saveWorkflows, addWorkflow, deleteWorkflow }