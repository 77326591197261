import React, { useRef } from "react";
import { message } from "antd";
import { connect } from "react-redux";
import { language, BasicModal, UpdateMedAlert, requests, projectsActions } from "../_index";

const MedAlertCard = ({alert, projectId, medAlerts, access_token, updateMedAlertComp}) => {
	
	const modalRef = useRef(); 

// ==================== MODAL FUNCTIONS ==================== //
	const showModal = () => {
		modalRef.current.showModal();
	};

	const closeModal = () => {
		modalRef.current.closeModal();
	};

// ==================== MED ALERTS FUNCTIONS ==================== //
// ------------------ Update medAlert ------------------ //
	const updateItem = (medAlert, id) => {
		requests.requestPut(`/projects/${projectId}/med-alerts/${id}`, medAlert, access_token)
		.then(res => {
			let index = medAlerts.map(alert => alert.id).indexOf(res.data.id);
			medAlerts[index] = res.data;
			updateMedAlertComp(projectId, medAlerts);
			message.success('L\'alerte a bien été modifiée')
			closeModal();
		})
		.catch(error => {
			console.log(error);
			message.error(`Erreur lors de la modification de l\'alerte: ${error.response.data.message}`);
		})
	};

// ------------------ Dlete medAlert ------------------ //
	const deleteAlert = () => {
		// Route inexistante
	};

	return (
		<div className="med-alert-info user-card" >
			<div  onClick={showModal}>
				<div className="flex">
					<div className="med-alert-name">
						<h5>{alert.label} </h5>
					</div>
					<span className="gx-d-inline-block gx-toolbar-separator">&nbsp;</span>
					<span> {alert.type} </span>
				</div>

				{alert.description? (
						<span><span className="bold">{language["common.description"]} </span>{alert.description} </span>
				): <> </> }

			</div>
			<BasicModal ref={modalRef} content={<UpdateMedAlert medAlert={alert} updateItemParent={updateItem} cancelForm={closeModal} />} /> 
			{/* <ConfirmModal ref={modalRef} content={ <div>Supprimer cette alerte du projet</div>} button={true} confirmParent={deleteAlert} /> */}
		</div>
	)
}

export default connect (
	(state) => ({
		access_token: state.common.token
	}),
	(dispatch) => ({
		updateMedAlertComp: (projectId, medAlerts) => dispatch(projectsActions.updateMedAlerts(projectId, medAlerts))
		// deleteMedAlertComp: (projectId, medAlertId) => dispatch(projectsActions.deleteMedAlert(projectId, medAlertId))
	  })
) (MedAlertCard);