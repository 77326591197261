import React, { useEffect, useState } from "react";
import { Button, Checkbox, Radio, message, Form } from "antd";
import { connect } from "react-redux";
import { genFunctions, requests, projectsActions, language } from "../_index";

const RadioGroup = Radio.Group;

const AddUser = ({project, cancelForm, usersData, addUserToProjectComp, access_token}) => {
  const [usersProject, setUsersProject] = useState([]);
  const [availableUsers, setAvailableUsers] = useState([])

  useEffect(() => {
    let hideItems = document.querySelectorAll('.hide');
    hideItems.forEach(item => {
      item.style.display='none';
    });

    let resultsUsers = usersData.filter(el => !project.Users.map(el => JSON.stringify(el.id)).includes(JSON.stringify(el.id)));
    setAvailableUsers(resultsUsers);  
  }, [project, usersData]);

// ==================== FORM FUNCTIONS ==================== //
// ------------------ Handling of the showing of radio buttons ------------------ //
  const showAccess = (id) => {
    let finalUsers = [...usersProject];
    let radio = document.getElementById(`radio-group-${id}`);
    if (radio.style.display === 'none' || !radio.style.display){  
      genFunctions.showItems(radio);
      let newUser = {
        UserId: id, 
        access: ""
      };
      finalUsers.push(newUser);
      setUsersProject(finalUsers)
    } else {
      genFunctions.hideItems([radio]);
      
			let index = usersProject.map((user, i) => user.UserId).indexOf(id);
			finalUsers.splice(index, 1);
			setUsersProject(finalUsers);
    };
  };

  // ------------------ Adding user to project in state ------------------ //
	const addUserToProject = (access, id) => {
		let finalUsers = [...usersProject];
		let index = usersProject.map((user, i) => user.UserId).indexOf(id);


		if ( index === -1){
			let newUser = {
				UserId: id, 
				access
			};
			finalUsers.push(newUser);
		} else {
			finalUsers[index].access = access;
		};

		setUsersProject(finalUsers);
	};

  // ==================== SAVE FUNCTION ==================== //
  const handleForm = async () => {
    if (usersProject.length){
      let error = usersProject.filter(user => !user.access);
      let arrayNames = error.map(error => {
        let name = usersData.filter(user => user.id == error.UserId)[0];
        name = ` ${name.firstName} ${name.lastName}`;
        return name;
      });

      if (arrayNames.length){
        message.error(`Vous n'avez pas défini d'accès pour: ${arrayNames}`)
      } else {
        Promise.all(
          usersProject.map(async user => {
            return requests.requestPost(`/projects/${project.id}/users`, user, access_token)
              .then(res => {
                usersProject.length > 1 ? message.success('Les utilisateurs ont bien été ajoutés au projet') 
                : message.success('L\'utilisateur a bien été ajouté au projet') 
                return res.data;
              })
              .catch(error => {
                console.log(error.response.data.message);
                message.error(`Erreur lors de la création d'un UserProject: ${error.response.data.message} `)

              })
          })
        ).then(values => {
          let newAvailableUsers = [...availableUsers];

          values.forEach(value => {
            newAvailableUsers = newAvailableUsers.filter(availableUser => availableUser.id !==  value.UserId);
            let user = usersData.filter(e => e.id === value.UserId)[0]
            let userToSave = {
              UsersProject: value,
              firstname: user.firstName,
              id: user.id,
              lastname: user.lastName,
              title: user.title
            };
            addUserToProjectComp(project.id, userToSave);
          })
          setAvailableUsers(newAvailableUsers);
        })
        .finally(() => {
          cancelForm();
          setUsersProject([]);
        });
      };
    } else {
      message.warning('Vous n\'avez sélectionné aucun utilisateur')
    }
  };


  return(
    <div className="inside-modal add-user">
      <h1> {language["project.addUser"]} </h1>

      <Form
        name="add-user"
        onFinish={handleForm}
        className="create-location-main inside-modal">
        <div className="select-user-list-div">
          <ul className="select-user-list">  
              {availableUsers.map(user => (
                  <li key={user.id} className="no-list-style add-user-item">
                    <Checkbox onChange={() => showAccess(user.id)} className="checkbox-form" >{user.title} {user.firstName} {user.lastName} </Checkbox>
                    <RadioGroup id={`radio-group-${user.id}`} className="radio-access hide">
                      <Radio value="guest" className="radio-form" onClick={(e) => addUserToProject(e.target.value, user.id)}>Guest</Radio>
                      <Radio value="owner" className="radio-form" onClick={(e) => addUserToProject(e.target.value, user.id)}>Owner</Radio>
                    </RadioGroup>
                  </li>
              ))}
          </ul>
        </div>

        <div className="button-div">
					<Form.Item>
						<Button type="primary" size="small" htmlType="submit" >Ok</Button>
					</Form.Item>
				</div>
      </Form>
    </div>
  )};

  export default connect(
    (state) => ({
      usersData: state.users,
      access_token: state.common.token,
    }),
    (dispatch) => ({
      addUserToProjectComp: (project, user) => dispatch(projectsActions.addUserToProject(project, user))
    })   
    )(AddUser);
    