import React, { useState } from "react";
import { connect } from "react-redux";
import { InputNumber, Select, Button, Checkbox, message } from "antd";
import { language } from "../_index.js"

const Option = Select.Option;

const MedEventTrigger = ({cancelForm, medEventsData, addTriggerParent}) => {
	
	const propTime = ['minDiff', 'dayDiff', 'hourDiff', 'atTimeHour', 'atTimeMin'];
	const medEvent = {	MedEventId: "",
	minDiff: 0,
	dayDiff: 0,
	hourDiff: 0,
	WorkflowId: "",
	triggerOnCreate: undefined,
	atTimeHour: 0,
	atTimeMin: 0,
	after: false,
	extended: undefined};

	const [checkedExtended, setCheckedExtended] = useState(false);
	const [checkedAfter, setCheckedAfter] = useState(false);
	const [medEventTrigger, setMedEventTrigger] = useState(medEvent);

	const closeModal = () =>  {
		cancelForm();
	}

// ==================== FORM FUNCTIONS ==================== //
// ------------------ State ------------------ //
	const handleState = (prop) => (event) => {
		if (propTime.includes(prop) && event === null) event = {target: {value: 0}}
		if (prop  === "triggerOnCreate" && event === null) event = {target: {value: null}};
		event.target ? setMedEventTrigger({ ...medEventTrigger, [prop]: event.target.value }) : setMedEventTrigger({ ...medEventTrigger, [prop]: event });
	};

// ------------------ Handling of the checkbox ------------------ //
	const handleCheck = (prop) => (event) => {
		if (prop === 'extended') {
			setCheckedExtended(!checkedExtended);
			setMedEventTrigger({...medEventTrigger, extended: !checkedExtended});
		} else if (prop === "after"){
			setCheckedAfter(!checkedAfter);
			setMedEventTrigger({...medEventTrigger, after: !checkedAfter});
		}
	};

	const handleMandatory = (e) => {
		e.target.classList.remove('mandatory-input');
	};


// ==================== SAVE FUNCTIONS ==================== //
	const saveTrigger = () => {
		if (!medEventTrigger.MedEventId){
			 message.error('Le champ "Événement médical" est obligatoire');
			 let inputs = document.querySelectorAll('.check-value');
			 inputs.forEach(input => {
				 if (!medEventTrigger[input.title]) input.classList.add("mandatory-input");
			 }); 
		}else {
		let days = medEventTrigger.dayDiff * 86400
		let hours = medEventTrigger.hourDiff * 3600;
		let minutes = medEventTrigger.minDiff * 60;
		const secondsDiff = days + hours + minutes;

		const atTime = medEventTrigger.atTimeHour === 0 && medEventTrigger.atTimeMin === 0 ? 
		null : `${medEventTrigger.atTimeHour < 10 ? `0${medEventTrigger.atTimeHour}` : medEventTrigger.atTimeHour}:${medEventTrigger.atTimeMin < 10 ?`0${medEventTrigger.atTimeMin}` : medEventTrigger.atTimeMin }+02:00`

		let trigger = medEventTrigger;

		trigger.secondsDiff = medEventTrigger.after ? secondsDiff : parseInt(`-${secondsDiff}`); 
		trigger.atTime = atTime;

		delete trigger.atTimeHour;	delete trigger.atTimeMin;	delete trigger.hourDiff;	delete trigger.dayDiff;	delete trigger.minDiff; delete trigger.after;	

		cancelForm();
		setMedEventTrigger(medEvent);
		setCheckedExtended(undefined);
		setCheckedAfter(undefined);
		addTriggerParent("MedEventWorkflowRules", trigger);
		};
	};

	
	return (
		<form className="trigger-form inside-modal">
			<h4 className="title-no-margin trigger-input">{language["workflow.medEvent"]} </h4>
			<Select title="MedEventId" className="select-width check-value" placeholder="Événement" value={medEventTrigger.MedEventId} onChange={handleState('MedEventId')} onClick={handleMandatory}>
				{medEventsData.map(event => (
						<Option key={event.id} value={event.id}>{event.name}</Option>
				))}
			</Select>

			<h3 className="med-trigger-title">{language['form.time.trigger']} </h3>

			<h4 className="title-no-margin trigger-input"> {language["workflow.diff"]} </h4>
			<div className="max-age-seconds"> 
				<div>
					<h6>{language["workflow.days"]}</h6>
					<InputNumber label="DD" size="small" className="time-input" value={medEventTrigger.dayDiff} onChange={handleState('dayDiff')} /> 
				</div>
				<div>
					<h6>{language["workflow.hours"]}</h6>
					<InputNumber label="HH" size="small" className="time-input" value={medEventTrigger.hourDiff} onChange={handleState('hourDiff')}/> <span className="two-dots-link">: </span>
				</div>
				<div>
				<h6>{language["workflow.minutes"]}</h6>
					<InputNumber label="MM" size="small" className="time-input" value={medEventTrigger.minDiff} onChange={handleState('minDiff')}/>
				</div>
			</div>
			<Checkbox onChange={handleCheck('after')} className="trigger-input" checked={checkedAfter} value={checkedAfter}>Postérieur à l'Événement</Checkbox>

			<h4 className="title-no-margin time-title trigger-input">{language["workflow.atTime"]}</h4>
			<div className="max-age-seconds"> 
				<div>
					<h6>{language["workflow.hours"]}</h6>
					<InputNumber label="HH" size="small" className="time-input" value={medEventTrigger.atTimeHour} onChange={handleState('atTimeHour')}/> <span className="two-dots-link">: </span>
				</div>
				<div>
					<h6>{language["workflow.minutes"]}</h6>
					<InputNumber label="MM" size="small" className="time-input" value={medEventTrigger.atTimeMin} onChange={handleState('atTimeMin')}/>
				</div>
			</div>
			<Checkbox onChange={handleCheck('extended')} className="trigger-input" checked={checkedExtended} value={checkedExtended}>Extended</Checkbox>

			<h3 className="med-trigger-title">{language['form.meet.trigger']} </h3>

			<h4 className="title-no-margin  trigger-input">{language["workflow.triggerOnCreate"]} </h4>
			<Select className="select-width" value={medEventTrigger.triggerOnCreate} onChange={handleState('triggerOnCreate')}>
				<Option value={'ALL'} >{language["workflow.triggerOnCreate.all"]}</Option>
				<Option value={'FIRST'}>{language["workflow.triggerOnCreate.first"]} </Option>
				<Option value={'ALL_BUT_FIRST'}>{language["workflow.triggerOnCreate.allButFirst"]} </Option>
				<Option value={null}>{language["common.none"]}</Option>
			</Select>

			<div className="button-div">
				<Button type="primary" size="small"onClick={saveTrigger} >Ok</Button>
			</div>
		</form>

	)
};
export default connect (
    (state) => ({
			medEventsData: state.workflows.medEvents
		})
) (MedEventTrigger)