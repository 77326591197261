import React, { useRef, useEffect } from "react";
import { message } from "antd";
import { connect } from "react-redux";
import { language, CreateMedAlert, requests, MedAlertCard, AddIcon, BasicModal, projectsActions } from "../_index";
import { useParams } from "react-router";

const MedAlerts = ({project, access_token, projectsData, addAlertToProjectComp}) => {
	
	const modalRef = useRef();
	const {id} = useParams();

	useEffect(() => {}, projectsData)
	
// ==================== MODAL FUNCTIONS ==================== //
	const showModal = () => {
		modalRef.current.showModal();
	};

	const closeModal = () => {
		modalRef.current.closeModal();
	};

// ==================== MED ALERTS FUNCTIONS ==================== //
// ------------------ Create medAlert  ------------------ //
	const saveItem = item => {
		requests.requestPost(`/projects/${id}/med-alerts`, item, access_token)
		.then(res => {
			let medAlerts = project.medAlerts;
			medAlerts.push(res.data);
			addAlertToProjectComp(id, medAlerts);
			message.success('L\'alerte médicale a bien été enregistrée');
		})
		.catch(error => {
			console.log(error);
			message.error(`Problème lors de la création de l\'alerte: ${error.response.data.message} `);
		});
	};	

	return (
		<div className="med-alerts-main">
			<h3> {language["medAlerts"]} </h3>
			<button onClick={() => {showModal()}} className="btn-no-style add-icon-btn no-link" size="large" > <span className="add-icon"><AddIcon /></span> {language["medAlerts.add"]} </button>

			{project.medAlerts?.length ? (
				<ul className="med-alert-list ">
					{project.medAlerts.map(alert => (
						<MedAlertCard key={alert.id} alert={alert} projectId={project.id} medAlerts={project.medAlerts} />
					))}
				</ul>
				):(
					<div className="gx-card-widget gx-card-profile-sm no-user-card">
						{language["no.medAlerts"]}
					</div>
				)}

			<BasicModal ref={modalRef} content={<CreateMedAlert saveItemParent={saveItem} cancelForm={closeModal} />} /> 
		</div>
	)
};

export default connect(
  (state) => ({
    access_token: state.common.token,
		projectsData: state.projects
  }),
	(dispatch) => ({
    addAlertToProjectComp: (projectId, medAlerts) => dispatch(projectsActions.addMedAlertsToProject(projectId, medAlerts)),
  })
)(MedAlerts);
