import React from "react";
import { language } from "../_index.js"

const MedAlertCard = ({action, deleteActionParent, medAlerts, showModalParent, type}) => {

	const showModal = (e) => {
		e.preventDefault();
		showModalParent(action)
	};
	const deleteAction = () => {
		deleteActionParent(action)
	};

	const findMedAlert = (id) => {
		let alert = medAlerts.filter(item => item.id === id)[0];
		if (!alert) alert = {label: "Pas de label"};

		return alert.label;
	};

	return(
		<div className="action-card">
		<div className="flex-between">
			<i onClick={deleteAction} className="icon icon-close icon-hover pointer icon-delete-action"/>
			<div className="action-title">
				<h3>{action.name} </h3> 
				<span>({action.type})</span>
			</div>
		 {type === "update" ? <span/> : <i onClick={(e) => showModal(e, action.type)} className="icon icon-edit icon-hover pointer icon-edit-action"/>}
		</div>

			{action.parameters.label? <span><span className="bold">{language["common.label"]}:</span> {action.parameters.label} </span> : <></>}
			<span><span className="bold">{language["medAlert.type"]}:</span> {action.parameters.ui ? action.parameters.ui : "Pas de badge"} </span><br/>
			<span><span className="bold">{language["medAlert"]}:</span> {action.parameters.MedAlertId ? findMedAlert(action.parameters.MedAlertId) : <span className="mandatory-color"> À COMPLÉTER </span>} </span>

</div>
	)
};

export default MedAlertCard;