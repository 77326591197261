import React from "react";
import { Select } from "antd";
import { connect } from "react-redux";
import { language } from "../_index.js"
import Workflow from "./Flow.js";

const Option = Select.Option;

const ChooseWorkflow = ({ loadWorkflowParent, workflows, projectsData }) => {
    const chooseWorkflow = (value) => {   
        loadWorkflowParent(value);        
    };

    const findProject = (id) => {
        let project = projectsData.filter(pj => pj.id === id)[0];

        return project.name;
    };

    return(
        <div className="inside-modal choose-workflow">
            <div id="choose-action-form" >
                <h4>{language["workflow.choose.workflow"]} </h4>
                <div className="choose-workflow">
                    <Select onChange={chooseWorkflow} className="workflow-input select-width" placeholder="Workflows">
                        {workflows.map(workflow => (
                          <Option key={workflow.id} value={workflow.id}>{findProject(workflow.ProjectId)} | {workflow.label} </Option>
                        ))}
                    </Select>
                </div>
            </div>
        </div>
    )
}

export default connect (
  (state) => ({
    workflowsData: state.workflows.workflows,
    projectsData: state.projects
  })
) (ChooseWorkflow);