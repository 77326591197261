import React, { useState, useEffect } from "react";
import { Button, Input, Select, message } from "antd";
import { ArrowBackIosNewRoundedIcon, language, genFunctions } from "../_index";

const Option = Select.Option;

const ConditionMedEventLocation = ({locations, saveActionParent, cancelForm, goBackParent, action}) => {

	const initState = {
		name: "",
		locationId: "",
		type: "condition.med-event.location"
	};

	const [condition, setAction] = useState(initState);
	

	useEffect(() => {
		if (action) setAction({...condition, 
			name: action.name,
			locationId: action.parameters.locationId,
			id: action.id
		});

		let hideItems = document.querySelectorAll('.hide');
		hideItems.forEach(item => {
			item.style.display='none'
		});

	}, []);



	const handleState = (prop) => (event) => {
		setAction(event.target ? {...condition, [prop]: event.target.value} : {...condition, [prop]: event});
	};

	const goBack = (e) => {
		goBackParent(e);
	};

	const handleMandatory = (e) => {
		e.target.classList.remove('mandatory-input');
	};


	const saveAction = () => {
		if (!condition.name || !condition.locationId){
			const fields = document.querySelector('#mandatory-fields');
			genFunctions.showItems(fields);

			let inputs = document.querySelectorAll('.check-value');
			inputs.forEach(input => {
				if (!condition[input.title]) input.classList.add("mandatory-input");
			});

		} else {
			let finalAction = {
				type: condition.type,
				name: condition.name,
				parameters: {
					locationId: condition.locationId
				}
			};
			if (action) finalAction.id = condition.id;
			
			if (JSON.stringify(finalAction) !== JSON.stringify(action)) saveActionParent(finalAction);
			cancelForm();
		};
	};

	return(
		<div className="choose-action-card">
		{!action ? <Button className="go-back-action" size="small" onClick={goBack}> <span className="icon-back"><ArrowBackIosNewRoundedIcon style={{fontSize: "14px"}} /></span> <span className="back">{language["common.goBack"]} </span></Button> : <> </>}
			<h4>{language["workflow.medEvent.location"]} </h4>
			<small id="mandatory-fields" className="mandatory-color hide">Vous n'avez pas rempli tous les champs obligatoires</small>
        <div>
          <Input placeholder="Nom *" label="name" value={condition.name} onChange={handleState('name')} className="workflow-input check-value" onClick={handleMandatory} />
		<br/>
					<h5 className="trigger-input"> {language["locations"]}* : </h5>
          <Select label="place" value={condition.locationId} placeholder="Lieu" title="locationId" onChange={handleState('locationId')} className="workflow-input check-value" onClick={handleMandatory} >
						{locations?.map(location => (
							<Option key={location.id} value={location.id}>{location.name} {location.comment ? `(${location.comment})` : <> </>} </Option>
						))}
					</Select>

          <div className="button-div">
					<Button type="primary" size="small" onClick={saveAction}>Ok</Button>
				</div>
      </div>
		</div>
	)};

export default ConditionMedEventLocation;