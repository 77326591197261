import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Checkbox, message } from "antd";
import { language } from "../_index.js"

const PatientTrigger = ({cancelForm, addTriggerParent}) => {

	const [checked, setChecked] = useState(false);

	const closeModal = () => {
		cancelForm();
	};

	const handleCheck = () => {
		setChecked(!checked);
	};

	const saveTrigger = () => {
		if (checked) {
			let patientTrigger = {
				triggerOnCreate: checked,
				WorkflowId: ""
			};
			
			addTriggerParent("PatientWorkflowRules", patientTrigger);
		} else {
			message.info('Le trigger n\'a pas été créé car le champ "Déclenchement n\'a pas été rempli');
		}

		cancelForm();
	};

	return(
		<form className="trigger-patient inside-modal">
			<h4 className="title-no-margin">{language["workflow.triggerOnCreate"]} </h4>
			
			<Checkbox onChange={handleCheck} className="checkbox-form trigger-title" checked={checked} value={checked}>{language["workflow.triggerOnCreate.patient"]}</Checkbox>

			<div className="button-div">
				<Button type="primary" size="small"onClick={saveTrigger}>Ok</Button>
			</div>
		</form>
	)
};

export default connect (
    (state) => ({

    })
) (PatientTrigger);