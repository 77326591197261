import React from "react";
import {Layout} from "antd";

import SidebarContent from "./SidebarContent";

const {Sider} = Layout;

const Sidebar = () => {

  return (
    <Sider
      className={`sider-sidebar gx-layout-sider-dark`}
      collapsed={false}
      theme="dark"
      collapsible={false}>
      <SidebarContent />
    </Sider>)
};
export default Sidebar;
