let comparators = [
    {dbName: "gt", displayName: "Supérieur(e) à:"},
    {dbName: "gte", displayName: "Supérieur(e) ou égal(e) à:"},
    {dbName: "eq", displayName: "Égal(e) à:"},
    {dbName: "eqBool", displayName: "Égal(e) à:"},
    {dbName: "eqString", displayName: "Égal(e) à:"},
    {dbName: "lte", displayName: "Inférieur(e) ou égal(e) à:"},
    {dbName: "lt", displayName: "Inférieur(e) à:"},
    {dbName: "neq", displayName: "Différent(e) de:"},
    {dbName: "neqString", displayName: "Différent(e) de:"},
];

const findVariableName = (id, variablesData) => {
    let variable = variablesData.filter(v => v.id == id)[0];
    let result = variable.label ? variable.label : variable.name
    return result;
};

const findKeyName = (key) => {
    let name = comparators.filter(item => item.dbName === key)[0];
    return name.displayName;
};

const findValue = (value) => {
    if (value === true) value = "Oui";
    else if (value === false) value = "Non";
    return value;
};

const findMedEvent = (medEventsData, id) => {
    let event = medEventsData.filter(event => event.id == id)[0];
    return event.name
};

const functions = { findVariableName, findKeyName, findValue, findMedEvent };
export default functions;