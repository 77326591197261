import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Button, Input, message, Form } from "antd";
import { ArrowBackIosNewRoundedIcon, AddIcon, BasicModal, MedEventTrigger, requests, workflowActions, PatientTrigger, VariablesTrigger, utils, projectsActions, 
	Flow, ChooseWorkflow, genFunctions, language, VariableTrigger, ConfirmModal, formItemLayout } from "../_index.js";
import { connect } from "react-redux";

const { TextArea } = Input;

const CreateWorkflow = ({workflowsData, medEventsData, access_token, variablesData, saveNewWorkflow, projectsData, addWorkflowComp}) => {
	const modalRef = useRef();
	const scrollRef = useRef();
	const flowRef = useRef();
	const confirmRef = useRef();
	const {id} = useParams();
	const history = useHistory(); 
	const project = projectsData.filter(project => project.id == id)[0];
	const medAlerts = project.medAlerts;
	const locations = project.locations;
	const [form] = Form.useForm();


	const initState = {
		label: "",
		description: "",
		actions: [{
				id: "Start0",
				name: "Start",
				type: "internal.start",
				parameters: {}
			  }],
		connections: {},
		MedEventWorkflowRules: [],
		PatientWorkflowRules: [],
		VariableWorkflowRules:[],
	};

	const [content, setContent] = useState("");
	const [workflow, setWorkflow] = useState(initState);

	useEffect(() => {
		let hideItems = document.querySelectorAll('.hide');
		hideItems.forEach(item => {
			item.style.display='none'
		});

	}, [workflow]);

	const executeScroll = () => scrollRef.current.scrollIntoView();
// ==================== MODAL FUNCTIONS ==================== //
	const showModal = (e, type) => {
		e.preventDefault();
		if (type === "medEventTrigger") setContent(<MedEventTrigger cancelForm={closeModal} addTriggerParent={addTrigger} /> )
		else if (type === "patientTrigger") setContent(<PatientTrigger cancelForm={closeModal} addTriggerParent={addTrigger} />)
		else if (type === "variablesTrigger") setContent(<VariablesTrigger cancelForm={closeModal} addTriggerParent={addTrigger} />);
		else if (type === "workflows") setContent(<ChooseWorkflow loadWorkflowParent={loadWorkflow} workflows={workflowsData} /> )

		modalRef.current.showModal();
	};

	const closeModal = () => {
		modalRef.current.closeModal();
	};


// ==================== FORM FUNCTIONS ==================== //
// ------------------ State ------------------ //
	const handleState = (prop) => (event) => {
		event.target ? setWorkflow({ ...workflow, [prop]: event.target.value }) : setWorkflow({ ...workflow, [prop]: event });
	};

// ------------------ Pre-save trigger from child component ------------------ //
	const addTrigger = (type, trigger) => {
		let array = workflow[type];
		array.push(trigger) 
	
		setWorkflow({...workflow, [type]: array});
	};

// ------------------ Remove trigger form list ------------------ //
	const deleteTrigger = (i, type) => {
		let array = workflow[type];
		array.splice(i, 1);
		setWorkflow({ ...workflow, [type]: array });
	};

	const loadWorkflow = async (value) => {
		if (value) {
			let workflowToFind = workflowsData.filter(flow => flow.id === value)[0];
			let errors = [];
			
			let wkLoc = workflowToFind.actions.filter(action => action.type === "condition.med-event.location")
			let wkAlert = workflowToFind.actions.filter(action => action.type === "medAlert");
			
			if (wkLoc.length && !project.locations.length) errors.push('Le workflow demandé contient un appel à un lieu, mais aucun lieu n\'a été  attribué au projet. ')
			if (wkAlert.length && !project.medAlerts.length) errors.push('Le workflow demandé contient un appel à une alerte médicale, mais aucune alerte médicale n\'a été  attribuée au projet. ')

			if (errors.length){
				errors.unshift('Impossible d\'importer ce workflow: ');
				message.warning(errors, 10);
			} else {
				let workflowToLoad = {
					MedEventWorkflowRules: [...workflowToFind.MedEventWorkflowRules],
					PatientWorkflowRules: [...workflowToFind.PatientWorkflowRules],
					VariableWorkflowRules: [...workflowToFind.VariableWorkflowRules],
					actions: [...workflowToFind.actions],
					connections: {...workflowToFind.connections},
					label: workflowToFind.label,
					description: workflowToFind.description,
					loaded: true
				}; 

				form.setFieldsValue({name: workflowToLoad.label, description: workflowToLoad.description})
				
				let triggers = ['MedEventWorkflowRules', 'PatientWorkflowRules', 'VariableWorkflowRules'];
				triggers.map(trigger => {
					if (workflowToLoad[trigger].length){
						workflowToLoad[trigger]?.map(wftrigger => {
							delete wftrigger.createdAt; delete wftrigger.updatedAt; delete wftrigger.deletedAt; delete wftrigger.WorkflowId;
							if (wftrigger.MedEvent) delete wftrigger.MedEvent;
							if (trigger != 'VariableWorkflowRules') {
								delete wftrigger.id; 
								delete wftrigger.ProjectId
							}
						});
					};
				});

				workflowToLoad.actions.forEach(action => {
					if (action.type === "condition.med-event.location"){
						action.parameters.locationId = "";
						message.warning('Attention: Veuillez sélectionner un lieu dans l\'action "condition.med-event.location".', 10)
					} else if (action.type === "medAlert"){
						message.warning('Attention: Veuillez sélectionner une alerte médicale dans l\'action "medAlert". ', 10)
						action.parameters.MedAlertId = "";
					};
				});


				setWorkflow(workflowToLoad);
				closeModal();
			}
		} else {
			message.warning("Ce workflow n'a pas d'id")
		};
	};


// ------------------ Remove loaded parameter from workflow ------------------ //
	const stopLoaded = () =>  {
		setWorkflow({...workflow, loaded: false});
	}

// ==================== ACTIONS FUNCTIONS ==================== //
// ------------------ Pre-save action in state ------------------ //
	const saveAction = (action) => {
		let actions = workflow.actions; 
		actions.push(action);

		setWorkflow({...workflow, actions: actions});
	};


// ------------------ Update action in state ------------------ //
	const updateAction = (action) => {
		let index = workflow.actions.map(el => el.id).indexOf(action.id);
		let actions = workflow.actions;
		actions[index] = action;
		setWorkflow({...workflow, actions});
	};


// ------------------ Delete action from state ------------------ //
	const deleteAction = (id) => {
		let actions = workflow.actions
		let index = actions.map(action => action.id).indexOf(id);
		actions.splice(index, 1);
		setWorkflow({...workflow, actions: actions})
	};

	
// ==================== SAVE FUNCTIONS ==================== //

  const checkFlow = () => {
    if (flowRef.current.elements.length === 1) {
      confirmRef.current.showModal()
    } else {
      saveWorkflow(flowRef.current.elements);
    }
  }

// ------------------ Create connections object from flow elements ------------------ //
	const createConnections = (elements) => {
		let connections = {};

		elements.map(element => {
      if (element.source){
				let source = workflow.actions.filter(action => action.id === element.source)[0];
        let target = workflow.actions.filter(action => action.id === element.target)[0];
				!connections[source.name] ? connections[source.name] = {} : connections[source.name] = {...connections[source.name]};

				if (!element.label) element.label = "next";
				if (element.label === "next") {
					connections[source.name].next= [{
						name: target.name
					}]
				}else {
					connections[source.name].onFailure = [{
							name: target.name
					}];
				};

			}
		});
		return connections;
	};

// ------------------ Save workflow ------------------ //
	const saveWorkflow = () => {
		let elements = flowRef.current.elements; 
		if (!workflow.label) {
			const fields = document.querySelector('#mandatory-fields');
			genFunctions.showItems(fields);

			let inputs = document.querySelectorAll('.check-value');
			inputs.forEach(input => {
				if (!workflow[input.title]) input.classList.add("mandatory-input");
			});

			executeScroll();
	
		} else {
			// let res = {id: 89};

			let workflowToSave = {...workflow};

			delete workflowToSave.MedEventWorkflowRules; delete workflowToSave.PatientWorkflowRules; delete workflowToSave.VariableWorkflowRules;  delete workflowToSave.loaded;

			workflowToSave.connections = createConnections(elements);
			let actions = [...workflow.actions];
			actions.map(action => {
				delete action.id
			});

			workflowToSave.actions = actions;

			requests.requestPost(`/projects/${id}/workflows`, workflowToSave, access_token)
			.then(res => {
				res = res.data
	// ----- Handling of the medEvent workflow rules ----- //
				if (workflow.MedEventWorkflowRules.length) {
					let medTriggers = workflow.MedEventWorkflowRules
						workflow.MedEventWorkflowRules.forEach((rule, i) => {
							rule.WorkflowId = res.id;

							requests.requestPost(`/projects/${id}/med-event-workflow-rules`, rule, access_token)
							.then(medEvent => {
								medTriggers[i] = medEvent.data;
							})
							.catch(error => {
								console.log(error);
								message.error('Erreur lors de la création de medEventTrigger')
							});

						});
						res.MedEventWorkflowRules = medTriggers;
				};

	// ----- Handling of the Patient workflow rules ----- //
				if (workflow.PatientWorkflowRules.length) {
					let patientTriggers = workflow.PatientWorkflowRules;
						workflow.PatientWorkflowRules.forEach((rule, i) => {
							rule.WorkflowId = res.id;

							requests.requestPost(`/projects/${id}/patient-workflow-rules`, rule, access_token)
							.then(patientRule => {

								patientTriggers[i] = patientRule.data;
							})
							.catch(error => {
								console.log(error);
								message.error('Erreur lors de la création de patientEventTrigger')
							});
					});
					res.PatientWorkflowRules = patientTriggers;
				};

	// Handling of the variables workflow rules ----- //
				if (workflow.VariableWorkflowRules.length) {
					let variablesTriggers = workflow.VariableWorkflowRules;
					variablesTriggers.map((rule, i) => {
						if (!rule.variables){
							requests.request(`/projects/${rule.ProjectId}/variable-workflow-rules/${rule.id}/variable-rules`, access_token)
							.then(response => {
								let trigger = {
									WorkflowId: res.id,
									variables: []
								};
								response.data.forEach(variable => {
									let item = {
										VariableId: variable.VariableId, 
										eq: variable.eq, eqBool: variable.eqBool, eqString: variable.eqString, gt: variable.gt, gte: variable.gte, lt: variable.lt, lte: variable.lte, neq: variable.neq, neqString: variable.neqString
									};
									trigger.variables.push(item);
								});

								requests.requestPost(`/projects/${id}/variable-workflow-rules`, {WorkflowId: res.id}, access_token)
								.then(variableWorkflowRule => {
									variablesTriggers[i] = variableWorkflowRule.data;
									trigger.variables.map(variable => {
										requests.requestPost(`/projects/${id}/variable-workflow-rules/${variableWorkflowRule.data.id}/variable-rules`, variable, access_token)
										.then()
										.catch(error => {
											message.error('Erreur lors de la création de VariablesRule')
											console.log(error);
										});
									});
								})
								.catch(error => {
									console.log(error);
									message.error('Erreur lors de la création de VariableTRigger')
								});
							})
							.catch(error => {
								console.log(error);
							});
						} else {
								requests.requestPost(`/projects/${id}/variable-workflow-rules`, {WorkflowId: res.id}, access_token)
								.then(variableWorkflowRule => {
									variablesTriggers[i] = variableWorkflowRule.data;
									rule.variables.map(variable => {
										requests.requestPost(`/projects/${id}/variable-workflow-rules/${variableWorkflowRule.data.id}/variable-rules`, variable, access_token)
										.then()
										.catch(error => {
											message.error('Erreur lors de la création de VariablesRule')
											console.log(error);
										});
									});
								})
								.catch(error => {
									console.log(error);
									message.error('Erreur lors de la création de VariableTRigger')
								});

						};
						res.VariableWorkflowRules = variablesTriggers

					});

				};

				message.success('Le workflow a bien été créé');
				saveNewWorkflow(id, res);
				addWorkflowComp(res);
				setTimeout(function(){
					history.push(`/project/${id}`)
				}, 800);
				setWorkflow(initState);

			})
			.catch(error => {
				console.log(error);
				message.error(`Erreur lors de la création du workflow: ${error.response.data.message}`)
			});
		};
	};

	const handleKeyPress = (e) => {
		if (e.key === "Enter") e.preventDefault();
	};

return(
		<div ref={scrollRef} className="gx-card display-card" onKeyDown={handleKeyPress}>
			<div className ="header-buttons">
        <Button className="go-back"> <Link to={`/project/${id}`} onClick={genFunctions.deleteGoBack}> <span className="icon-back"><ArrowBackIosNewRoundedIcon /></span> <span className="back">{language["common.goBack"]} </span></Link> </Button>
      </div> 

			<h1> {project.name} - {language["workflow.new"]} </h1>
			<div className="mandatory-div">
				<small className="mandatory">{language["common.mandatory.fields"]} </small>
			</div>

			<div className="workflow-main relative">
				<Button onClick={(e) => showModal(e, "workflows")} className="import-workflow-btn" type="primary">{language["workflows.import"]}</Button>
				<Form
					form={form}
					onFinish={checkFlow}
					className="workflow-form"
					scrollToFirstError>
					
					<div className="create-workflow-header">
						<h5 className="form-label"> <span className="mandatory-color">* </span> Nom :</h5>
						<Form.Item
							name="name"
							className="form-item-space"
							rules={[{required: true, message: language["form.mandatory.role"]},]}
							>
							<Input className="workflow-input" onChange={handleState('label')} />
						</Form.Item>
				<br/>
						<h5 className="form-label"> Description :</h5>
							<Form.Item
							name="description"
							className="form-item-space"
							>
							<TextArea rows={4} value={workflow.description} className="workflow-input description" onChange={handleState('description')} />
						</Form.Item>
					</div>

					<div className="triggers-group">

						<div className="trigger-item">
							<button onClick={(e) => showModal(e, "medEventTrigger")} className="btn-no-style add-icon-btn" size="large"> 
								<span className="add-icon"><AddIcon /></span> {language["workflow.medEvent.triggers"]}
							</button>
							<ul>
								{workflow.MedEventWorkflowRules.map((trigger, i)  => (
									<li key={i} className="trigger-card no-list-style relative">
										<i className="icon icon-trash pointer trigger-close-icon" value={i} onClick={() => deleteTrigger(i, "MedEventWorkflowRules")} />
										<h5 className="margin-r-20">{genFunctions.findMedEvent(trigger.MedEventId, medEventsData)} </h5>
										<p>
											<span className="bold">{language["workflow.diff"]} </span>
												{trigger.dayDiff === 0 && trigger.hourDiff === 0 && trigger.minDiff === 0 ? 
													language["common.none"]
												 : 
											 		<span>
                           {genFunctions.secondsToDhms(trigger.secondsDiff)} {trigger.secondsDiff > 0 ? language["common.after"] : language["common.before"]}
													</span>
												} <br/>

											<span className="bold">{language["workflow.atTime"]} </span> 
											{trigger.atTime ? trigger.atTime.substring(0,5) : language["common.none"]} <br/>
											<span className="bold">{language["workflow.triggerOnCreate"]} </span> {!trigger.triggerOnCreate ? language["common.none"] : `${trigger.triggerOnCreate}`} <br/>
											<span className="bold">Extended</span>: {trigger.extended ? language["common.yes"] : language["common.no"]}
										</p>
									</li>
								))}
							</ul>
						</div>
						
						<div className="trigger-item">
							<button onClick={(e) => showModal(e, "patientTrigger")} className="btn-no-style add-icon-btn" size="large"> 
								<span className="add-icon"><AddIcon /></span>{language["workflow.patient.triggers"]}
							</button>
							<ul>
								{workflow.PatientWorkflowRules.map((trigger, i) => (
									<li key={i} className="trigger-card no-list-style relative">
										<i className="icon icon-trash pointer trigger-close-icon" value={i} onClick={() => deleteTrigger(i, "PatientWorkflowRules")} />
										<div className="margin-r-20"><span className="bold">{language["workflow.triggerOnCreate"]} </span> {!trigger.triggerOnCreate ? " Aucun" : `${trigger.triggerOnCreate}`} </div> <br/>
									</li>										
								))}
							</ul>
						</div>
						
						<div className="trigger-item">
							<button onClick={(e) => showModal(e, "variablesTrigger")} className="btn-no-style add-icon-btn" size="large"> 
								<span className="add-icon"><AddIcon /></span> {language["workflow.variables.triggers"]}
							</button>							
							
							<ul>
							{workflow.VariableWorkflowRules?.map((rule, i) => (
                  <li key={i} className="trigger-card no-list-style relative" id="variable-trigger-card">
                    <i className="icon icon-trash pointer trigger-close-icon" value={i} onClick={() => deleteTrigger(i, "VariableWorkflowRules")} />
                    {rule.variables ? (
                      rule.variables.map((variable, i) => (
                        <div key={Math.floor(Math.random() * 10000)} className="trigger-display-project">
                          <h5 className="variable-title margin-r-20">{genFunctions.findVariableName(variable.VariableId, variablesData)}: </h5>
                          { Object.entries(variable).map(([key, value], i) => (
                            key !== "VariableId" ? (
                              <span key={i}>	<span >{genFunctions.findKeyName(key) } {genFunctions.findValue(value)} </span> <br/> </span>
                            ):( <> </>)
                          ))}
                      </div>
                      ))

                    ): (
                      <div className="triggers-list no-list-style" id="variable-trigger-card">
                        <VariableTrigger key={Math.floor(Math.random() * 10000)+i} variableWorkflowRules={rule} />
                      </div>
                    )}
                  </li>
                ))}
							</ul>
						</div>

					</div>

				<div id="workflow-stream" className="workflow-stream-inside">
						<Flow project={project} workflow={workflow} deleteActionParent={deleteAction} updateActionParent={updateAction} saveActionParent={saveAction} medAlerts={medAlerts} locations={locations} deleteActionParent={deleteAction} medAlerts={medAlerts} getElementsParents={saveWorkflow} stopLoadedParent={stopLoaded} ref={flowRef} />
				</div>

				<div className="button-div-flow">
					<Button type="primary" size="large" htmlType='sumbit'> {language["common.save"]} </Button>
				</div>

				</Form>
			</div>

			<BasicModal ref={modalRef} content={content} />
			<ConfirmModal ref={confirmRef} content={ <div>{language["no.flow"]}</div>} button={true} confirmParent={saveWorkflow} />
		</div>
	)};

export default connect (
	(state) => ({
		medEventsData: state.workflows.medEvents,
		access_token: state.common.token,
		variablesData: state.workflows.variables,
		projectsData: state.projects,
		workflowsData: state.workflows.workflows
	}), 
	(dispatch) => ({
		saveMedEventsComp: data => dispatch(workflowActions.saveMedEvents(data)),
		saveVariablesComp: data => dispatch(workflowActions.saveVariables(data)),
		saveNewWorkflow: (projectId, workflow) => dispatch(projectsActions.addWorkflowToProject(projectId, workflow)),
		addWorkflowComp: (workflow) => dispatch(workflowActions.addWorkflow(workflow))
	})
) (CreateWorkflow);