import React, { useRef, useState, useEffect } from "react";
import { Card } from "antd";
import { Link } from "react-router-dom";
import { BasicModal, ChangeAccess, language, formats, requests } from "../_index.js";
import { connect } from "react-redux";

const ProjectCard = ({project, projectsData, user, phoneNumbersData, access_token}) => {
	const modalRef = useRef();

	const [userProject, setUserProject] = useState({ access: ""});
	const [content, setContent] = useState();


	useEffect(() => {
		if (user.id) {
			requests.request(`/projects/${project.id}/users/${user.id}`, access_token)
			.then(res => {
				setUserProject(res.data);
			})
		};
	}, [user,projectsData]);

// ==================== MODAL FUNCTIONS ==================== //
	const showModal = () => {
		setContent(<ChangeAccess cancelForm={closeModal} project={project} user={user} userProject={userProject} updateAccessParent={updateAccess} />);
		modalRef.current.showModal();
	};

	const closeModal = () => {
		modalRef.current.closeModal();
	};

// ==================== FORM FUNCTIONS ==================== //
	const findPhoneNumber = (id) => {
    let phone = {phone: ""};
    if (phoneNumbersData.length) phone = phoneNumbersData.find(phone => phone.id === id) ;
    return formats.formatPhoneNumber(phone.phone);
  };
	
	const updateAccess = (access) => {
		setUserProject({...userProject, access})
	};

// ==================== GO BACK FUNCTIONS ==================== //
	const saveGoBack = () => {
		let goBack = `/user/${user.id}`;
		localStorage.setItem('goBack', goBack);
	};


	return (
		<div>
			<Card onClick={showModal} title={<Link to={`/project/${project.id}`} onClick={saveGoBack}>{project.name}</Link>} className="user-project-card gx-card-widget gx-card-profile-sm user-card">
				<div className="user-infos">
					<div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list user-item">
						<div className="gx-mr-3">
							<i className="icon icon-phone gx-fs-xxl gx-text-grey"/>
						</div>
						<div className="gx-media-body">
							<span className="gx-mb-0 gx-text-grey gx-fs-sm">{language["common.phone"]}</span>
							<p className="gx-mb-0">{findPhoneNumber(project.PhoneNumberId)} </p>
						</div>
					</div>
					<div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list user-item">
						<div className="gx-mr-3">
							<i className={`icon icon-chat-bubble gx-fs-xxl gx-text-grey`}/>
						</div>
						<div className="gx-media-body">
							<span className="gx-mb-0 gx-text-grey gx-fs-sm">{language["common.smsSender"]}</span>
							<p className="gx-mb-0">{project.smsSender ? project.smsSender : "NC"}</p>
						</div>
					</div>
				</div>

				<div className="user-infos">
					<div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list user-item">
						<div className="gx-mr-3">
							<i className={`icon icon-inbox gx-fs-xxl gx-text-grey`}/>
						</div>
						<div className="gx-media-body">
							<span className="gx-mb-0 gx-text-grey gx-fs-sm">{language["common.access"]} </span>
							<p className="gx-mb-0 capitalize"> {userProject.access} </p>
						</div>
					</div>
				</div>
			</Card>
			<BasicModal ref={modalRef} content={content} />
		</div>
	)};

export default connect(
	(state) => ({
		phoneNumbersData: state.phoneNumbers,
		projectsData: state.projects,
		access_token: state.common.token
	})
) (ProjectCard);