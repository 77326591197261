import React from "react";
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";
import "assets/vendors/style";
import "styles/wieldy.less";


import { store, persistor } from "./appRedux/store"
import App from "./containers/App/index";

const NextApp = () => {

  return(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Switch>
            <Route path="/" component={App}/>
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  )};

export default NextApp; 

