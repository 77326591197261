import React, {useState} from "react";
import { Layout } from "antd";
import { Link } from "react-router-dom";

import { SearchBox } from "../_index.js";

const {Header} = Layout;

const Topbar = ({sendNameParent}) => {

  const [searchText, setSearchText] = useState('');

  const updateSearchChatUser = (evt) => {
    setSearchText(evt.target.value);
    sendNameParent(evt.target.value);
  };

  return (
    <Header className="search-bar">
     
      <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
        <img alt="" src={("/assets/images/w-logo.png")}/></Link>

      <SearchBox styleName="gx-d-none gx-d-lg-block gx-lt-icon-search-bar-lg"
                 placeholder="Rechercher un nom de projet ..."
                 onChange={updateSearchChatUser}
                 value={searchText}/>
    </Header>
  );
};

export default Topbar;
