import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { Button, message } from "antd";
import { AddIcon, ConfirmModal, ModalVariable, utils, language } from "../_index.js";

const VariablesTrigger = ({ addTriggerParent, cancelForm, variablesData}) => {

	const modalRef = useRef();

  const initState = {
		variables: [],
    WorkflowId: ""
	}
	const [condition, setCondition] = useState(initState);

	const showModal = (e) => {
		e.preventDefault();
    let card = document.querySelector('#card-choose');
    card.style.height = "28rem";

		modalRef.current.showModal();
	};

	const closeModal = () => {
    modalRef.current.closeModal();
    setTimeout(function (){
      let card = document.querySelector('#card-choose');
      card.style.height = "auto" 
    }, 400);
	};


// ==================== FORM FUNCTIONS ==================== //
// ------------------ Get variable and add it to table ------------------ //
  const getVariable = (variable) => {
    Object.entries(variable).forEach(([key, value]) => {
      if (value === "" || value === null || value === undefined) delete variable[key];
    });

    let variables = condition.variables;
    variables.push(variable);

    setCondition({...condition, variables: variables});
  };

// ------------------ Delete variable from state ------------------ //
const deleteVariable = (i) => {
  let variables = condition.variables
  variables.splice(i, 1);
  setCondition({...condition, variables});
};


// ==================== SAVE FUNCTIONS ==================== //
  const saveCondition = () => {
    if (condition.variables.length) {      
      addTriggerParent("VariableWorkflowRules", condition);
      setCondition(initState);
    } else {
      message.info('Le trigger n\'a pas été créé car aucune variable n\'a été ajoutée')
    }
    cancelForm();
  };

	return(
    <div id="card-choose" className="choose-action-card">
      <h2 className="trigger-title">{language["workflow.conditions"]} </h2>

        <div className="variable-table variable-trigger">
          {condition.variables.map((variable, i) => (
            <div key={Math.floor(Math.random() * 10000)} className="variable-item">
              <h5 className="variable-title">{utils.findVariableName(variable.VariableId, variablesData)}: </h5>
              <i onClick={() => deleteVariable(i)} className="icon icon-close margin-left icon-hover pointer icon-delete-variable-trigger"/>
                { Object.entries(variable).map(([key, value], i) => (
                  key !== "VariableId" ? (
                    <span key={Math.floor(Math.random() * 10000)}>	<span >{utils.findKeyName(key) } {utils.findValue(value)} </span> <br/> </span>
                  ):( <> </>)
                ))}
            </div>
          ))}
          <div className="button-div">
              <button onClick={(e) => showModal(e)} className="btn-no-style add-icon-btn" size="large"><span className="add-icon"><AddIcon /> </span></button>
          </div>
        </div>

        <div className="button-div">
            <Button type="primary" size="small" onClick={saveCondition}>Ok</Button>
        </div>


    <ConfirmModal ref={modalRef} content={<ModalVariable sendVariable={getVariable} closeModalParent={closeModal} />} button={false} />
    </div>
	)};

export default connect (
	(state) => ({
		variablesData: state.workflows.variables
	})
) (VariablesTrigger);