import React from "react";
import { SendSmsCard, MedAlertCard, ConditionVariablesCard, ConditionMedEventLocationCard, CopilotConversationCard } from "../_index";

const Actioncard = ({action, deleteActionParent, medAlerts, showModalParent, locations, project, type}) => {

	return (
		<>
			{action.type === "internal.start" ? 
				<div className="action-card">
					<span className="internal-start"><h3>{action.name} </h3> <span>({action.type})</span></span>
				</div>
				: <> </>}

			{action.type === "sms.send.doctor" || action.type === "sms.send.patient" ? (
				<SendSmsCard action={action} deleteActionParent={deleteActionParent} showModalParent={showModalParent} type={type} project={project} />
			): <> </>} 

			{action.type === "medAlert" ? (
				<MedAlertCard action={action} deleteActionParent={deleteActionParent} medAlerts={medAlerts} showModalParent={showModalParent} type={type} />
			): <> </>}

			{action.type === "condition.variable" ? (
				<ConditionVariablesCard action={action} deleteActionParent={deleteActionParent} showModalParent={showModalParent} type={type}/>
			): <> </>}  

			{action.type === "condition.med-event.location" ? (
				<ConditionMedEventLocationCard action={action} deleteActionParent={deleteActionParent} locations={locations} showModalParent={showModalParent} type={type} />
			): <> </>}

			{action.type === "copilot.conversation.start" ? (
				<CopilotConversationCard action={action} deleteActionParent={deleteActionParent} showModalParent={showModalParent} type={type} />
			): <> </>}


		</>
	)
};

export default Actioncard;