import React, {useEffect} from "react";
import {Redirect, Route} from "react-router-dom";
import {IntlProvider} from "react-intl";

import { MainApp, Login } from "../_index.js";

const App = () => {

  const logged = localStorage.getItem('logged');

  useEffect(() => {
      document.documentElement.classList.remove('rtl');
      document.documentElement.setAttribute('data-direction', 'ltr')
  }, [logged]);


  return (
      <IntlProvider>
        {logged ? (
            <MainApp/>
          ): (
            <Redirect to='/login-bo' />
          )}
      <Route path='/login-bo' component={Login} />

      </IntlProvider>
  )
};

export default App;
