import { message } from "antd";
import React, { useRef } from "react";
import { connect } from "react-redux";

import { language, ConfirmModal, requests, BasicModal, UpdateLocation, projectsActions } from "../_index";


const LocationCard = ({location, projectId, locations, access_token, deleteLocationComp, updateLocationComp}) => {

	const modalRef = useRef();
	const confirmRef = useRef(); 

// ==================== MODAL FUNCTIONS ==================== //
	const showModal = (type) => {
		if (type === "update") modalRef.current.showModal();
		else if (type === "delete") confirmRef.current.showModal();
	};

	const closeModal = () => {
		modalRef.current.closeModal();
	};


// ==================== LOCATIONS FUNCTIONS ==================== //
// ------------------ Update location ------------------ //
	const updateItem = (location, id) => {
		requests.requestPut(`/projects/${projectId}/locations/${id}`, location, access_token)
		.then(res => {
			let index = locations.map(loc => loc.id).indexOf(res.data.id);
			locations[index] = res.data;
			updateLocationComp(projectId, locations);
			message.success('Le lieu a bien été modifié');
		})
		.catch(error =>{
			console.log(error);
			message.error(`Erreur lors de la modification du lieu: ${error.response.data.message}`);
		});
		closeModal();
	};

// ------------------ Delete lcoation ------------------ //
const deleteLocation = () => {
	requests.requestDelete(`/projects/${projectId}/locations/${location.id}`, access_token)
	.then(res => {
		deleteLocationComp(res.data.ProjectId, res.data.id);
		message.success('Le lieu a bien été supprimé du projet');
	})
	.catch(error => {
		console.log(error);
		message.error('Erreur lors de la suppression du lieu')
	});
};


	return (
		<div className="location-card user-card gx-card-widget gx-card-profile-sm">
			<i onClick={() => showModal('delete')} className="icon icon-close-circle margin-left icon-hover pointer icon-delete-location"/>
			<div onClick={() => showModal("update")} >
				<h4>{location.name} </h4>
				<div className="location-infos">

					<div className="location-infos1">
						<span>{location.number} {location.street} </span> {location.street || location.number ? <br/> : <> </>}
						<span>{location.zipcode} {location.city} </span> {location.city || location.zipcode ? <br/> : <> </>}
						<span>{location.state} {location.country} </span>
					</div>
					
					<span className="gx-d-inline-block gx-toolbar-separator">&nbsp;</span>

					<div className="location-infos2">
					<span className="bold"> {language["common.phone"]}: </span>{location.phone ? <span> {location.phone} </span> : <span>NC</span>} <br/>                     
						{location.comment ? <span><span className="bold"> {language["common.comment"]}: </span> {location.comment} </span> : <> </>}
					</div>

				</div>
				
			</div>
			<BasicModal ref={modalRef} content={<UpdateLocation location={location} updateItemParent={updateItem} cancelForm={closeModal} />} /> 
			<ConfirmModal ref={confirmRef} content={ <div>{language["locations.delete"]}</div>} button={true} confirmParent={deleteLocation} />
		</div>
	)
};

export default connect (
	(state) => ({
		access_token: state.common.token
	}),
	(dispatch) => ({
		updateLocationComp: (projectId, locations) => dispatch(projectsActions.updateLocation(projectId, locations)),
		deleteLocationComp: (projectId, locationId) => dispatch(projectsActions.deleteLocation(projectId, locationId))
	  })
	
) (LocationCard);