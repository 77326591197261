import React from "react";
import { Link } from "react-router-dom";
import { Button, Avatar } from "antd";
import { formats, language } from "./_index";
import { connect } from "react-redux";

const CardsListItem = ({data, source, phoneNumbersData})=> {

  const {createdAt, updatedAt} = data;

  const findPhoneNumber = (id) => {
    let phone = {phone: ""};
    if (phoneNumbersData.length) phone = phoneNumbersData.find(phone => phone.id === id) 
    return formats.formatPhoneNumber(phone.phone);
  };

  return (
    data ? (
    <div className={`gx-user-list gx-card-list card-list`}>
      <Avatar className="gx-mr-2">{source === "project" ? data.name.charAt(0) : data.lastName.charAt(0)}</Avatar>
      <div className="gx-description">
        <div className="gx-flex-row">
          <h4>{source === "project" ? data.name : (`${data.title} ${data.firstName} ${data.lastName}`) }</h4>
          <span className="gx-d-inline-block gx-toolbar-separator">&nbsp;</span>
          <span>{source === "project" ? findPhoneNumber(data.PhoneNumberId) : formats.formatPhoneNumber(data.phone)}</span>
        </div>
        {source === "project" ? 
        <p>
          <span className="gx-mr-3 gx-d-inline-block">{language["common.createdAt"]} <span className="gx-text-grey">{formats.formatDate(createdAt)} </span></span> <br/>
          <span className="gx-mr-3 gx-d-inline-block">{language["common.lastUpdate"]} <span className="gx-text-grey">{formats.formatDate(updatedAt)}</span></span>
        </p>
        :
        <p>
          <span className="gx-mr-3 gx-d-inline-block">Email: <span className="gx-text-grey">{data.email} </span></span> <br/>
          <span className="gx-mr-3 gx-d-inline-block">{language["common.role"]} <span className="gx-text-grey">{formats.formatRole(data.role)}</span></span>
        </p>
        }
      </div>
      <div className="gx-card-list-footer">
        <Button type="primary">
          
          <Link to={source === "project" ? `/project/${data.id}` : `/user/${data.id}` } >{language["common.see"]}</Link></Button>
      </div>
    </div>
    ) :
    <> </>
  );
};

export default connect(
  (state) => ({
    projectsData: state.projects,
    usersData: state.users,
    phoneNumbersData: state.phoneNumbers
  })
)(CardsListItem);
