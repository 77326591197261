import React from "react"; 
import { Link } from "react-router-dom";
import { AddIcon, WorkflowCard , language} from "../_index";

const Workflows = ({project, id}) => {

  return (
    <div className="workflows">
      <h3> {language["workflows"]}</h3>

      <Link to={`/project/${id}/create-workflow`}> 
        <button className="btn-no-style add-icon-btn no-link" size="large"> <span className="add-icon"><AddIcon /></span> {language["workflows.add"]} </button>
      </Link>

      {project.workflows?.length ? (
        <ul className="workflows-list" >
          {project.workflows.map(workflow => (
            <li key={workflow.id} className="no-list-style">
              <WorkflowCard workflow={workflow} project={project} />
            </li>
          ))}
        </ul>
      ):(
        <div className="gx-card-widget gx-card-profile-sm no-user-card">
          {language["no.workflows"]}
        </div>
      )}

  </div>
  )
};

export default Workflows;