import React, { useState, useEffect } from "react";
import { Button, Input, message } from "antd";
import { ArrowBackIosNewRoundedIcon, language, genFunctions } from "../_index";
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/fr';


const CopilotconversationStart = ({saveActionParent, cancelForm, goBackParent, action}) => {

	const initState = {
		name: "",
		parameters: {},
		type: "copilot.conversation.start"
	}

	const [value, setValue] = useState(initState);

	useEffect(() => {
		if (action) setValue({...value, 
			id: action.id,
			name: action.name,
			parameters: action.parameters
		});

		let hideItems = document.querySelectorAll('.hide');
		hideItems.forEach(item => {
			item.style.display='none'
		});

	}, []);
	
	const handleChange = (prop) => (event) => {
		setValue({...value, [prop]: prop === "parameters" ? event.jsObject : event.target.value});
	};
	
	const goBack = (e) => {
		goBackParent(e);
	};

	const handleMandatory = (e) => {
		e.target.classList.remove('mandatory-input');
	};

	const saveAction = () => {
		if (!value.name || !Object.entries(value.parameters).length){
			const fields = document.querySelector('#mandatory-fields');
			genFunctions.showItems(fields);

			let inputs = document.querySelectorAll('.check-value');
			inputs.forEach(input => {
				if (!value[input.title]) input.classList.add("mandatory-input");
			});
		} else {
			let finalAction = value;
			if (action) finalAction.id = value.id;

			if (JSON.stringify(finalAction) !== JSON.stringify(action)) saveActionParent(finalAction);
			cancelForm();
		};
	};

	return(
		<div className="copilot-card">
			{!action ? <Button className="go-back-action go-back-copilot" size="small" onClick={goBack}> <span className="icon-back"><ArrowBackIosNewRoundedIcon style={{fontSize: "14px"}} /></span> <span className="back">{language["common.goBack"]}</span></Button> : <> </>}
			
			<h4>Copilot</h4>
			<small id="mandatory-fields" className="mandatory-color hide">Vous n'avez pas rempli tous les champs obligatoires</small>

			<div>
				<Input placeholder="Nom *" label="name" title="name" className="workflow-input check-value " value={value.name} onChange={handleChange('name')} onClick={handleMandatory}	/>

				<div className="editor-div workflow-input">
					<JSONInput
						id          = 'json-editor'
						placeholder = { value.parameters }
						colors      = {{
							number: "#12a54a",			
						}}
						locale      = { locale }
						height      = '550px'
						onChange={handleChange('parameters')}
					/>
				</div>

				<div className="button-div">
					<Button type="primary" size="small" onClick={saveAction} >Ok</Button>
				</div>
			</div>
		</div>
	)};

export default CopilotconversationStart;