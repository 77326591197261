import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Input, message, Select, Form } from "antd";
import { formats, requests, projectsActions, language, formItemLayout } from "../_index.js";

const Option = Select.Option

const UpdateProject = ({project, cancelForm, phoneNumbersData, projectsData, updateProjectComp, access_token}) => {

  const [newProject, setProject] = useState({
		PhoneNumberId: project.PhoneNumberId ,
		smsSender: project.smsSender,
		name: project.name,
	});

  const [form] = Form.useForm();

  useEffect(() => {
    Object.entries(newProject).map(([key, value]) => {
			form.setFieldsValue({[key]: value})
		});
  }, [])

  const closeModal = () => {
    cancelForm();
  };

// ==================== FORM FUNCTIONS ==================== //  
// ------------------ State ------------------ //
	const handleState = (prop) => (event) => {
		event.target ? setProject({ ...newProject, [prop]: event.target.value }) : setProject({ ...project, [prop]: event });
	};  

// ==================== SAVE PROJECT ==================== //
  const handleForm = () => {
    requests.requestPut(`/projects/${project.id}`, newProject, access_token)
    .then(async (res) => {
      let index = projectsData.map(project => project.id).indexOf(res.data.id);
      let projects = [...projectsData]
      projects[index].name = res.data.name;
      projects[index].smsSender = res.data.smsSender;
      projects[index].PhoneNumberId = res.data.PhoneNumberId;
      projects[index].updatedAt = res.data.updatedAt;

      message.success('Le projet bien été mis à jour')
      updateProjectComp(projects);
    })
    .catch(error => {
      console.log(error.response);
      message.error(`Erreur lors de la mise à jour du projet: ${error.response.data.message}`);
    });

    closeModal();
  };

  return(
    <div className="update-project">
			<div className="center-title-div">
        <h3> {project.name} </h3>
			</div>


      <Form
        {...formItemLayout}
        name="update-project"
        onFinish={handleForm}
        scrollToFirstError
        form={form}
        className="form-update-project">
       
        <Form.Item
          name="name"
          label="Nom"
          className="form-item-space"
          rules={[{required: true, message: language["form.mandatory.name"]},]}
          >
          <Input className="input-form" onChange={handleState('name')} />
        </Form.Item>

        <Form.Item
          name="PhoneNumberId"
          label="Téléphone"
          className="form-item-space"
          >
          <Select className="input-form" onChange={handleState('PhoneNumberId')}>
            {phoneNumbersData.map(phoneNumber => (
              <Option key={phoneNumber.id} value={phoneNumber.id} className="input-form" >{formats.formatPhoneNumber(phoneNumber.phone)} </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="smsSender"
          label="Expéditeur"
          className="form-item-space"
          >
          <Input className="input-form" onChange={handleState('smsSender')} />
        </Form.Item>

        <div className="button-div">
					<Form.Item>
						<Button type="primary" size="small" htmlType="submit" >Ok</Button>
					</Form.Item>
				</div>
      </Form>
      
    </div>
)};

export default connect(
	(state) => ({
	  phoneNumbersData: state.phoneNumbers,
    projectsData: state.projects,
		access_token: state.common.token
	}),
  (dispatch) => ({
    updateProjectComp: data => dispatch(projectsActions.updateProject(data))
  })

  )(UpdateProject);
