const initialState = {
    medEvents: [],
    variables: [],
    workflows: []
};

export const SAVE_MED_EVENTS_ACTION = "SAVE_MED_EVENTS_ACTION";
export const ADD_WORKFLOW = "ADD_WORKFLOW";
export const SAVE_VARIABLES_ACTION = "SAVE_VARIABLES_ACTION";
export const SAVE_WORKFLOWS = "SAVE_WORKFLOWS";
export const DELETE_WORKFLOW = "DELETE_WORKFLOW";

export function workflowsReducer(state = initialState, action) {
    let workflowsArray = [...state.workflows];
    switch(action.type){
        case SAVE_WORKFLOWS : 
            return {...state, workflows: action.workflows};
        case ADD_WORKFLOW :
            workflowsArray.push(action.workflow);
            return {... state, workflows: workflowsArray};
        case DELETE_WORKFLOW : 
            let wfIndex = workflowsArray.map(wk => wk.id).indexOf(action.workflowId);
            workflowsArray.splice(wfIndex, 1);
            return {...state, workflows: workflowsArray};
        case SAVE_MED_EVENTS_ACTION : 
            return {...state, medEvents : action.medEvents};
        case SAVE_VARIABLES_ACTION : 
            return {...state, variables: action.variables};
        default: 
            return state;
    };
};