import React, { useEffect, useState } from "react";
import { CardsListItem, Topbar } from "../_index";
import { connect } from "react-redux";

function ListProjects({projectsData}){

  const [projects, setProjects] = useState([]);

  useEffect(() => {
    setProjects(projectsData);
  }, [projectsData])

  const searchName = (name) => {
    let projectsArray = projectsData.filter(project => project.name.toLowerCase().includes(name.toLowerCase()));
    if (!name && !projectsArray.length) setProjects(projectsData)
    else setProjects(projectsArray)
  };

  return (
    <div className="projects-list">
      <Topbar sendNameParent={searchName} />
      

      <div id="card-list" className="list-div">
        {projects.length ? projects.map(project => (
          <CardsListItem key={project.id} data={project} source="project" />
        ))
          : <> </>
        }
      </div>
      
    </div>
  )
};

export default connect(
  (state) => ({
    projectsData: state.projects,
  })
)(ListProjects);
