import React from "react";
import { language } from "../_index.js"

const SendSmsCard = ({action, deleteActionParent, showModalParent , project, type}) => {

	const showModal = (e) => {
		e.preventDefault();
		showModalParent(action)
	};

	const deleteAction = () => {
		deleteActionParent(action)
	};

	return(
		<div className="action-card">
			<div className="flex-between">
				<i onClick={deleteAction} className="icon icon-close icon-hover pointer icon-delete-action"/>
				<div className="action-title">
					<h3>{action.name} </h3> 
					<span>({action.type})</span>
				</div>
				{type === "update" ? <span/> : <i onClick={(e) => showModal(e, action.type)} className="icon icon-edit icon-hover pointer icon-edit-action"/>}
			</div>
			
			<div className="action-content">
				<span><span className="bold">{language["common.smsSender"]}:</span> {action.parameters.from ? action.parameters.from : project.smsSender} </span>
				<h5 className="action-message-title"> {language["workflow.message"]} </h5>
				<span><span className="bold">{language["workflow.french"]}</span> {action.parameters.message.fr} </span> <br/>
				<span><span className="bold">{language["workflow.english"]}</span> {action.parameters.message.en} </span>
			</div>
		</div>
	)
};

export default SendSmsCard;