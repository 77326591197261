import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Input, message , Button, Form} from "antd";
import { UpdateFlow, AddIcon, BasicModal, MedEventTrigger, requests, formItemLayout, PatientTrigger, VariablesTrigger, VariableTrigger, genFunctions, projectsActions,
  language, ArrowBackIosNewRoundedIcon, ConfirmModal } from "../_index.js";
import { connect } from "react-redux";
import { workflowActions } from "../../workflows/_index.js";

const { TextArea } = Input;

const UpdateWorkflow = ({workflowsData, projectsData, access_token, medEventsData, variablesData, updateWorkflowComp, saveWorkflowComp}) => {

  const  {id, workflowid} = useParams();
  const modalRef = useRef();
  const scrollRef = useRef();
	const history = useHistory(); 
  const flowRef = useRef();
  const confirmRef = useRef();
  const [form] = Form.useForm();
	
  const project = projectsData.filter(item => item.id == id)[0];
  const workflowData = workflowsData.filter(item => item.id == workflowid)[0];
	const medAlerts = project.medAlerts;
	const locations = project.locations;

  const [content, setContent] = useState("");
  const [workflow, setWorkflow] = useState(workflowData);
  const [toDelete, setToDelete] = useState({
    MedEventWorkflowRules: [],
    PatientWorkflowRules: [],
    VariableWorkflowRules: []
  });


  useEffect(() => {
    let actions = workflow.actions
    workflow.actions.map((action, i) => {
      actions[i].id = `${action.name}${i}`;
    });

    setWorkflow({...workflow, actions});
    form.setFieldsValue({name: workflow.label, description: workflow.description})
  }, [project]);

  const executeScroll = () => scrollRef.current.scrollIntoView();
// ==================== MODAL FUNCTIONS ==================== //
  const showModal = (e, type) => {
		e.preventDefault();
		if (type === "medEventTrigger") setContent(<MedEventTrigger cancelForm={closeModal} addTriggerParent={addTrigger} /> )
		else if (type === "patientTrigger") setContent(<PatientTrigger cancelForm={closeModal} addTriggerParent={addTrigger} />)
		else if (type === "variablesTrigger") setContent(<VariablesTrigger cancelForm={closeModal} addTriggerParent={addTrigger} />);

		modalRef.current.showModal();
	};

	const closeModal = () => {
		modalRef.current.closeModal();
	};

// ==================== FORM FUNCTIONS ==================== //
// ------------------ State ------------------ //
const handleState = (prop) => (event) => {
  event.target ? setWorkflow({ ...workflow, [prop]: event.target.value }) : setWorkflow({ ...workflow, [prop]: event });
};
// ------------------ Pre-save trigger from child component ------------------ //
const addTrigger = (type, trigger) => {
  let array = [];
  if (workflow[type]) array = workflow[type];
  array.push(trigger);

  setWorkflow({...workflow, [type]: array});
};

// ------------------ Remove trigger from list ------------------ //
const deleteTrigger = (i, id, type) => {
  let array = workflow[type];
  array.splice(i, 1);
  setWorkflow({ ...workflow, [type]: array });
 
  if (id) {
    let array2 = toDelete[type]
    array2.push(id);
    setToDelete({...toDelete, [type]: array2});
  }
};

const handleMandatory = (e) => {
  e.target.classList.remove('mandatory-input');
};

// ==================== ACTIONS FUNCTIONS ==================== //
// ------------------ Pre-save action in state ------------------ //
const saveAction = (action) => {
  let actions = workflow.actions; 
  actions.push(action);

  setWorkflow({...workflow, actions: actions});
};

// ------------------ Update action in state ------------------ //
const updateAction = (action) => {
  let index = workflow.actions.map(el => el.id).indexOf(action.id);
  let actions = workflow.actions;
  actions[index] = action;
  setWorkflow({...workflow, actions});
};

// ------------------ Delete action from state ------------------ //
const deleteAction = (id) => {
  let actions = workflow.actions
  let index = actions.map(action => action.id).indexOf(id);
  actions.splice(index, 1);
  setWorkflow({...workflow, actions: actions})
};

// ==================== SAVE FUNCTIONS ==================== //
const checkFlow = () => {
  if (flowRef.current.elements.length === 1) {
    confirmRef.current.showModal()
  } else {
    saveWorkflow(flowRef.current.elements);
  }
}

// ------------------ Create the connection object from flow elements ------------------ //
const createConnections = (elements) => {
  let connections = {};
  elements.map(element => {
    if (element.source){
      let source = workflow.actions.filter(action => action.id === element.source)[0];
      let target = workflow.actions.filter(action => action.id === element.target)[0];
      !connections[source.name] ? connections[source.name] = {} : connections[source.name] = {...connections[source.name]};

      if (!element.label) element.label = "next";
      if (element.label === "next") {
        connections[source.name].next= [{
          name: target.name
        }]
      }else {
        connections[source.name].onFailure = [{
            name: target.name
        }];
      };
    };
  });
  return connections;
};

const saveWorkflow = (elements) => {
  if (!workflow.label) {
    message.error('Le champ "Nom" est obligatoire');
    let inputs = document.querySelectorAll('.check-value');
    inputs.forEach(input => {
      if (!workflow[input.title]) input.classList.add("mandatory-input");
    });

  } else {

    let workflowToSave = {...workflow};

    delete workflowToSave.MedEventWorkflowRules; delete workflowToSave.PatientWorkflowRules; delete workflowToSave.VariableWorkflowRules;  delete workflowToSave.loaded;
    delete workflowToSave.BundleId; delete workflowToSave.ProjectId; delete workflowToSave.createdAt; delete workflowToSave.deletedAt; delete workflowToSave.id; delete workflowToSave.updatedAt;

    workflowToSave.connections = createConnections(elements);
    let actions = [...workflow.actions];
    actions.map(action => {
      delete action.id
    });

    workflowToSave.actions = actions;

    requests.requestPut(`/projects/${id}/workflows/${workflowid}`, workflowToSave, access_token)
    .then(res => {
      res = res.data;

      // ----- Handling of the medEvent workflow rules ----- //
      if (toDelete.MedEventWorkflowRules.length) toDelete.MedEventWorkflowRules.forEach(rule => requests.requestDelete(`/projects/${id}/med-event-workflow-rules/${rule}`, access_token));

      let medTriggers = workflow.MedEventWorkflowRules;
        workflow.MedEventWorkflowRules?.forEach((rule, i) => {
          if (!rule.id) {
            rule.WorkflowId = res.id;
            requests.requestPost(`/projects/${id}/med-event-workflow-rules`, rule, access_token)
            .then(medEvent => {
              medTriggers[i] = medEvent.data;
            })
            .catch(error => {
              console.log(error);
              message.error(`Erreur lors de la création de medEventTrigger: ${error.reponse.data.message}`)
            });
          };
        });
        res.MedEventWorkflowRules = medTriggers;

    // ----- Handling of the Patient workflow rules ----- //
      if (toDelete.PatientWorkflowRules.length) toDelete.PatientWorkflowRules.forEach(rule => requests.requestDelete(`/projects/${id}/patient-workflow-rules/${rule}`, access_token));
      
      let patientTriggers = workflow.PatientWorkflowRules;
        workflow.PatientWorkflowRules?.forEach((rule, i) => {
          if (!rule.id) {
            rule.WorkflowId = res.id;
            requests.requestPost(`/projects/${id}/patient-workflow-rules`, rule, access_token)
            .then(patientRule => {
              patientTriggers[i] = patientRule.data;
            })
            .catch(error => {
              console.log(error);
              message.error(`Erreur lors de la création de patientEventTrigger: ${error.reponse.data.message}`)
            });
          };
        });
      res.PatientWorkflowRules = patientTriggers;

    // Handling of the variables workflow rules ----- //
      if (toDelete.VariableWorkflowRules) toDelete.VariableWorkflowRules.forEach(rule => requests.requestDelete(`/projects/${id}/variable-workflow-rules/${rule}`, access_token));
      
      let variablesTriggers = workflow.VariableWorkflowRules;
      workflow.VariableWorkflowRules?.forEach((rule, i) => {
        if (!rule.id){
          requests.requestPost(`/projects/${id}/variable-workflow-rules`, {WorkflowId: res.id}, access_token)
          .then(variableWorkflowRule => {
            rule.variables.map(variable => {

              requests.requestPost(`/projects/${id}/variable-workflow-rules/${variableWorkflowRule.data.id}/variable-rules`, variable, access_token)
              .then()
              .catch(error => {
                message.error(`Erreur lors de la création de VariablesRule: ${error.reponse.data.message}`)
                console.log(error);
              });
            });
            variablesTriggers[i] = variableWorkflowRule.data;
          })
          .catch(error => {
            console.log(error);
            message.error(`Erreur lors de la création du variable de VariableTrigger: ${error.reponse.data.message}`)
          });
        };
      });
      res.VariableWorkflowRules = variablesTriggers

      message.success('Le workflow a bien été modifié');
      updateWorkflowComp(id, res);
      let index = workflowsData.map(item => item.id).indexOf(parseInt(workflowid));
      workflowsData[index] = res;
      saveWorkflowComp(workflowsData);
      setTimeout(function(){
        history.push(`/project/${id}`)
      }, 1000);
        
    })
    .catch(error => {
      console.log(error);
      message.error(`Erreur lors de la mise à jour du workflow: ${error.response.data.message}`)
    });
    };
  };

  return (
    <div ref={scrollRef} className="gx-card display-card">
      <div className ="header-buttons">
        <Button className="go-back"> <Link to={`/project/${id}`} onClick={genFunctions.deleteGoBack}> <span className="icon-back"><ArrowBackIosNewRoundedIcon /></span> <span className="back">{language["common.goBack"]} </span></Link> </Button>
      </div>

    <h1> {project.name} - {language["workflow.update"]} </h1>
    <div className="mandatory-div">
      <small className="mandatory">{language["common.mandatory.fields"]} </small>
    </div>

    <div className="workflow-main relative">
      <Form
        form={form}
        onFinish={checkFlow}
        className="workflow-form">

          <div className="create-workflow-header">
						<h5 className="form-label"> <span className="mandatory-color">* </span> Nom :</h5>
						<Form.Item
							name="name"
							className="form-item-space"
							rules={[{required: true, message: language["form.mandatory.role"]},]}
							>
							<Input className="workflow-input" onChange={handleState('label')} />
						</Form.Item>
				  <br/>
						<h5 className="form-label"> Description :</h5>
							<Form.Item
							name="description"
							className="form-item-space"
							>
							<TextArea rows={4} value={workflow.description} className="workflow-input description" onChange={handleState('description')} />
						</Form.Item>
					</div>
        
        <h4>Triggers</h4>
          <div className="triggers-group">

            <div className="trigger-item">
              <button onClick={(e) => showModal(e, "medEventTrigger")} className="btn-no-style add-icon-btn" size="large"> 
                <span className="add-icon"><AddIcon /></span> {language["workflow.medEvent.triggers"]}
              </button>
              <ul>
                {workflow.MedEventWorkflowRules?.map((trigger, i)  => (
                  <li key={i} className="trigger-card no-list-style relative">
                    <i className="icon icon-trash pointer trigger-close-icon" value={i} onClick={() => deleteTrigger(i, trigger.id, "MedEventWorkflowRules")} />
                    <h5 className="margin-r-20">{genFunctions.findMedEvent(trigger.MedEventId, medEventsData)} </h5>
                    <p>
                      <span className="bold">{language["workflow.diff"]} </span>
                        {trigger.secondsDiff === 0 ? 
                          language["common.none"]
                        : 
                          <span>
                            {genFunctions.secondsToDhms(trigger.secondsDiff)} {trigger.secondsDiff > 0 ? language["common.after"] : language["common.before"] }
                            </span>
                        } <br/>

                      <span className="bold">{language["workflow.atTime"]} </span> 
                      {trigger.atTime ? trigger.atTime.substring(0,5) : "Aucun"} <br/>
                      <span className="bold">{language["workflow.triggerOnCreate"]} </span> {!trigger.triggerOnCreate ? " Aucun" : `${trigger.triggerOnCreate}`} <br/>
                      <span className="bold">Extended</span>: {trigger.extended ? language["common.yes"] : language["common.no"]}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
            
            <div className="trigger-item">
              <button onClick={(e) => showModal(e, "patientTrigger")} className="btn-no-style add-icon-btn" size="large"> 
                <span className="add-icon"><AddIcon /></span> {language["workflow.patient.triggers"]}
              </button>
              <ul>
                {workflow.PatientWorkflowRules?.map((trigger, i) => (
                  <li key={i} className="trigger-card no-list-style relative">
                    <i className="icon icon-trash pointer trigger-close-icon" value={i} onClick={() => deleteTrigger(i, trigger.id, "PatientWorkflowRules")} />
                    <div className="margin-r-20"><span className="bold">{language["workflow.triggerOnCreate"]} </span> {!trigger.triggerOnCreate ? language["common.none"] : `${trigger.triggerOnCreate}`}  </div><br/>
                  </li>										
                ))}
              </ul>
            </div>
            
            <div className="trigger-item">
              <button onClick={(e) => showModal(e, "variablesTrigger")} className="btn-no-style add-icon-btn" size="large"> 
                <span className="add-icon"><AddIcon /></span> {language["workflow.variables.triggers"]}
              </button>							
              
              <ul>
                {workflow.VariableWorkflowRules?.map((rule, i) => (
                  <li key={i} className="trigger-card no-list-style relative" id="variable-trigger-card">
                    <i className="icon icon-trash pointer trigger-close-icon" value={i} onClick={() => deleteTrigger(i, rule.id, "VariableWorkflowRules")} />
                    {rule.variables ? (
                      rule.variables.map((variable, i) => (
                        <div key={Math.floor(Math.random() * 10000)} className="trigger-display-project">
                          <h5 className="variable-title margin-r-20">{genFunctions.findVariableName(variable.VariableId, variablesData)}: </h5>
                          { Object.entries(variable).map(([key, value], i) => (
                            key !== "VariableId" ? (
                              <span key={i}>	<span >{genFunctions.findKeyName(key) } {genFunctions.findValue(value)} </span> <br/> </span>
                            ):( <> </>)
                          ))}
                      </div>
                      ))
                    ): (
                      <div className="triggers-list-update no-list-style" id="variable-trigger-card">
                        <VariableTrigger key={Math.floor(Math.random() * 10000)+i} variableWorkflowRules={rule} />
                      </div>
                    )}
                  </li>
                ))}
                
              </ul>
            </div>

          </div>

        <div id="workflow-stream" className="workflow-stream-inside">
            <UpdateFlow project={project} workflow={workflow} medAlerts={medAlerts} locations={locations} deleteActionParent={deleteAction} saveActionParent={saveAction} getElementsParents={saveWorkflow} updateActionParent={updateAction} />
        </div>
      </Form>
    </div>

    <BasicModal ref={modalRef} content={content} />
    <ConfirmModal ref={confirmRef} content={ <div>{language["no.flow"]}</div>} button={true} confirmParent={saveWorkflow} />
  </div>
  )

}

export default connect (
  (state) => ({
		medEventsData: state.workflows.medEvents,
		access_token: state.common.token,
		variablesData: state.workflows.variables,
		projectsData: state.projects,
		workflowsData: state.workflows.workflows
  }), 
  (dispatch) => ({
    updateWorkflowComp: (projectId, workflow) => dispatch(projectsActions.updateWorkflowInProject(projectId, workflow)),
    saveWorkflowComp: (workflows) => dispatch(workflowActions.saveWorkflows(workflows))
  })
) (UpdateWorkflow);