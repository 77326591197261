let comparators = [
  {dbName: "gt", displayName: "Supérieur(e) à:"},
  {dbName: "gte", displayName: "Supérieur(e) ou égal(e) à:"},
  {dbName: "eq", displayName: "Égal(e) à:"},
  {dbName: "eqBool", displayName: "Égal(e) à:"},
  {dbName: "eqString", displayName: "Égal(e) à:"},
  {dbName: "lte", displayName: "Inférieur(e) ou égal(e) à:"},
  {dbName: "lt", displayName: "Inférieur(e) à:"},
  {dbName: "neq", displayName: "Différent(e) de:"},
  {dbName: "neqString", displayName: "Différent(e) de:"},
];

const findVariableName = (id, variablesData) => {
  let variable = variablesData.filter(v => v.id == id)[0];
  let name = variable.label ? variable.label : variable.name
  return name;
};

const findKeyName = (key) => {
  let name = comparators.filter(item => item.dbName === key)[0];
  return name.displayName;
};

const findValue = (value) => {
  if (value === true) value = "Oui";
  else if (value === false) value = "Non";
  return value;
};

const findMedEvent = (id, medEventsData) => {
  let event = medEventsData.filter(event => event.id == id)[0];

  if (!event) event = {name: "NC"};
  return event.name
};

const showItems = (selector) => {
  selector.style.display = 'block';
  selector.classList.remove('hide');
};

const showItemsFlex = (selector) => {
  selector.style.display = 'flex';
  selector.classList.remove('hide');
};

const hideItems = (selectors) => {
  selectors.forEach(selector => {
    if (!selector.classList.contains('hide')){
      selector.classList.add('hide');
      selector.style.display = 'none';
    }; 
  });
};

const secondsToDhms = (seconds) => {
  let result;  
  if (seconds.toString()[0] === "-") seconds = seconds.toString().substring(1);
  seconds = parseInt(seconds, 10);
  
  var days = Math.floor(seconds / (3600*24));
  seconds  -= days*3600*24;
  var hrs   = Math.floor(seconds / 3600);
  seconds  -= hrs*3600;
  var mnts = Math.floor(seconds / 60);
  seconds  -= mnts*60;
  
  if (days !== 0 && (hrs !== 0 || mnts !== 0 )){
    return result = `${days} jours, ${hrs < 10 ? `0${hrs}` : hrs}h${mnts < 10 ? `0${mnts}` : mnts}`;
  } else if (days !== 0 && hrs === 0 && mnts === 0) {
    return result = `${days} jours`
  } else if (days === 0 && (hrs !== 0 || mnts !== 0)) {
    return result = `${hrs < 10 ? `0${hrs}` : hrs}h${mnts < 10 ? `0${mnts}` : mnts}`
  };
};

const deleteGoBack = () => {
  let goBack = localStorage.getItem('goBack');
  if (goBack) localStorage.removeItem('goBack');
 };


export default { showItems, showItemsFlex, hideItems, findVariableName, findKeyName, findValue, findMedEvent, 
  secondsToDhms, deleteGoBack };