import React, {useEffect} from "react";
import { connect } from "react-redux";
import {Layout, message} from "antd";
import { useHistory } from "react-router";

import { App, Sidebar, CommonActions, requests, projectsActions, workflowActions, usersActions, phoneNumbersActions } from "../_index.js";

const { Content } = Layout;

const MainApp = ({saveProjectsComp, saveUsersComp, savePhoneNumbersComp, saveTokenComp, saveVariablesComp, saveMedEventsComp, saveWorkflowsComp, deleteTokenComp}) => {
  
  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    saveTokenComp("Bearer "+ token);

      async function fetchData () {
        requests.request('/projects', `Bearer ${token}`)
        .then(res => {
          saveProjectsComp(res.data);
          let workflows = [];
          res.data.map(async (item, i) => {
            let project = item;
            requests.request(`/projects/${project.id}/med-alerts`, `Bearer ${token}`)
            .then(res => {
              project.medAlerts = res.data;
            })
            .catch(err => console.log("erreur med alerts", err));
            
            requests.request(`/projects/${project.id}/locations`, `Bearer ${token}`)
            .then(res => {
              project.locations = res.data;
            })
            .catch(err => console.log("erreur locations", err));
  
            let workflowsData = await requests.request(`/projects/${project.id}/workflows`, `Bearer ${token}`);
            project.workflows = workflowsData.data;
            project.workflows.map(workflow => {
            workflows.push(workflow)}); 
            saveWorkflowsComp(workflows);    
          });
         
          saveProjectsComp(res.data);
        })
        .catch(error => {
          if (error.response.data.statusCode === 401){
            logOut();
            message.error(`Erreur avec le token de connection: ${error.response.data.message}`);
          } else {
            message.error(`Erreur lors du chargement des données: ${error.response.data.message}`);
          };
        });

        requests.request('/users', `Bearer ${token}`).then(res => saveUsersComp(res.data))
        .catch(error => {
          if (error.response.data.statusCode !== 401){
            message.error(`Erreur lors du chargement des données: ${error.response.data.message}`);
          };
        });

        requests.request('/phone-numbers', `Bearer ${token}`).then(res => savePhoneNumbersComp(res.data))
        .catch(error => {
          if (error.response.data.statusCode !== 401){
            message.error(`Erreur lors du chargement des données: ${error.response.data.message}`);
          };
        });

        requests.request('/variables', `Bearer ${token}`).then(res => saveVariablesComp(res.data))
        .catch(error => {
          if (error.response.data.statusCode !== 401){
            message.error(`Erreur lors du chargement des données: ${error.response.data.message}`);
          };
        });

        requests.request('/med-events', `Bearer ${token}`).then(res => saveMedEventsComp(res.data))
        .catch(error => {
          if (error.response.data.statusCode !== 401){
            message.error(`Erreur lors du chargement des données: ${error.response.data.message}`);
          };
        });      
      };

    if (token)  fetchData();
  });

  const logOut = () => {
    deleteTokenComp();
    history.push('/login-bo');
  };


  return (
    <Layout className="gx-app-layout">
      <Sidebar/>
      <Layout>
        <Content className={`gx-layout-content`}>
          <App/>
        </Content>
      </Layout>
    </Layout>
  )
};
export default connect(
  (state) => ({
    access_token: state.common.token
  }),
  (dispatch) => ({
    saveProjectsComp: data => dispatch(projectsActions.saveProjects(data)),
    saveUsersComp: data => dispatch(usersActions.saveUsers(data)),
    savePhoneNumbersComp: data => dispatch(phoneNumbersActions.savePhoneNumbers(data)),
    saveTokenComp: data => dispatch(CommonActions.saveToken(data)),
    saveMedEventsComp: data => dispatch(workflowActions.saveMedEvents(data)),
		saveVariablesComp: data => dispatch(workflowActions.saveVariables(data)),
    saveWorkflowsComp: data => dispatch(workflowActions.saveWorkflows(data)),
    deleteTokenComp: () => dispatch(CommonActions.deleteToken())
  })
)(MainApp);

