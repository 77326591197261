import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Button, message } from "antd";
import { language, usersActions, genFunctions, UpdateUser, ArrowBackIosNewRoundedIcon, BasicModal, EditRoundedIcon, formats, ProjectCard, requests } from "../_index.js"

const DisplayUser = ({projectsData, usersData}) => {

  const {id} = useParams();
  const modalRef = useRef();

  const [user, setUser] = useState({});
  const [userProjects, setUserProjects] = useState([]);

	const goBack = localStorage.getItem('goBack');

  useEffect(() => {
// Rechercher les informations RPPS, si NationelRegisterID disponible
		let loader = document.querySelectorAll('#loader')[0];
		let content = document.querySelectorAll('#main-part')[0];

		let thisUser;
		if (usersData.length) thisUser = usersData.filter(user => user.id == id)[0];
		if (thisUser.nationalRegisterID){
			requests.requestRPPS(thisUser.nationalRegisterID)
				.then(res => {
					res = res.data.user
					Object.entries(res).map(([key, value]) => {
						if (thisUser[key] == null && key !== "street" && key !== "streetNumber" && key !== "firstname" && key !== "lastname") {
							thisUser[key] = value;
						};
					});
					thisUser.street = `${res.streetNumber} ${res.street}`;
					setUser(thisUser);

					genFunctions.showItems(content)
					genFunctions.hideItems([loader]);
				})
				.catch(error => {
					genFunctions.showItems(content)
					genFunctions.hideItems([loader]);
					message.error(`Problème avec la requête RPPS: ${error}`)
					console.log('Erreur RPPS:', error);
					setUser(thisUser)
				});
		} else {
			if (thisUser.role === "doctor" && !thisUser.nationalRegisterID) message.warning('Ce docteur n\'a pas de "National Register Id"');
			setUser(thisUser);
			genFunctions.showItems(content)
			genFunctions.hideItems([loader]);
		}

		// Find the projects attahced to this user
    let projects = [];
    projectsData.forEach(project => {
			project.Users.forEach(userProject => {
        if (userProject.id == id) projects.push(project);
      });
    });

    setUserProjects(projects);
  },[usersData]);
  	
	const showModal = () => {
		modalRef.current.showModal();
	};

	const closeModal = () => {
		modalRef.current.closeModal();
	};

	const updateUser = (userData) => {
		setUser(userData);
	};

	return(
		<div className="gx-card display-card display-user">
			
			<div className="header-buttons">
      		<Button className="go-back"> <Link to={goBack ? goBack : "/users/list"} onClick={genFunctions.deleteGoBack}> <span className="icon-back"><ArrowBackIosNewRoundedIcon /></span> <span className="back">{language["common.goBack"]} </span></Link> </Button>
				<button onClick={showModal} className="btn-no-style icon-hover"><EditRoundedIcon style={{fontSize:"38px"}} /> </button>
			</div>

			<div className="display-user-main">
				<div id="loader" className="loader loading loader-div">
					<img src="/assets/images/curecall-loading.gif" className="loader-img" alt="loader"/>
					<p>{language["common.rppsSearch"]}</p>
				</div>

				<div id="main-part" className="hide">
					<div className="user-header">
						<h1>{`${user?.title} ${user?.firstName} ${user?.lastName}`}</h1>

						<div className="user-infos-header">
							<span> <span className="bold"> {language["common.role"]}:</span> {formats.formatRole(user?.role)} </span> <br/>
							{user.speciality ? <span><span className="bold"> {language["common.specialty"]}:</span> {user.speciality}</span> : <></>}	{user.speciality ? <br/> : <> </>}
							<span> {user.email? user.email : "Pas d'adresse email"} </span>
							<span className="gx-d-inline-block gx-toolbar-separator">&nbsp;</span> 
							<span>{user.phone ? formats.formatPhoneNumber(user.phone) : language["common.no.phone"]}</span><br/>
						</div>
			
						<span className="bold user-infos-header ">Informations de travail:</span>
						<p>
							{user.companyName ? user.companyName : <> </>} {user.companyName ? <br/> : <> </>}
							{user.street ? user.street : <> </>} {user.street ? <br/> : <> </>}
							{user.zipcode ? (`${user.zipcode} `) : <> </>}
							{user.city ? user.city : <> </>} {user.city ? <br/> : <> </>}
							{user.companyEmail? user.companyEmail : <></>} 
							{user.companyEmail && user.companyPhone ? <span className="gx-d-inline-block gx-toolbar-separator">&nbsp;</span> : <></>}
							{user.companyPhone ? formats.formatPhoneNumber(user.companyPhone) : <></> }

						</p>
					</div>

					<div>
						<h2>Projets</h2>
						{userProjects.length ? (

							<ul className="display-list users-projects-list">
							{userProjects.map(project => (
								<li key={project.id} className="no-list-style user-pj-list half-width">
									<ProjectCard user={user} project={project} />
								</li>
							))}
						</ul>
						): (
							<div className="gx-card-widget gx-card-profile-sm no-project-card">{language["users.no.projects"]} </div>
						)}
					</div>
				</div>
			</div>


		<BasicModal ref={modalRef} content={<UpdateUser userParent={user} cancelForm={closeModal} updateUserParent={updateUser} /> } /> 
		</div>
	)};

export default connect(
  (state) => ({
    projectsData: state.projects,
    usersData: state.users,
  }),
	(dispatch) => ({
		saveUsersComp: data => dispatch(usersActions.updateUsers(data))
	})

) (DisplayUser);