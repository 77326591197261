import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Input, message } from "antd";
import {  genFunctions, AddIcon, ConfirmModal, ModalVariable, utils, language, ArrowBackIosNewRoundedIcon } from "../_index.js";

const ConditionVariable = ({ saveActionParent, cancelForm, goBackParent, variablesData, action}) => {

	const modalRef = useRef();

	const initState = {
		name: "",
		type: "condition.variable",
		variables: []
	};
	
	const [condition, setCondition] = useState(initState);

	useEffect(() => {
		if (action) setCondition({...condition, 
			name: action.name,
			variables: action.parameters.conditions,
			id: action.id
		});

		let hideItems = document.querySelectorAll('.hide');
		hideItems.forEach(item => {
			item.style.display='none'
		});

	}, []);

	const showModal = (e) => {
		e.preventDefault();
		let card = document.querySelector('#card-choose');
    card.style.height = "28rem";

		modalRef.current.showModal();
	};

	const goBack = (e) => {
		goBackParent(e);
	};

	const handleMandatory = (e) => {
		e.target.classList.remove('mandatory-input');
	};


	const closeModal = () => {
		modalRef.current.closeModal();
		setTimeout(function (){
      let card = document.querySelector('#card-choose');
      card.style.height = "auto" 
    }, 400);
	};

// ==================== FORM FUNCTIONS ==================== //
// ------------------ State ------------------ //
	const handleChange = (prop) => (event) => {
		setCondition({...condition, [prop]: event.target.value});
	};

// ------------------ Get variable and add it to table ------------------ //
	const getVariable = (variable) => {
		Object.entries(variable).map(([key, value]) => {
			if (value === "" || value === null) delete variable[key];
		});

		let variables = condition.variables;
		variables.push(variable);

		setCondition({...condition, variables: variables});
	};

// ------------------ Delete variable from state ------------------ //
	const deleteVariable = (i) => {
		let variables = condition.variables
		variables.splice(i, 1);
		setCondition({...condition, variables});
	};


// ==================== SAVE FUNCTIONS ==================== //
	const saveCondition = () => {
		if (!condition.name ){
			const fields = document.querySelector('#mandatory-fields');
			genFunctions.showItems(fields);

			let inputs = document.querySelectorAll('.check-value');
			inputs.forEach(input => {
				if (!condition[input.title]) input.classList.add("mandatory-input");
			});
		} else if (!condition.variables.length ){
			message.error('Aucune variable n\'a été ajoutée')
		} else {
			let finalAction = {
				type: condition.type,
				name: condition.name,
				parameters: {
					conditions: condition.variables
				}
			};
			if (action) finalAction.id = condition.id;

			saveActionParent(finalAction);
			cancelForm();
		};
	};

	return(
		<div id="card-choose" className="choose-action-card condition-card">
			<div>
			{!action ? <Button className="go-back-action" size="small" onClick={goBack}> <span className="icon-back"><ArrowBackIosNewRoundedIcon style={{fontSize: "14px"}} /></span> <span className="back">{language["common.goBack"]}</span></Button> : <> </>}
			<h4>{language["workflow.variables.condition"]}</h4>
				<small id="mandatory-fields" className="mandatory-color hide">Vous n'avez pas rempli tous les champs obligatoires</small>

        <Input label="name" placeholder="Nom *" value={condition.name} title="name" onChange={handleChange('name')} className="workflow-input check-value" onClick={handleMandatory} />
				
				<h5 className="trigger-input">{language["workflow.conditions"]}</h5>
				<div className="variable-table workflow-input">
					{condition.variables.map((variable, i) => (
						<div key={`variable-${i}`} className="variable-item relative">
							<h5 className="variable-title">{utils.findVariableName(variable.VariableId, variablesData)}: </h5>
							<i onClick={() => deleteVariable(i)} className="icon icon-close margin-left icon-hover pointer icon-delete-variable"/>
								{ Object.entries(variable).map(([key, value], i) => (
									key !== "VariableId" && value ? (
										<div key={`variable-desc-${i}`} >
											<span >{utils.findKeyName(key) } {utils.findValue(value)} </span> <br/> 
										</div>
									):( <> </>)
								))}
						</div>
					))}
					<div className="button-div">
						<button onClick={(e) => showModal(e)} className="btn-no-style add-icon-btn" size="large"><span className="add-icon"><AddIcon /> </span></button>
					</div>
				</div>

				<div className="button-div">
					<Button type="primary" size="small" onClick={saveCondition}>Ok</Button>
				</div>
		
			<ConfirmModal ref={modalRef} content={<ModalVariable sendVariable={getVariable} closeModalParent={closeModal} />} button={false} />
			</div>
		</div>
	)};

export default connect (
	(state) => ({
		variablesData: state.workflows.variables
	})
) (ConditionVariable);