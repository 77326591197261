const fr = {
  "common.access": "Accès:",
  "common.after": "après",
  "common.before": "avant",
  "common.cancel": "Annuler",
  "common.city": "Ville",
  "common.comment": "Commentaire",
  "common.country": "Pays",
  "common.createdAt": "Crée le:",
  "common.description": "Description",
  "common.false": "Faux",  
  "common.goBack": "Retour",
  "common.label": "Label:",
  "common.lastUpdate": "Dernière mise à jour:",
  "common.mandatory.fields": "Tous les champs marqués * sont obligatoires",
  "common.name": "Nom",
  "common.no": "Non",
  "common.no.access": "Pas d'accès au projet",
  "common.no.email": "Pas d'email",
  "common.no.phone": "Pas de téléphone",
  "common.none": "Aucun",
  "common.number": "Numéro",
  "common.phone": "Téléphone",
  "common.region": "Région",
  "common.rppsSearch": "Recherche des informations RPPS ...",
  "common.role": "Rôle",
  "common.save": "Enregistrer",
  "common.see": "Voir",
  "common.search": "Rechercher",
  "common.smsSender": "Expéditeur",
  "common.specialty": "Spécialité",
  "common.street": "Rue",
  "common.true": "Vrai",
  "common.type": "Type:",
  "common.update": "Mise à jour",
  "common.yes": "Oui",
  "common.zipcode": "Code postal",
  "form.mandatory.email": "L'adresse email est obligatoire",
  "form.mandatory.firstName": "Le prénom est obligatoire",
  "form.mandatory.lastName": "Le nom est obligatoire",
  "form.mandatory.name": "Le nom est obligatoire",
  "form.mandatory.phone": "Vous devez choisir un téléphone",
  "form.mandatory.role": "Le rôle est obligatoire",
  "form.mandatory.title": "Le titre est obligatoire",
  "form.mandatory.type": "Le type d'alerte est obligatoire",
  "form.mandatory.user.phone": "Le téléphone est obligatoire",
  "form.11.characters": "Le nombre de caractère est limité à 11",
  "form.time.trigger": "Déclenchement temporel",
  "form.meet.trigger": "Déclenchement à la prise de RDV",
  "locations": "Lieux",
  "location": "Lieu",
  "locations.new": "Nouveau lieu",
  "locations.add": "Ajouter un lieu",
  "locations.delete": "Supprimer ce lieu du projet",
  "no.locations": "Ce projet n'a pas encore de lieux",
  "medAlert": "Alerte médicale",
  "medAlerts": "Alertes médicales",
  "medAlerts.new": "Nouvelle alerte médicale",
  "medAlerts.add": "Ajouter une alerte médicale",
  "no.medAlerts": "Ce projet n'a pas encore d'alertes médicales",
  "medAlert.type": "Type d'alerte",
  "medAlert.danger": "Danger",
  "medAlert.info": "Information",
  "medAlert.success": "Succès",
  "medAlert.warning": "Avertissement",
  "project.new": "Nouveau projet",
  "project.users": "Utilisateurs du projet:",
  "project.no.users": "Ce projet n'a pas encore d'utilisateurs",
  "project.addUser": "Ajouter un utilisateur au projet",
  "project.deleteUser": "Supprimer cet utilisateur du projet",
  "project.name": "Nom du projet",
  "sidebar.projects":"Projets",
  "sidebar.projects.list": "Liste des projets",
  "sidebar.projects.create": "Créer un projet",
  "sidebar.users.list": "Liste des utilisateurs",
  "sidebar.users.create": "Créer un utilisateur",
  "users":"Utilisateurs",
  "users.add": " Ajouter un utilisateur",
  "users.create": "Créer un utilisateur",
  "users.businessId": "Business ID:",
  "users.no.projects": "Cet utilisateur n'est associé à aucun projet",
  "users.role.doctor": "Docteur",
  "users.role.admin": "Admin",
  "users.role.sales": "Sales",
  "users.role.paramed": "Paramédical",
  "users.title.doctor": "Dr.",
  "users.title.mister": "M.",
  "users.title.miss": "Mme",
  "users.title.professor": "Pr.",
  "users.remove.filters.sort": "Retirer les tris",
  "workflows" : "Workflows",
  "workflows.add" : "Ajouter un workflow",
  "workflows.delete" : "Supprimer ce workflow du projet",
  "workflows.import" : "Importer un workflow",
  "workflow.new" : "Nouveau workflow",
  "no.flow": "Créer ce workflow sans flow chart ?",
  "no.flow.update": "Enregistrer ce workflow sans flow chart ?",
  "no.workflows": "Ce projet n'a pas encore de workflows",
  "workflows.triggers" : "Triggers",
  "workflow.medEvent.triggers": "Triggers Événement médical",
  "workflows.medEvent": "Événement médical",
  "workflow.patient.triggers": " Triggers Patient",
  "workflow.variables.triggers": "Triggers Variables",
  "workflow.days": "Jours",
  "workflow.diff": "Délai:",
  "workflow.no.trigger": "Pas de trigger",
  "workflow.hours": "Heures",
  "workflow.minutes": "Minutes",
  "workflow.atTime": "Heure de déclenchement:",
  "workflow.triggerOnCreate": "Déclenchement:",
  "workflow.triggerOnCreate.patient": "À la création",
  "workflow.triggerOnCreate.all": "Toute création",
  "workflow.triggerOnCreate.first": "Première création",
  "workflow.triggerOnCreate.allButFirst": "Toute création, sauf la première",
  "workflow.addAction": " Ajouter une action",
  "workflow.addCondition": " Ajouter une condition",
  "workflow.actions.connection": "Connection entre les actions",
  "workflow.medEvent": "Événement médical",
  "workflow.medEvent.location": "Condition - Lieu",
  "workflow.send.sms.doctor": "Envoyer un sms au docteur",
  "workflow.send.sms.patient": "Envoyer un sms au patient",
  "workflow.message": "Message:",
  "workflow.french": "Français:",
  "workflow.english": "Anglais:",
  "workflow.copilot.conversation.start": "Commencer une conversation",
  "workflow.variables.condition": "Condition - Variable",
  "workflow.new.condition": "Nouvelle condition",
  "workflow.equalTo": "Égal à",
  "workflow.conditions": "Conditions",
  "workflow.parameters": "Paramètres:",
  "workflow.choose.action": "Choisir une action",
  "workflow.update": "Mise à jour workflow",
  "workflow.choose.workflow": "Choisir un workflow"}

export default fr;