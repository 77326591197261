import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Form, Button, Input, Radio, Checkbox, Select, message } from "antd";
import { language, requests, genFunctions, formats, projectsActions, AddIcon, BasicModal,CreateMedAlert, CreateLocation} from "../_index.js";

const RadioGroup = Radio.Group;
const Option = Select.Option;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};


const CreateProject = ({projectsData, phoneNumbersData, usersData, addProjectComp, access_token}) => {

	const modalRef = useRef();
	const history = useHistory(); 

	const [form] = Form.useForm();
	const [content, setContent] = useState("");
	const [usersProject, setUsersProject] = useState([]);
	const [project, setProject] = useState({
		PhoneNumberId: "" ,
		smsSender: "",
		name: "",
		locations: [], 
		medAlerts: []
	});

	
	useEffect(() => {
		let hideItems = document.querySelectorAll('.hide');
		hideItems.forEach(item => {
			item.style.display='none'
		});
	});

	
	const showModal = (e, type, data) => {
		e.preventDefault();
		if (type === "location") setContent(<CreateLocation cancelForm={closeModal} saveItemParent={saveItem} locationData={data} /> )
		else if (type === "medAlert") setContent(<CreateMedAlert cancelForm={closeModal} saveItemParent={saveItem} />)

		modalRef.current.showModal();
	};

	const closeModal = () => {
		modalRef.current.closeModal();
	};

// ==================== FORM FUNCTIONS ==================== //
// ------------------ State ------------------ //
	const handleState = (prop) => (event) => {
		event.target ? setProject({ ...project, [prop]: event.target.value }) : setProject({ ...project, [prop]: event });
	};

// ------------------ Handling of the showing of radio buttons ------------------ //
	const showAccess = (id) => {
    let finalUsers = [...usersProject];
    let radio = document.getElementById(`radio-group-${id}`);
    if (radio.style.display === 'none' || !radio.style.display){  
      genFunctions.showItems(radio);
      let newUser = {
        UserId: id, 
        access: ""
      };
      finalUsers.push(newUser);
      setUsersProject(finalUsers)
    } else {
      genFunctions.hideItems([radio]);
      
			let index = usersProject.map((user, i) => user.UserId).indexOf(id);
			finalUsers.splice(index, 1);
			setUsersProject(finalUsers);
    };
	};	

// ------------------ Adding user to project in state ------------------ //
	const addUserToProject = (access, id) => {
		let finalUsers = [...usersProject];
		let index = usersProject.map((user, i) => user.UserId).indexOf(id);

		if ( index === -1){
			let newUser = {
				UserId: id, 
				access
			};
			finalUsers.push(newUser);
		} else {
			finalUsers[index].access = access;
		};

		setUsersProject(finalUsers);
	};

// ------------------ Pre-save location or medAlert to project in state ------------------ //
	const saveItem = (item, type) => {
		let array = project[type];
		array.push(item);
		setProject({...project, [type]: array})
	};

// ------------------ Delete location or medAlert to project in state ------------------ //
	const deleteItem = (type, i) => {
		let array = project[type];
		array.splice(i, 1);
		setProject({...project, [type]: array})
	};

	// ==================== SAVE PROJECT ==================== //
	const handleForm = () => {
		let error = [];

		let errorNames = usersProject.filter(user => !user.access);
    let arrayNames = errorNames.map(error => {
      let name = usersData.filter(user => user.id == error.UserId)[0];
      name = ` ${name.firstName} ${name.lastName}`;
      return name;
    });

		if (arrayNames.length) {
			if (error.length) error.map(err => message.error(`${err}`));
			if (arrayNames.length) message.error(`Vous n'avez pas défini d'accès au projet pour: ${arrayNames}`);
		} else {
			let projectCopy = {...project};
			delete projectCopy.locations; delete projectCopy.medAlerts;

			requests.requestPost('/projects', projectCopy, access_token)
			.then(res => {
				res.data.Users = []; res.data.locations = []; res.data.medAlerts = []; res.data.workflows = [];

			// Création de UserProjects;
				usersProject.forEach(user => {
					let thisUser = usersData.filter(item => item.id === user.UserId)[0];
					let body = {
						access: user.access,
						UserId: user.UserId,
					};
					requests.requestPost(`/projects/${res.data.id}/users`, body, access_token)
					.then(response => {
						let newUser = {
							id: thisUser.id,
							firstname: thisUser.firstName,
							lastnamt: thisUser.lastName,
							title: thisUser.title,
							UsersProject: response.data
						};
						res.data.Users.push(newUser);
					})
					.catch(error => {
						console.log(error);
						message.error(`L'utilisateur ${thisUser.firstname} ${thisUser.lastName} n'as pas pu être ajouté au projet: ${error}`)
					})
				});

			// Création des lieux
				project.locations?.forEach(location => {
					requests.requestPost(`/projects/${res.data.id}/locations`, location, access_token)
					.then(response => {
						res.data.locations.push(response.data);
					})
					.catch(error => {
						console.log(error);
						message.error(`Le lieu ${location.name} n'a pas pu être créé: ${error}`)
					});
				});

			// Création des alertes médicales
				project.medAlerts?.forEach(medAlert => {
					requests.requestPost(`/projects/${res.data.id}/med-alerts`, medAlert, access_token)
					.then(response => {
						res.data.medAlerts.push(response.data)
					})
					.catch(error => {
						console.log(error);
						message.error(`L'alerte ${medAlert.label} n'a pas pu être créée: ${error}`)
					});
				});
	
				message.success(`Le projet ${project.name} a été créé`);
				addProjectComp(res.data);
				setTimeout(function(){
					history.push(`/project/${res.data.id}`);
				}, 800);
			})
			.catch(error => {
				console.log(error.response);
				message.error(`Erreur lors de la création du projet: ${error.response.data.message}`)
			});
		};

	};

  return(
		<div className="gx-card display-card create-project-main">
			<h1> {language["project.new"]} </h1>

		<Form
      form={form}
			{...formItemLayout}
      name="create-project"
      onFinish={handleForm}
      scrollToFirstError
			className="display-card-main">

			<div className="display-card-part">
				<div className="project-infos-part">
					<Form.Item
						name="projectName"
						label="Nom"
						className="form-item-space"
						rules={[{required: true, message: language["form.mandatory.name"]},]}
						>
						<Input className="input-form-special" onChange={handleState('name')} />
					</Form.Item>

					<Form.Item
						name="projectPhone"
						label="Téléphone"
						className="form-item-space"
						rules={[{required: true, message: language["form.mandatory.phone"]},]}	>
						<Select className="input-form-special" onChange={handleState('PhoneNumberId')}>
							{phoneNumbersData.map(phoneNumber => (
								<Option key={phoneNumber.id} value={phoneNumber.id} >{formats.formatPhoneNumber(phoneNumber.phone)} </Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item
						name="projectSender"
						label="Expéditeur"
						className="form-item-space"
						initialValue={project.smsSender}
						rules={[{max: 11, message: language['form.11.characters']},]}
						>
						<Input className="input-form-special" onChange={handleState('smsSender')} />
					</Form.Item>
				</div>

				<div className="display-list users-part">
					<h3>{language["users"]}</h3>
					<div className="select-div-user-main">
						<ul className="select-div-user">
							{usersData.map(user => (
								<li key={user.id} className="no-list-style add-user-item">
									<Checkbox onChange={()=> showAccess(user.id)} className="checkbox-form" >{ user.title === "Dr." ? `${user.title} ${user.firstName} ${user.lastName} (${formats.formatRole(user.role)})` : `${user.firstName} ${user.lastName}`}</Checkbox>
									<RadioGroup id={`radio-group-${user.id}`} className="radio-access hide">
										<Radio value="guest" className="radio-form" onClick={(e) => addUserToProject(e.target.value, user.id)}>Guest</Radio>
										<Radio value="owner" className="radio-form" onClick={(e) => addUserToProject(e.target.value, user.id)}>Owner</Radio>
									</RadioGroup>
								</li>
							))}
						</ul>
					</div>
				</div>

			</div>

			<div className="display-card-part">
				<div className="display-list med-alerts-height">
					<h3> {language["medAlerts"]} </h3>
					<button onClick={(e) => showModal(e, "medAlert")} className="btn-no-style add-icon-btn no-link" size="large" > <span className="add-icon"><AddIcon /></span> {language["medAlerts.add"]} </button>

					{project.medAlerts?.length ? (
						<ul className="med-alert-list gx-card-widget gx-card-profile-sm">
							{project.medAlerts.map((alert, i) => (
								<li className="no-list-style med-alert-info" >
									<i className="icon icon-close-circle margin-left icon-hover pointer icon-delete-location" value={i} onClick={() => deleteItem('medAlerts', i)} />
									<div>
										<div className="flex">
											<div className="med-alert-name">
												<h5>{alert.label} </h5>
											</div>
											<span className="gx-d-inline-block gx-toolbar-separator">&nbsp;</span>
											<span> {alert.type} </span>
										</div>
						
										{alert.description? (
												<p><span className="bold">{language["common.description"]} </span>{alert.description} </p>
										): <> </> }
						
									</div>
								</li>
							))}
						</ul>
						):(
							<> </>
						)}

				</div>

				<div className="display-list locations-height">
					<h3> {language["locations"]} </h3>
					<button onClick={(e) => showModal(e, "location")} className="btn-no-style add-icon-btn no-link" size="large" > <span className="add-icon"><AddIcon /></span> {language["locations.add"]} </button>

					{project.locations?.length ? (
						<ul className="locations-list">
							{project.locations.map((location, i) => (
								<li className="no-list-style location-card user-card gx-card-widget gx-card-profile-sm">
									<i value={i} onClick={() => deleteItem('locations', i)} className="icon icon-close-circle margin-left icon-hover pointer icon-delete-location"/>
									<div >
										<h4>{location.name} </h4>
										<div className="location-infos">
						
											<div>
												<span>{location.number} {location.street} </span> {location.street || location.number ? <br/> : <> </>}
												<span>{location.zipcode} {location.city} </span> {location.city || location.zipcode ? <br/> : <> </>}
												<span>{location.state} {location.country} </span>
											</div>
											
											<span className="gx-d-inline-block gx-toolbar-separator">&nbsp;</span>
						
											<div>
												{location.phone ? <span><span className="bold"> {language["common.phone"]} </span> {location.phone} </span> : <span>Pas de téléphone enregistré</span>} <br/>                     
												{location.comment ? <span><span className="bold"> {language["common.comment"]} </span> {location.comment} </span> : <> </>}
											</div>
										</div>		
									</div>
								</li>
					
							))}
							</ul>
						):(
							<></>
						)}
				</div>


			</div>					

			<div className="button-div div-create-project">
      <Form.Item>
        <Button type="primary" htmlType="submit" size="large">{language["common.save"]}</Button>
      </Form.Item>
			</div>
    </Form>

			<BasicModal ref={modalRef} content={content} />

		</div>
  )};

export default connect(
	(state) => ({
	  phoneNumbersData: state.phoneNumbers,
	  usersData: state.users,
	  access_token: state.common.token,
		projectsData: state.projects
	}),
	(dispatch) => ({
		addProjectComp: data => dispatch(projectsActions.addProject(data))
	})
	
)(CreateProject);
  