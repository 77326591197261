import React from "react";
import { Select } from "antd";
import { language } from "../_index.js"

const Option = Select.Option;

const ChooseAction = ({ openActionParent, project, type }) => {

    const displayAction = (value) => {   
        openActionParent(value);        
    };


    return(
        <div className="inside-modal">
            <div id="choose-action-form" >
                <h4>{language["workflow.choose.action"]} </h4>
                <div className="choose-action-form">
                    {type === "action" ? 
                    <Select onChange={displayAction} className="workflow-input select-width" placeholder="Action">
                            <Option value="sms.send.patient">{language["workflow.send.sms.patient"]}</Option>
                             <Option value="sms.send.doctor">{language["workflow.send.sms.doctor"]}</Option>
{project.medAlerts.length ? <Option value="medAlert">{language["medAlert"]} </Option> : <> </>}
                        <Option value="copilot.conversation.start">{language["workflow.copilot.conversation.start"]} </Option>
                    </Select>
                     :
                     <Select onChange={displayAction} className="workflow-input select-width" placeholder="Action">
                     <Option value="condition.variable">{language["workflow.variables.condition"]} </Option>
{project.locations.length ? <Option value="condition.med-event.location">{language["workflow.medEvent.location"]}</Option>: <> </>}
             </Select>

                    }
                </div>
            </div>
        </div>
    )
}

export default ChooseAction;