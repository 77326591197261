import React, { useState, useEffect } from "react";
import { Button, Input, message, Form } from "antd";
import { ArrowBackIosNewRoundedIcon, language, genFunctions } from "../_index";

const { TextArea } = Input;

const SendSmsPatient = ({saveActionParent, cancelForm, goBackParent, action}) => {

	const initState ={
		name: "",
		type: "sms.send.patient",
		english: "",
		french: "",
		from: ""
	}
	const [value, setValue] = useState(initState);

	useEffect(() => {
		if (action) setValue({ ...value, 
			name: action.name,
			english: action.parameters.message.en,
			french: action.parameters.message.fr,
			from: action.parameters.from,
			id: action.id
		});

		let hideItems = document.querySelectorAll('.hide');
		hideItems.forEach(item => {
			item.style.display='none'
		});

	}, [action]);

	const handleChange = (prop) => (event) => {
		if (prop === "from") {
			if (event.target.value.length < 12) setValue({...value, [prop]: event.target.value});
			else message.error ('Le nombre de caractère est limité à 11')
		} else setValue({...value, [prop]: event.target.value});
	};

	const goBack = (e) => {
		goBackParent(e);
	};

	const handleMandatory = (e) => {
		e.target.classList.remove('mandatory-input');
	};

	const saveAction = () => {
		if (!value.name || !value.french || !value.english){
			const fields = document.querySelector('#mandatory-fields');
			genFunctions.showItems(fields);
			let inputs = document.querySelectorAll('.check-value');
			inputs.forEach(input => {
				if (!value[input.title]) input.classList.add("mandatory-input");
			});

		} else {
				let finalAction = {
				type: value.type,
				name: value.name,
				parameters: {
					from: value.from,
					message:{
						en: value.english,
						fr: value.french
					}
				}
			};
			if (action) finalAction.id = value.id;

			if (JSON.stringify(finalAction) !== JSON.stringify(action)) saveActionParent(finalAction);
			cancelForm();
		};
	};

	return(
		<div className="choose-action-card">
		{!action ? <Button className="go-back-action" size="small" onClick={goBack}> <span className="icon-back"><ArrowBackIosNewRoundedIcon style={{fontSize: "14px"}} /></span> <span className="back">{language["common.goBack"]} </span></Button> : <> </>}

			<h4>{language["workflow.send.sms.patient"]}</h4>
				<small id="mandatory-fields" className="mandatory-color hide">Vous n'avez pas rempli tous les champs obligatoires</small>
			<div>
				<Input placeholder="Nom *" label="name" value={value.name} onChange={handleChange('name')} className="workflow-input check-value" onClick={handleMandatory}/>
				
				<Input placeholder="Expéditeur" label="from" value={value.from} onChange={handleChange('from')} className="workflow-input"	/>

				<h5 className="trigger-input"> {language["workflow.message"]}</h5>
				<TextArea
					placeholder="Français"
					label="fr"
					value={value.french}
					onChange={handleChange('french')}
					className="workflow-input  check-value"
					onClick={handleMandatory}
					rows={4}
					/>

				<TextArea
					placeholder="Anglais"
					label="en"
					value={value.english}
					onChange={handleChange('english')}
					className="workflow-input  check-value"
					onClick={handleMandatory}
					rows={4}
					/>

				<div className="button-div">
					<Button type="primary" size="small" onClick={saveAction}>Ok</Button>
				</div>

			</div>
		</div>
	)};

export default SendSmsPatient