import React, {useEffect} from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";

import { language, genFunctions, UserProfile, SidebarLogo } from "../_index.js"

const SubMenu = Menu.SubMenu;


const SidebarContent = () => {
    
  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content sidebar-content">
        <div className={`gx-sidebar-notifications`}>
          <UserProfile/>
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={["projects", "users"]}
            theme='dark'
            mode="inline">

              <SubMenu className="gx-menu-group submenu-title" key="projects" title={
                <span>
                  <i className="icon icon-data-display"/>
                <span>{language["sidebar.projects"]}</span>
              
              </span>}>
                <Menu.Item onClick={genFunctions.deleteGoBack} key="projects/list">
                  <Link to="/projects/list">
                    <span>{language["sidebar.projects.list"]}</span>
                  </Link>
                </Menu.Item>
                <Menu.Item onClick={genFunctions.deleteGoBack} key="projects/create">
                  <Link to="/projects/create">
                    <span>{language["sidebar.projects.create"]}</span></Link>
                </Menu.Item>
              </SubMenu>

              <SubMenu key="users" title={
                <span>
                  <i className="icon icon-profile2"/>
                <span>{language["users"]}</span>
              
              </span>}>
                <Menu.Item onClick={genFunctions.deleteGoBack} key="users/list">
                  <Link to="/users/list">
                    <span>{language["sidebar.users.list"]}</span>
                  </Link>
                </Menu.Item>
                <Menu.Item onClick={genFunctions.deleteGoBack} key="users/create">
                  <Link to="/users/create">
                    <span>{language["sidebar.users.create"]}</span></Link>
                </Menu.Item>
              </SubMenu>

          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);

