import React, { useState, useEffect} from "react";
import {Button, Card, Form, Input, message} from "antd";
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import "./horizontalLoginForm.less";
import { requests } from "../_index";
import { useLocation,useHistory } from "react-router-dom";

const FormItem = Form.Item;

const Login = () => {
  const location = useLocation();
  const history = useHistory();

  const loggedLocale = localStorage.getItem('logged')

  const [user, setUser] = useState({email: "", password: ""});
  const [logged, setLogged] = useState(loggedLocale);


  useEffect(() => {
    if (logged) {
      location.pathname = "/projects/list";
      history.push("/projects/list")
    };

  }, [logged, history, location]);


  const handleState = (prop) => (event) => {
    setUser({...user, [prop]: event.target.value});
  };

  const logUser = () => {
    if (user.email && user.password){
      requests.requestPost('/auth/standard', user)
      .then(async res => {
        if (res.data.role === "admin") {
          localStorage.setItem('logged', true);
          localStorage.setItem('access_token', res.data.access_token);
          let user = await requests.request('/users/me', `Bearer ${res.data.access_token}`);
          localStorage.setItem('user', JSON.stringify(user.data));
          setLogged(true);
        } else {
          message.warning("Cet espace est destiné à l'équipe Curecall. Veuillez vous diriger vers l'espace utilisateur.")
        };
      })
      .catch(error => {
        message.error(`Connexion impossible. Veuillez vérifier votre saisie.`)
        console.log(error.response.data);
      })
    } else {
      message.warning('Vous n\'avez pas entré d\'informations de connection ')
    }

  };

  return (
    <div className="login-comp">
      <div className="login-card-main gx-card">
        <div className="login-logo-div">
          <h1 className="login-title">Curecall | Back-office admin</h1>
        </div>
        <div className=" login-card" >
          <div className="login-picture">
            <img src="/assets/images/login.gif" alt="login" className="login-img" />
          </div>
          <div className="login-form">
            <Form
              initialValues={{remember: true}}
              name="basic"
              onFinish={logUser}
              className="gx-login-form gx-form-row0">

              <FormItem rules={[{required: true, message: 'Please input your E-mail!'}]}>
                <Input prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                      placeholder="Email" label="email" value={user.email} onChange={handleState('email')} />
              </FormItem>

              <FormItem rules={[{required: true, message: 'Please input your Password!'}]}>
                <Input.Password prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                      type="password" placeholder="Mot de passe" label="password" value={user.password} onChange={handleState('password')} />
              </FormItem>

              <Button type="primary" htmlType="submit" className="login-form-button gx-mt-1">
                Se connecter
              </Button>

            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};


export default Login;