import { SAVE_PROJECTS_ACTION, UPDATE_PROJECT_ACTION, ADD_PROJECT_ACTION, ADD_USER_TO_PROJECT, UPDATE_ACCESS_USER_PROJECT,
    DELETE_USER_PROJECT, UPDATE_LOCATION_IN_PROJECT, DELETE_LOCATION_FROM_PROJECT, ADD_LOCATION_TO_PROJECT, ADD_MED_ALERT_TO_PROJECT,
    UPDATE_MED_ALERT_TO_PROJECT,DELETE_MED_ALERT_FROM_PROJECT, ADD_WORKFLOW_TO_PROJECT, UPDATE_WORKFLOW_IN_PROJECT, DELETE_WORKFLOW_FROM_PROJECT
} from "../reducers/projectsReducer.js"

const saveProjects = data => ({
    type: SAVE_PROJECTS_ACTION,
    projects: data
});

const updateProject = data => ({
    type: UPDATE_PROJECT_ACTION, 
    projects: data
});

const addProject = data => ({
    type: ADD_PROJECT_ACTION,
    project: data
});

const addUserToProject = (projectId, user) => ({
    type: ADD_USER_TO_PROJECT, 
    projectId,
    user
});

const updateAccessUserProject = (project) => ({
    type: UPDATE_ACCESS_USER_PROJECT,
    project
});

const deleteUserProject = (projectId, userId) => ({
    type: DELETE_USER_PROJECT,
    projectId,
    userId
});

const addLocationToProject = (projectId, locations) => ({
    type: ADD_LOCATION_TO_PROJECT,
    projectId,
    locations
})

const updateLocation = (projectId, locations) => ({
    type: UPDATE_LOCATION_IN_PROJECT,
    locations,
    projectId
});

const deleteLocation = (projectId, locationId) => ({
    type: DELETE_LOCATION_FROM_PROJECT,
    projectId,
    locationId
});

const addMedAlertsToProject = (projectId, medAlerts) => ({
    type: ADD_MED_ALERT_TO_PROJECT,
    projectId,
    medAlerts
})

const updateMedAlerts = (projectId, medAlerts) => ({
    type: UPDATE_MED_ALERT_TO_PROJECT,
    medAlerts,
    projectId
});

const deleteMedAlerts = (projectId, medAlertId) => ({
    type: DELETE_MED_ALERT_FROM_PROJECT,
    projectId,
    medAlertId
});

const addWorkflowToProject = (projectId, workflow) => ({
    type: ADD_WORKFLOW_TO_PROJECT,
    projectId,
    workflow
});

const updateWorkflowInProject = (projectId, workflow) => ({
    type: UPDATE_WORKFLOW_IN_PROJECT,
    projectId, 
    workflow
})

const deleteWorkflowFromProject = (projectId, workflowId) => ({
    type: DELETE_WORKFLOW_FROM_PROJECT, 
    projectId,
    workflowId
});



export default { saveProjects, updateProject, addProject, addUserToProject, updateAccessUserProject, deleteUserProject, updateLocation,
    deleteLocation, addLocationToProject, addMedAlertsToProject, updateMedAlerts, deleteMedAlerts, addWorkflowToProject, updateWorkflowInProject, 
    deleteWorkflowFromProject
 };