import React, {useState, useEffect} from "react";
import { requests, genFunctions } from "../_index";
import { connect } from "react-redux";

const VariableTrigger = ({variableWorkflowRules, access_token, variablesData, workflowPres}) => {
  
  const [variableRules, setVariablesRules] = useState([]);
  
  useEffect(() => {
    requests.request(`/projects/${variableWorkflowRules.ProjectId}/variable-workflow-rules/${variableWorkflowRules.id}/variable-rules`, access_token)
    .then(res => {
      setVariablesRules(res.data);
    });
  }, []);

  return variableRules.map((variable, i) => (
    <div key={Math.floor(Math.random() * 10000)+i} className="trigger-display-project"> 
      <h5 className="no-margin-bottom variable-title">{genFunctions.findVariableName(variable.VariableId, variablesData)}: </h5>

      {Object.entries(variable).map(([key, value], i) => (
        value !== null && key!== "VariableId" && key !== "VariableWorkflowRuleId" && key !== "createdAt" && key !== "updatedAt" && key !== "id" ? (
          <span key={Math.floor(Math.random() * 10000) +i}>	<span >{genFunctions.findKeyName(key) } {genFunctions.findValue(value)} </span> <br/> </span>
        ):( <> </>)
      ))}
    </div>
  ))
};

export default connect ( 
  (state) => ({
    access_token: state.common.token,
    variablesData: state.workflows.variables,
  })
) (VariableTrigger);