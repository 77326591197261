import React, { useRef, useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { AddUser, AddIcon, UserCard, ArrowBackIosNewRoundedIcon, UpdateProject, BasicModal, EditRoundedIcon, formats, 
   MedAlerts, Locations, Workflows, genFunctions, language } from "../_index.js"

const DisplayProject = ({projectsData, phoneNumbersData}) => {

  const initState = { 
    Users: [],
    medAlerts:[],
    locations:[],
    workflows: [
    ]
  };

  const {id} = useParams();
  const modalRef = useRef();
  const goBack = localStorage.getItem('goBack');
  
  const [content, setContent] = useState("");
  const [project, setProject] = useState(initState);
  
  useEffect(() => {
    let proj = initState;
    if (projectsData.length) proj = projectsData.filter(item => item.id == id)[0];
    setProject(proj);
  }, [project, projectsData]);


  

// ==================== MODAL FUNCTIONS ==================== //
	const showModal = (type) => {
    if (type === "edit") setContent(<UpdateProject project={project} cancelForm={closeModal} />)
    else if (type === "user") setContent(<AddUser project={project} users={project.Users} projectId={project.id} cancelForm={closeModal} />);
    modalRef.current.showModal();
	};

	const closeModal = () => {
		modalRef.current.closeModal();
	};
  

// ==================== COMPONENT FUNCTIONS ==================== //
  const findPhoneNumber = (id) => {
    let phone = {phone: ""};
    if (phoneNumbersData.length) phone = phoneNumbersData.find(phone => phone.id === id);
    return formats.formatPhoneNumber(phone.phone);
  };

  return(
    <div className="gx-card display-card">
      <div className="header-buttons">
        <Button className="go-back"> <Link to={goBack ? goBack : "/projects/list"} onClick={genFunctions.deleteGoBack}> <span className="icon-back"><ArrowBackIosNewRoundedIcon /></span> <span className="back">{language["common.goBack"]} </span></Link> </Button>
        <button onClick={() => showModal("edit")} className="btn-no-style icon-hover"><EditRoundedIcon style={{fontSize:"38px"}} /> </button>
      </div>

      <div className="display-card-main">
      
        <div className="display-card-part">
          <div className="project-header">
            <h1>{project?.name}</h1>
            
            <span className="gx-d-inline-block gx-toolbar-separator">&nbsp;</span>

            <div>
              <span><span className="bold">{language["common.phone"]}: </span> {project?.PhoneNumberId ? findPhoneNumber(project.PhoneNumberId) : ""} </span><br/>
              <span><span className="bold">{language["common.smsSender"]}: </span> {project?.smsSender} </span> <br/>
              <span><span className="bold">{language["common.createdAt"]}</span> {project?.createdAt? formats.formatDate(project?.createdAt) : ""} </span> <br/>
              <span><span className="bold">{language["common.lastUpdate"]}</span> {project?.updatedAt? formats.formatDate(project?.updatedAt) : ""} </span>
            </div>
          </div>
        
          <div className="display-list">
            <h3>{language["project.users"]}</h3>

            <button onClick={() => showModal("user")} className="btn-no-style add-icon-btn" size="large"> <span className="add-icon"><AddIcon /></span>{language["users.add"]} </button>
            {project?.Users?.length? (
              <ul className="users-list">
                {project?.Users?.map(user => (
                  <li key={user.id} className="no-list-style" >
                    <UserCard user={user} project={project} />
                  </li>
                ))}
              </ul>
            ):(
              <div className="users-list">
                <p className="gx-card-widget gx-card-profile-sm no-user-card">{language["project.no.users"]} </p>
              </div>
            )}
          </div>

        </div>

        <div  className="display-card-part">
          <MedAlerts project={project} />
          <Locations project={project} />
        </div>

      </div>

      <Workflows project={project} id={id} />

      <BasicModal ref={modalRef} content={content} /> 
    </div>
  )};

export default connect(
  (state) => ({
    phoneNumbersData: state.phoneNumbers,
    projectsData: state.projects,
    access_token: state.common.token,
    medAlertsData: state.projects.medAlerts
  }), 
)(DisplayProject);
