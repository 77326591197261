import axios from "axios";
require('dotenv').config({path: `.env.${process.env.NODE_ENV}`});


const host = process.env.REACT_APP_HOST;

const request = async (url, token) => {
	let response = await axios.get(`${host}${url}`, {headers: {Authorization: token}});
	
	return response;
};

const requestPost = async (url, body, token) => {
	let response = await axios.post(`${host}${url}`, body, {headers: {Authorization: token}});

	return response;
};

const requestPut = async (url, body, token) => {
	let response = await axios.put(`${host}${url}`, body, {headers: {Authorization: token}});

	return response;
};

const requestDelete = async (url, token) => {
	let response = await axios.delete(`${host}${url}`, {headers: {Authorization: token}});

	return response;
};


const requestRPPS = async (id) => {
	let response = await axios.get(`${process.env.REACT_APP_HOST_RPPS}${id}`)

	return response;
};

const functions = {request, requestPost, requestPut, requestDelete, requestRPPS};

export default functions ;