
const INIT_STATE = {
  navCollapsed: true,
  pathname: '/',
  token: ""
};

export const SAVE_TOKEN = "SAVE_TOKEN";
export const DELETE_TOKEN = "DELETE_TOKEN";

const CommonReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE': {
      return {
        ...state,
        pathname: action.payload.location.pathname,
        navCollapsed: false
      }
    }
    case SAVE_TOKEN: {
      return {...state, token: action.token}
    }
    case DELETE_TOKEN: 
      return {...state, token: ""};
    default:
      return state;
  }
}

export default CommonReducer;
