const initialState = [];

export const SAVE_USERS_ACTION = "SAVE_USERS_ACTION";
export const SAVE_NEW_USER_ACTION = "SAVE_NEW_USER_ACTION";
export const UPDATE_USERS_ACTION = "UPDATE_USERS_ACTION";

export function usersReducer(state = initialState, action) {
    let users = [...state];
    switch(action.type){
        case SAVE_USERS_ACTION : 
            return action.users;

        case UPDATE_USERS_ACTION :
            return action.users;
            
        case SAVE_NEW_USER_ACTION : 
            users.push(action.user)
            return users;

        default: 
            return state
    };
};