const initialState = [];

export const SAVE_PROJECTS_ACTION = "SAVE_PROJECTS_ACTION";
export const UPDATE_PROJECT_ACTION = "UPDATE_PROJECT_ACTION";
export const ADD_PROJECT_ACTION = "ADD_PROJECT_ACTION";
export const ADD_USER_TO_PROJECT = "ADD_USER_TO_PROJECT";
export const UPDATE_ACCESS_USER_PROJECT = "UPDATE_ACCESS_USER_PROJECT";
export const DELETE_USER_PROJECT = "DELETE_USER_PROJECT";
export const ADD_LOCATION_TO_PROJECT = "ADD_LOCATION_TO_PROJECT";
export const UPDATE_LOCATION_IN_PROJECT = "UPDATE_LOCATION_IN_PROJECT";
export const DELETE_LOCATION_FROM_PROJECT = "DELETE_LOCATION_FROM_PROJECT";
export const ADD_MED_ALERT_TO_PROJECT = "ADD_MED_ALERT_TO_PROJECT";
export const UPDATE_MED_ALERT_TO_PROJECT = "UPDATE_MED_ALERT_TO_PROJECT";
export const DELETE_MED_ALERT_FROM_PROJECT = "DELETE_MED_ALERT_FROM_PROJECT";
export const ADD_WORKFLOW_TO_PROJECT = "ADD_WORKFLOW_TO_PROJECT";
export const UPDATE_WORKFLOW_IN_PROJECT = "UPDATE_WORKFLOW_IN_PROJECT";
export const DELETE_WORKFLOW_FROM_PROJECT = "DELETE_WORKFLOW_FROM_PROJECT"; 

const findIndex = (projects, id) => {
    let index = projects.map(proj => proj.id).indexOf(parseInt(id));
    return index;
};

export function projectsReducer(state = initialState, action) {
    let projects = [...state];

    switch(action.type){
        case SAVE_PROJECTS_ACTION : 
            return action.projects;

        case UPDATE_PROJECT_ACTION : 
            return action.projects;

        case ADD_PROJECT_ACTION :
            projects.push(action.project);
            return projects;


        case ADD_USER_TO_PROJECT :
// Find the project, add the user
            let projAddUserIndex = findIndex(projects, action.projectId);
            let projAddUser = projects[projAddUserIndex];
            projAddUser.Users.push(action.user)
            projects[projAddUserIndex] = projAddUser;

            return projects;

        case UPDATE_ACCESS_USER_PROJECT :
// Find the project, find the user, change the access
            let projAccUpIndex = findIndex(projects, action.project.id);
            projects[projAccUpIndex] = action.project;

            return projects;

        case DELETE_USER_PROJECT : 
// Find the project, find the user, delete the user
            const findProjDelId = (el) => el.id === action.projectId;
            let projDelId = projects.findIndex(findProjDelId)
            let projDel = projects[projDelId];
                const findUserDelId = (el) => el.id === action.userId;
                let userDelId = projDel.Users.findIndex(findUserDelId)
            projects[projDelId].Users.splice(userDelId, 1);
            return projects;
        

        case ADD_LOCATION_TO_PROJECT :
            let projectLocationIndex = projects.map(proj => proj.id).indexOf(parseInt(action.projectId))
            projects[projectLocationIndex].locations = action.locations;
            return projects;

        case UPDATE_LOCATION_IN_PROJECT :
            let projUpLocId = projects.map(proj => proj.id).indexOf(action.projectId);
            projects[projUpLocId].locations = action.locations
           return projects
        
        case DELETE_LOCATION_FROM_PROJECT :
            let projDelLocId = projects.map(proj => proj.id).indexOf(action.projectId);
            let locDelId = projects[projDelLocId].locations.map(loc => loc.id).indexOf(action.locationId);
            projects[projDelLocId].locations.splice(locDelId, 1);
            return projects


        case ADD_MED_ALERT_TO_PROJECT :
            let projectAlertIndex = projects.map(proj => proj.id).indexOf(parseInt(action.projectId))
            projects[projectAlertIndex].medAlerts = action.medAlerts;
            return projects;

        case UPDATE_MED_ALERT_TO_PROJECT :
            let projUpAlertId = projects.map(proj => proj.id).indexOf(action.projectId);
            projects[projUpAlertId].medAlerts = action.medAlerts
            return projects;
        
        case DELETE_MED_ALERT_FROM_PROJECT :
            let projDelAlertId = projects.map(proj => proj.id).indexOf(action.projectId);
            let alertDelId = projects[projDelAlertId].medAlerts.map(loc => loc.id).indexOf(action.medAlertId);
            projects[projDelAlertId].medAlerts.splice(alertDelId, 1);
            return projects;


        case ADD_WORKFLOW_TO_PROJECT :
            let projectWorkflowIndex = findIndex(projects, action.projectId);
            let projectWorkflows = projects[projectWorkflowIndex].workflows;
            projectWorkflows.push(action.workflow);
            projects[projectWorkflowIndex].workflows = projectWorkflows;
            return projects;

        case UPDATE_WORKFLOW_IN_PROJECT :
            let projectUpWfIndex = findIndex(projects, action.projectId);
            let WfToUpdateIndex = findIndex(projects[projectUpWfIndex].workflows, action.workflow.id);
            projects[projectUpWfIndex].workflows[WfToUpdateIndex] = action.workflow;
            return projects;

        case DELETE_WORKFLOW_FROM_PROJECT : 
            let projWokflowToDelId = findIndex(projects, action.projectId);
            let workflowToDelId = findIndex(projects[projWokflowToDelId].workflows, action.workflowId);
            projects[projWokflowToDelId].workflows.splice(workflowToDelId, 1);
            return projects;

        default: 
            return state
    };
};