import React, { useState, useEffect } from "react";
import { Button, Select, Input, Radio, InputNumber, message } from "antd";
import { genFunctions, language } from "../_index.js";
import { connect } from "react-redux";
import ConditionVariables from "./ConditionVariables.js";

const Option = Select.Option;
const RadioGroup = Radio.Group;

const ModalVariable = ({sendVariable, variablesData, closeModalParent}) => {

	const propTime = ['gt', 'gte', 'lt', 'lte', 'eq', 'neq'];
	const allProps = ['gt', 'gte', 'lt', 'lte', 'eq', 'neq', 'eqBool', 'eqString', 'neqString'];
	const initState = {
		VariableId: "",
		VariableWorkflowRuleId: "",
		gt: undefined,
		gte: undefined,
		lt: undefined,
		lte: undefined,
		eq: undefined,
		neq: undefined,
		eqBool: undefined,
		eqString: undefined,
		neqString: undefined,
	};

	const [variable, setVariable] = useState(initState);
	const [type, setType] = useState("");

	useEffect(() => {
    let hideItems = document.querySelectorAll('.hide');
    hideItems.forEach(item => {
      item.style.display='none'
    });

  }, []);
  	
// ==================== FORM FUNCTIONS ==================== //
// ------------------ State ------------------ //
	const handleState = (prop) => (event) => {
		if (propTime.includes(prop) && event === null) event = {target: {value: 0}}
		event.target ? setVariable({ ...variable, [prop]: event.target.value }) : setVariable({ ...variable, [prop]: event });
	};

// ------------------ Handling of the type of variable ------------------ //
  const handleChange = (event) => {
    const booleanDiv = document.querySelector('#special-boolean');
    const numberDiv = document.querySelector('#special-number');
    const stringDiv = document.querySelector('#special-string');

		let chosenVariable = variablesData.filter(v => v.id === event)[0];
		setVariable({...variable, VariableId: event})
		setType(chosenVariable.label);

		if (chosenVariable.type === "BOOLEAN") {
			genFunctions.showItems(booleanDiv); genFunctions.hideItems([numberDiv, stringDiv]);
		} else if (chosenVariable.type === "INTEGER"){
			genFunctions.showItems(numberDiv);	genFunctions.hideItems([booleanDiv, stringDiv]);
		} else if (chosenVariable.type === "STRING"){
			genFunctions.showItems(stringDiv);	genFunctions.hideItems([booleanDiv, numberDiv]);
		}
  };

// ==================== SAVE FUNCTIONS ==================== //
  const saveVariable = () => {
	const booleanDiv = document.querySelector('#special-boolean');
	const numberDiv = document.querySelector('#special-number');
	const stringDiv = document.querySelector('#special-string');

		let filled = allProps.filter(prop => variable[prop] !== undefined);
		
		if(!variable.VariableId) closeModalParent();
		else if (!filled.length) message.error('Vous devez ajouter une valeur à la condition');
		else {
			sendVariable(variable);
			setVariable(initState);
			genFunctions.hideItems([booleanDiv, numberDiv, stringDiv]);
			setType('');
			closeModalParent();
		};
  };

  return(
		<div className="modal-variable inside-modal">
			<h3>{language["workflow.new.condition"]} </h3>

			<div>
				<Select className="input-condition workflow-input" value={type} onChange={handleChange} placeholder="Condition" >
					{variablesData.map(variable => (
						variable.type !== "OPTIONS" && variable.type !== "INTEGER_VALUE_RANGE" && variable.type !== "AMSLER_GRID" ?
						<Option key={variable.id} value={variable.id}>{variable.label || variable.name}</Option>
						: <></>
					))}
				</Select>

				<div id="special-boolean" className="hide trigger-input">
					<h4 className="title-no-margin"> {language["workflow.equalTo"]}</h4>
					<RadioGroup label="eqBool" name="equalTo" value={variable.eqBool} onChange={handleState('eqBool')} className="radio-workflow">
						<Radio value={true} className="radio-form">{language["common.true"]}</Radio>
						<Radio value={false} className="radio-form">{language["common.false"]}</Radio>
					</RadioGroup>
				</div>

				<div id="special-number" className="hide trigger-input">
					<InputNumber value={variable.gt} label="gt" size="small" onChange={handleState('gt')} placeholder="Supérieur à" className="workflow-input input-condition" /> <br/> 
					<InputNumber value={variable.gte} label="gte" size="small" onChange={handleState('gte')} placeholder="Supérieur ou égal à" className="workflow-input input-condition" /> <br/> 
					<InputNumber value={variable.eq} label="eq" size="small" onChange={handleState('eq')} placeholder="Égal à" className="workflow-input input-condition" /> <br/> 
					<InputNumber value={variable.lte} label="lte" size="small" onChange={handleState('lte')} placeholder="Inférieur ou égal à" className="workflow-input input-condition" /> <br/> 
					<InputNumber value={variable.lt} label="lt" size="small" onChange={handleState('lt')} placeholder="Inférieur à" className="workflow-input input-condition" /> <br/>
					<InputNumber value={variable.neq} label="neq" size="small" onChange={handleState('neq')} placeholder="Différent de" className="workflow-input input-condition" /> 
				</div>
				
				<div id="special-string" className="hide trigger-input">
					<Input value={variable.eqString} label="eqSting" size="small" onChange={handleState('eqString')} placeholder="Réponse" className="workflow-input number-input" /> <br/> 
					<Input value={variable.neqString} label="neqString" size="small" onChange={handleState('neqString')} placeholder="Réponse différente de" className="workflow-input number-input" /> <br/> 
				</div>

				<div className="two-button-div">
					<Button size="small" onClick={closeModalParent}>{language["common.cancel"]}</Button>
					<Button type="primary" size="small" onClick={saveVariable}>Ok</Button>
				</div>
			</div>
		</div>
	)};

export default connect (
	(state) => ({
		variablesData: state.workflows.variables
	})
) (ModalVariable);