import React, { useState, useEffect } from "react";
import { Button, Input, Select, message, Form, Row } from "antd";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { requests, genFunctions, formats, usersActions, language, formItemLayout } from "../_index";
import {SearchOutlined } from "@ant-design/icons";


const Option = Select.Option;

const CreateUser = ({access_token, saveNewUserComp}) => {

	const initState = {
		role: undefined, nationalRegisterId: undefined, title: undefined, firstName: undefined,
		lastName: undefined, email: undefined, phone: undefined, speciality: undefined,  civility: undefined, 
		businessID: undefined, companyName: undefined, street: undefined, zipcode: undefined, city: undefined,  
		companyPhone: undefined,  companyEmail: undefined, locale: "fr", 
	};

	const history = useHistory(); 
	
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();

	const [user, setUser] = useState(initState);

	useEffect(() => {
		let hideItems = document.querySelectorAll('.hide');
		hideItems.forEach(item => {
			item.style.display='none'
		});

  }, []);

// ==================== FORM FUNCTIONS ==================== //
// ------------------ State ------------------ //
	const handleState = (prop) => (event) => {
		event.target ? setUser({ ...user, [prop]: event.target.value }) : setUser({ ...user, [prop]: event });
	};

// ------------------ Handling of the role ------------------ //  
	const handleRole = (value) => {
		const roleDoctor = document.querySelector('#role');
		const infos = document.querySelector('#infos');

		if (value === "doctor") {
			genFunctions.showItems(roleDoctor);
			genFunctions.hideItems([infos]);
			setUser((prev) => ({...prev, title: "Dr."}));	
			form.setFieldsValue({title: "Dr."})
		} else {
			genFunctions.showItems(infos);
			genFunctions.hideItems([roleDoctor]);	
			setUser((prev) => ({...prev, title: undefined}));	
			setUser((prev) => ({...prev, nationalRegisterId: ""}));
			setUser((prev) => ({...prev, speciality: ""}));
			form.setFieldsValue({title: undefined, nationalRegisterId: "", speciality: ""})
		};

		setUser((prev) => ({ ...prev, role: value}));
	};
	
// ------------------ Handling of the RPPS datas ------------------ //
  const searchRPPS = () => {
		const infos = document.querySelector('#infos');
    setLoading(true);
		genFunctions.showItems(infos)
		
		if (user.nationalRegisterId){ 
			requests.requestRPPS(user.nationalRegisterId)
			.then(res => {
					Object.entries(res.data.user).forEach(([key, value]) => {
						if (key !== "streetNumber") {
							if (key == "firstname") {
								setUser((prev) => ({ ...prev, firstName: value}));
								form.setFieldsValue({firstName: value})
							} else if (key == "lastname") {
								setUser((prev) => ({ ...prev, lastName: value}));
								form.setFieldsValue({lastName: value})
							} else {
								setUser((prev) => ({ ...prev, [key]: value}))};
								form.setFieldsValue({[key]: value});
						};
					});

				setUser((prev) => ({...prev, street: `${res.data.user.streetNumber} ${res.data.user.street}`}));
				form.setFieldsValue({street: `${res.data.user.streetNumber} ${res.data.user.street}`});
				setLoading(false);
			})
			.catch(error => {
					if (error.response.status === 404) message.error(`Impossible de trouver les informations RPPS: ${error.response.data}`)
					else message.error(`Erreur lors de la recherche ds informations RPPS: ${error.response.data}`)
					setLoading(false);
					setUser(initState);
			});
		} else{
			message.info("Vous n'avez pas entré de numéro RPPS")
			setLoading(false);
		}; 
	};

// ==================== SAVE USER ==================== //
	const saveNewUser = () => {
		let finalUser = {...user};


		if (finalUser.role !== "doctor" && finalUser.title === "Dr."){
			message.error('Incohérence entre le titre et le rôle');
			let inputs = document.querySelectorAll('.check-value');
			inputs.forEach(input => {
				input.classList.add("mandatory-input");
			});
		} else {
			if (finalUser.streetNumber ) delete finalUser.streetNumber;
			finalUser.phone = formats.formatPhoneNumberUser(finalUser.phone)
			if (finalUser.companyPhone) finalUser.companyPhone = formats.formatPhoneNumberUser(finalUser.companyPhone);
			delete finalUser.nationalRegisterId;

			Object.entries(finalUser).forEach(([key, value]) => {
				if (!value) delete finalUser[key];
			});
	
			requests.requestPost('/users', finalUser, access_token)
			.then(res => {
				saveNewUserComp(res.data);
				message.success(`${res.data.firstName} ${res.data.lastName} a bien été créé`)
				setTimeout(function(){
					history.push(`/user/${res.data.id}`)
				}, 1200)
			})
			.catch(error => {
				console.log(error.response);
				message.error(`Problème avec la création de l\'utilisateur: ${error.response.data.message}`)
			});
		};
	};

	console.log(window.innerWidth);
				
  return(
    <div className="create-user gx-card">
			<h1 className="create-user-title">{language["users.create"]}</h1>
			<div className="mandatory-div create-user-title">
				<small className="mandatory">{language["common.mandatory.fields"]} </small>
			</div>
				<Form
				form={form}
				name="create-user"
				onFinish={saveNewUser}
				{...formItemLayout}
				scrollToFirstError>
				
			<div className="create-user-form">
					<div>
						<Form.Item
							name="role"
							label="Rôle"
							className="form-item-space"
							rules={[{required: true, message: language["form.mandatory.role"]},]}
							>
							<Select className="input-form-special" onChange={handleRole} >
								<Option value="doctor">{language["users.role.doctor"]}</Option>
								<Option value="admin">{language["users.role.admin"]}</Option>
								<Option value="paramed">{language["users.role.paramed"]}</Option>
								<Option value="sales">{language["users.role.sales"]}</Option>
							</Select>
						</Form.Item>
					</div>
					<div id="role" className="relative rpps-div">
						<Form.Item
							name="nationalRegisterId"
							label="N° RPPS"
							className="form-item-space"
							>
							<Input className="input-form-special" onChange={handleState('nationalRegisterId')} />
						</Form.Item>
						<div className="button-rpps" >
							<Button size="medium" type="primary" id="search-button" loading={loading} onClick={searchRPPS} icon={<SearchOutlined />} > {language['common.search']} </Button>
							<Button size="medium" type="primary" id="search-button-icon" loading={loading} onClick={searchRPPS} icon={<SearchOutlined />} > </Button>
						</div>
					</div>
						<div id="infos" className="hide">
							<Form.Item
								name="title"
								label="Titre"
								className="form-item-space"
								rules={[{required: true, message: language["form.mandatory.title"]},]}
								>
								<Select className="input-form-special check-value" value={user.title} onChange={handleState('title')} >
									<Option value="Dr.">{language["users.title.doctor"]}</Option>
									<Option value="M.">{language["users.title.mister"]}</Option>
									<Option value="Mme.">{language["users.title.miss"]}</Option>
									<Option value="Pr.">{language["users.title.professor"]}</Option>
								</Select>
							</Form.Item>
							<Form.Item
								name="firstName"
								label="Prénom"
								className="form-item-space"
								rules={[{required: true, message: language["form.mandatory.firstName"]},]}
								>
								<Input className="input-form-special" onChange={handleState('firstName')} />
							</Form.Item>
							<Form.Item
								name="lastName"
								label="Nom"
								className="form-item-space"
								rules={[{required: true, message: language["form.mandatory.name"]},]}
								>	
								<Input className="input-form-special" onChange={handleState('lastName')} />
							</Form.Item>

							<Form.Item
									name="email"
									label="Email"
									className="form-item-space"
									rules={[
										{required: true, message: language["form.mandatory.email"]},
									]}
									>
								<Input className="input-form-special" onChange={handleState('email')} />
							</Form.Item>
							<Form.Item
									name="phone"
									label="Téléphone"
									className="form-item-space"
									rules={[{required: true, message: language["form.mandatory.user.phone"]},]}
									>
								<Input className="input-form-special" onChange={handleState('phone')} />
							</Form.Item>


					{user.role === "doctor" ? 
							<Form.Item
								name="specialty"
								label="Spécialité"
								className="form-item-space"
								>
								<Input className="input-form-special" onChange={handleState('speciality')} />
							</Form.Item>
						:
						<></>
						}
							<Form.Item
								name="companyName"
								label="Lieu de travail"
								className="form-item-space"
								>
									<Input className="input-form-special" onChange={handleState('companyName')} /> <br/>
								</Form.Item>
							<Form.Item
								name="companyEmail"
								label="Email du travail"
								className="form-item-space"
								>
								<Input className="input-form-special" onChange={handleState('companyEmail')}/> <br/>
							</Form.Item>
							<Form.Item
								name="companyPhone"
								label="Téléphone du travail"
								className="form-item-space"
								>
								<Input className="input-form-special" onChange={handleState('street')} /> 
							</Form.Item>
							<Form.Item
								name="street"
								label="Adresse"
								className="form-item-space"
								>
								<Input className="input-form-special" onChange={handleState('companyPhone')} /> 
							</Form.Item>
							<Form.Item
								name="zipcode"
								label="Code postal"
								className="form-item-space"
								>							
								<Input className="input-form-special" onChange={handleState('zipcode')} />
							</Form.Item>
							<Form.Item
								name="city"
								label="Ville "
								className="form-item-space"
								>						
								<Input className="input-form-special" onChange={handleState('city')} />
							</Form.Item>
					
					</div> 

					</div>
					<div className="button-div">
						<Button type="primary" htmlType="submit" >{language['common.save']} </Button>
					</div>
				</Form>
		</div>
	)};

export default connect(
	(state) => ({
		access_token: state.common.token,
	}),
	(dispatch) => ({
		saveNewUserComp: data => dispatch(usersActions.saveNewUser(data))
	})
)(CreateUser);


