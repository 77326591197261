import { createStore, combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import common from "../reducers/Common";
import {projectsReducer as projects} from "../reducers/projectsReducer"
import {usersReducer as users } from "../reducers/usersReducer";
import {phoneNumbersReducer as phoneNumbers } from "../reducers/phoneNumbersReducers";
import { workflowsReducer as workflows } from '../reducers/workflowsReducer';



const createBrowserHistory = require('history').createBrowserHistory;
export const history = createBrowserHistory();

const persistConfig = {
  key: 'root',
  storage: storage,
  // whitelist:['common', 'projects', 'users', 'workflows']
}; 

export const USER_LOGOUT = "USER_LOGOUT";


const appReducer = combineReducers({common, projects, users, phoneNumbers, workflows})
const rootReducer = (state, action) => {
    if (action.type === USER_LOGOUT){
      localStorage.clear();
      localStorage.removeItem("persist:root");
      return appReducer(undefined, action)
    };
    return appReducer (state, action);
};

const pReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(pReducer);
const persistor = persistStore(store);

export { store, persistor };
