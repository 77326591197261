import React, { useState, useEffect } from "react";
import { Button, Input, message, Select, Form } from "antd";
import { useParams } from "react-router-dom";
import { requests, language, usersActions, formats, formItemLayout } from "../_index.js"
import { connect } from "react-redux";

const Option = Select.Option;

const UpdateUser = ({userParent, cancelForm, usersData, access_token, saveUsersComp, updateUserParent}) => {

	let initState = {role: undefined, title: undefined, firstName: undefined,
		lastName: undefined, email: undefined, phone: undefined, speciality: undefined,  civility: undefined, 
		businessID: undefined, companyName: undefined, street: undefined, zipcode: undefined, city: undefined,  
		companyPhone: undefined,  companyEmail: undefined, locale: "fr"};

	const [user, setUser] = useState(initState);
	const {id} = useParams();
	const [form] = Form.useForm();

	useEffect(() => {
		let thisUser = userParent;
		thisUser.phone = thisUser.phone ? formats.formatPhoneNumber(thisUser.phone) : undefined;
		thisUser.companyPhone = thisUser.companyPhone ? formats.formatPhoneNumber(thisUser.companyPhone) : undefined;
		if (thisUser.Projects ) delete thisUser.Projects;
		setUser({...thisUser});

		Object.entries(thisUser).map(([key, value]) => {
			form.setFieldsValue({[key]: value})
		});

	}, [userParent]);

// ==================== FORM FUNCTIONS ==================== //  
// ------------------ State ------------------ //
	const handleState = (prop) => (event) => {
		event.target ? setUser({ ...user, [prop]: event.target.value }) : setUser({ ...user, [prop]: event });
	}; 

	const handleRole = (event) => {
		setUser({...user, role: event});
	};

	const handleMandatory = (e) => {
		e.target.classList.remove('mandatory-input');
	};
	
// ==================== SAVE FUNCTIONS ==================== //  
	const handleForm = () => {
		if (JSON.stringify(user) !== JSON.stringify(userParent)) {
			let finalUser = user;
			delete finalUser.generalTerms;
			delete finalUser.verified;
			delete finalUser.updatedAt;
			delete finalUser.createdAt;
			delete finalUser.isActive;
			delete finalUser.nationalRegisterID;
			delete finalUser.id;
			delete finalUser.membership;
			// delete finalUser.role;


		if (finalUser.role !== "doctor" && finalUser.title === "Dr."){
			message.error('Incohérence entre le titre et le rôle');
			let inputs = document.querySelectorAll('.check-value');
			inputs.forEach(input => {
				input.classList.add("mandatory-input");
			});

		} else {
			if (finalUser.companyPhone) finalUser.companyPhone = formats.formatPhoneNumberUser(finalUser.companyPhone) ;
			finalUser.phone = formats.formatPhoneNumberUser(finalUser.phone) ;
			if (user.role !== "doctor" && finalUser.speciality) finalUser.speciality = null;
		
			requests.requestPut(`/users/${id}`, finalUser, access_token)
			.then(res => {
				let usersArray = usersData;
				let index = usersArray.map(user => user.id).indexOf(res.data.id);
				usersArray[index] = res.data;
				saveUsersComp(usersArray);
				updateUserParent(res.data);
				message.success('Les informations ont bien été mises à jour')
			})
			.catch(error => {
				console.log(error.response);
				message.error(`Erreur lors de la mise à jour des informations: ${error.response.data.message}`)
			});
			cancelForm();
		};
		} else {
			message.info('Aucune modification n\'a été apportée');
		};
		
	};


	return(
		<div className="update-user-main">
			<div className="modal-header">
				<h1>{`${userParent?.title} ${userParent?.firstName} ${userParent?.lastName}`} - Mise à jour</h1>
			</div>
			<div className="mandatory-div">
				<small className="mandatory">{language['form.mandatory.fields']} </small>
			</div>
			
			{user.businessID ? <p>{language['users.businessId']}: {user.businessID} </p> : <></>}
			<Form
				form={form}
				name="update-user"
				onFinish={handleForm}
				{...formItemLayout}

				scrollToFirstError>
				
				<div className="update-user-form">
					<div>
						<Form.Item
							name="role"
							label="Rôle"
							className="form-item-space"
							rules={[{required: true, message: language["form.mandatory.role"]},]}
							>
							<Select className="input-form-special" onChange={handleRole} >
								<Option value="doctor">{language["users.role.doctor"]}</Option>
								<Option value="admin">{language["users.role.admin"]}</Option>
								<Option value="paramed">{language["users.role.paramed"]}</Option>
								<Option value="sales">{language["users.role.sales"]}</Option>
							</Select>
						</Form.Item>
					</div>
					<div>
						<Form.Item
							name="title"
							label="Titre"
							className="form-item-space"
							rules={[{required: true, message: language["form.mandatory.title"]},]}
							>
							<Select className="input-form-special check-value" value={user.title} onChange={handleState('title')} >
								<Option value="Dr.">{language["users.title.doctor"]}</Option>
								<Option value="M.">{language["users.title.mister"]}</Option>
								<Option value="Mme.">{language["users.title.miss"]}</Option>
								<Option value="Pr.">{language["users.title.professor"]}</Option>
							</Select>
						</Form.Item>
						<Form.Item
							name="firstName"
							label="Prénom"
							className="form-item-space"
							rules={[{required: true, message: language["form.mandatory.firstName"]},]}
							>
							<Input className="input-form-special" onChange={handleState('firstName')} />
						</Form.Item>
						<Form.Item
							name="lastName"
							label="Nom"
							className="form-item-space"
							rules={[{required: true, message: language["form.mandatory.name"]},]}
							>	
							<Input className="input-form-special" onChange={handleState('lastName')} />
						</Form.Item>

						<Form.Item
								name="email"
								label="Email"
								className="form-item-space"
								rules={[
									{required: true, message: language["form.mandatory.email"]},
								]}
								>
							<Input className="input-form-special" onChange={handleState('email')} />
						</Form.Item>
						<Form.Item
								name="phone"
								label="Téléphone"
								className="form-item-space"
								rules={[{required: true, message: language["form.mandatory.user.phone"]},]}
								>
							<Input className="input-form-special" onChange={handleState('phone')} />
						</Form.Item>


				{user.role === "doctor" ? 
						<Form.Item
							name="specialty"
							label="Spécialité"
							className="form-item-space"
							>
							<Input className="input-form-special" onChange={handleState('speciality')} />
						</Form.Item>
					:
					<></>
					}
						<Form.Item
							name="companyName"
							label="Lieu de travail"
							className="form-item-space"
							>
								<Input className="input-form-special" onChange={handleState('companyName')} /> <br/>
							</Form.Item>
						<Form.Item
							name="companyEmail"
							label="Email du travail"
							className="form-item-space"
							>
							<Input className="input-form-special" onChange={handleState('companyEmail')}/> <br/>
						</Form.Item>
						<Form.Item
							name="companyPhone"
							label="Téléphone du travail"
							className="form-item-space"
							>
							<Input className="input-form-special" onChange={handleState('companyPhone')} /> 
						</Form.Item>
						<Form.Item
							name="street"
							label="Adresse"
							className="form-item-space"
							>
							<Input className="input-form-special" onChange={handleState('street')} /> 
						</Form.Item>
						<Form.Item
							name="zipcode"
							label="Code postal"
							className="form-item-space"
							>							
							<Input className="input-form-special" onChange={handleState('zipcode')} />
						</Form.Item>
						<Form.Item
							name="city"
							label="Ville "
							className="form-item-space"
							>						
							<Input className="input-form-special" onChange={handleState('city')} />
						</Form.Item>
				
					</div> 

					</div>
					<div className="button-div">
						<Button type="primary" htmlType="submit" >{language['common.save']} </Button>
					</div>
				</Form>

		</div>
)};

export default connect(
	(state) => ({
		usersData: state.users,
		access_token: state.common.token
	}),
	(dispatch) => ({
		saveUsersComp: data => dispatch(usersActions.updateUsers(data))
	})
) (UpdateUser);