import React from "react";
import { SendSmsDoctor, SendSmsPatient, MedAlert, ConditionVariables, ConditionMedEventLocation, CopilotConversationStart} from "../_index";

const UpdateActioncard = ({action, medAlerts, cancelForm, goBackParent, updateActionParent, locations}) => {
	return (
		<>
			{action.type === "sms.send.patient" ? (
				<SendSmsPatient action={action} cancelForm={cancelForm} saveActionParent={updateActionParent} goBackParent={goBackParent}  />	): <> </>} 

			{action.type === "sms.send.doctor" ? (
				<SendSmsDoctor action={action} cancelForm={cancelForm} saveActionParent={updateActionParent} goBackParent={goBackParent}  />	): <> </>} 

			{action.type === "medAlert" ? (
				<MedAlert action={action} cancelForm={cancelForm} saveActionParent={updateActionParent} goBackParent={goBackParent} medAlerts={medAlerts}  />	): <> </>}

			{action.type === "condition.variable" ? (
				<ConditionVariables action={action} cancelForm={cancelForm} saveActionParent={updateActionParent} goBackParent={goBackParent} /> ): <> </>}  

			{action.type === "condition.med-event.location" ? (
				 <ConditionMedEventLocation action={action} cancelForm={cancelForm} saveActionParent={updateActionParent} goBackParent={goBackParent} locations={locations}/>): <> </>}
			
			{action.type === "copilot.conversation.start" ? (
				 <CopilotConversationStart action={action} cancelForm={cancelForm} saveActionParent={updateActionParent} goBackParent={goBackParent} locations={locations}/>): <> </>}

		</>
	)
};

export default UpdateActioncard;