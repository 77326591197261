import React, { useState, useEffect } from "react";
import { Input, Button, Radio, Form } from "antd";
import { language, formItemLayout } from "../_index.js"

const { TextArea } = Input;
const RadioGroup = Radio.Group;

const UpdateMedAlert = ({medAlert, updateItemParent, cancelForm}) => {

	const initState = {
		label: "", 
		description:"",
		type: "",  
	};

	const [form] = Form.useForm();
	const [uMedAlert, setuMedAlert] = useState(initState);

	useEffect(() => {
		setuMedAlert({...medAlert});
		Object.entries(medAlert).map(([key, value]) => {
			form.setFieldsValue({[key]: value})
		});
	}, []);


// ==================== MED ALERTS FUNCTIONS ==================== //
// ------------------ Handling of the state  ------------------ //
	const handleState = (prop) => (event) => {
		setuMedAlert({...uMedAlert, [prop]: event.target.value });
	};


// ------------------ Send updated medAlert to parents  ------------------ //
	const updateMedAlert = () => {
		if (JSON.stringify(medAlert) !== JSON.stringify(uMedAlert)){
			let finalAlert = uMedAlert;
			delete finalAlert.ProjectId;
			delete finalAlert.createdAt;
			delete finalAlert.updatedAt;
			delete finalAlert.id;
			updateItemParent(uMedAlert, medAlert.id);
		} else {
			cancelForm();
		}
	};

	return (
		<div className="update-project">
			<div className="center-title-div">
				<h3>{language["common.update"]}: {medAlert.label}</h3>
			</div>

			<Form 
				{...formItemLayout}
				name="create-med-alert"
				onFinish={updateMedAlert}
				scrollToFirstError
				form={form}
				className="create-location-main inside-modal"
				>
				<Form.Item
					name="label"
					label="Nom"
					className="form-item-space"
					rules={[{required: true, message: language["form.mandatory.name"]},]}
					>
					<Input className="input-form" onChange={handleState('label')} />
				</Form.Item>
				
				<Form.Item
					name="description"
					label="Description"
					className="form-item-space"
					>
					<TextArea className="input-form" onChange={handleState('description')} />
				</Form.Item>
				<Form.Item
					name="type"
					label="Type d'alerte"
					className="form-item-space"
					>
					<RadioGroup className="radio-access">
						<Radio value="pined" className="radio-form" onClick={handleState('type')}>Pined</Radio>
						<Radio value="detail" className="radio-form" onClick={handleState('type')}>Detail</Radio>
					</RadioGroup>
				</Form.Item>

				<div className="button-div">
					<Form.Item>
						<Button type="primary" size="small" htmlType="submit" >Ok</Button>
					</Form.Item>			
				</div>
			</Form>
		</div>
	)
};

export default UpdateMedAlert;