import React from "react";
import { language } from "../_index.js"

const ConditionMedEventLocationCard = ({action, deleteActionParent, showModalParent, locations, type}) => {
	
	
	const location = locations.filter(loc => loc.id === action.parameters.locationId)[0];

	const showModal = (e) => {
		e.preventDefault();
		showModalParent(action)
	};

	const deleteAction = () => {
		deleteActionParent(action);
	};
	
	return(
		<div className="action-card">
			<div className="flex-between">
				<i onClick={deleteAction} className="icon icon-close icon-hover pointer icon-delete-action"/>
			<div className="action-title">
				<h3>{action.name} </h3> 
				<span>({action.type})</span>
			</div>
			{type === "update" ? <span/> : <i onClick={(e) => showModal(e, action.type)} className="icon icon-edit icon-hover pointer icon-edit-action"/>}
		</div>				
				<span><span className="bold">{language["location"]}: </span> {location ? <span> {location.name} {location.comment ? `(${location.comment})` : <> </>}</span> : <span className="mandatory-color"> À COMPLÉTER </span> }  </span>
		</div>
	)
};

export default ConditionMedEventLocationCard;