import React, {useState} from "react";
import {Button, Table} from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { formats, language } from "../_index.js";

const ListUsers = ({usersData}) => {

  const [state, setState] = useState({
		filteredInfo: null,
		sortedInfo: null,
	});

  let {sortedInfo, filteredInfo} = state;
  sortedInfo = sortedInfo || {};
  filteredInfo = filteredInfo || {};


  const data = usersData.map(user => ({
    id: user.id,
    name: `${user.firstName} ${user.lastName}`,
    role: formats.formatRole(user.role),
    contact: formats.formatPhoneNumber(user.phone),
    profile: <Link key={user.id} to={`/user/${user.id}`}> <div className="user-link"> Voir </div></Link>
    }
  ));	

  const columns = [{
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    sorter: (a, b) => a.id - b.id,
    sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order,
  }, 
    {title: 'Nom',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => a.name.split(" ")[1].localeCompare(b.name.split(" ")[1]),
    sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
  }, 
  {
    title: 'Rôle',
    dataIndex: 'role',
    key: 'role',
    filters: [
      {text: 'Doctor', value: `${formats.formatRole('doctor')}`},
      {text: 'Paramédical', value: `${formats.formatRole('paramed')}`},
      {text: 'Admin', value: `${formats.formatRole('admin')}`},
      {text: 'Sales', value: `${formats.formatRole('sales')}`},
    ],
    filteredValue: filteredInfo.role || null,
    onFilter: (value, record) => record.role.includes(value),
    sorter: (a, b) => a.role.localeCompare(b.role),
    sortOrder: sortedInfo.columnKey === 'role' && sortedInfo.order,
  }, 
  {
    title: 'Contact',
    dataIndex: 'contact',
    key: 'contact',
  },
  {
    title: "Profil",
    dataIndex: "profile",
    key: "profile"
  }];


// ==================== TABLE FUNCTIONS ==================== //

  const handleChange = (pagination, filters, sorter) => {
    setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  const clearFilters = () => {
    setState({...state, filteredInfo: null});
  };

  const clearAll = () => {
    setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };
 

  return (
    <div className="gx-card list-users display-card">
      <div className="table-operations">
        <Button onClick={clearAll}>{language["users.remove.filters.sort"]} </Button>
      </div>
      <Table className="gx-table-responsive" columns={columns} dataSource={data} pagination={false} onChange={handleChange}/>
    </div>
  );
};


export default connect (
  (state) => ({
    usersData: state.users
  })
) (ListUsers);