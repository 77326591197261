import React, { useState, useEffect } from "react";
import { Button, Input, Radio, Select, message } from "antd";
import { ArrowBackIosNewRoundedIcon, language, genFunctions } from "../_index";

const RadioGroup = Radio.Group;
const Option = Select.Option;


const MedAlert = ({saveActionParent, cancelForm, medAlerts, goBackParent, action}) => {
	const initState = {
		name: "", 
		type: "medAlert",
		ui: null,
		label: "",
		MedAlertId:"",
	};
	
	let hideItems = document.querySelectorAll('.hide');
	hideItems.forEach(item => {
		item.style.display='none'
	});

	const [medAlert, setMedAlert] = useState(initState);


	useEffect(() => {
		if (action) setMedAlert({...medAlert, 
			name: action.name,
			ui: action.parameters.ui,
			label: action.parameters.label,
			MedAlertId: action.parameters.MedAlertId,
			id: action.id
		});

		let hideItems = document.querySelectorAll('.hide');
		hideItems.forEach(item => {
			item.style.display='none'
		});

	}, [action]);

	const handleState = (prop) => (event) => {
		setMedAlert(event.target ? {...medAlert, [prop]: event.target.value} : {...medAlert, [prop]: event});
	};

	const goBack = (e) => {
		goBackParent(e);
	};

	const handleMandatory = (e) => {
		e.target.classList.remove('mandatory-input');
	};

	const saveAction = () => {
		if (!medAlert.name || !medAlert.MedAlertId){
			const fields = document.querySelector('#mandatory-fields');
			genFunctions.showItems(fields);

			let inputs = document.querySelectorAll('.check-value');
			inputs.forEach(input => {
				if (!medAlert[input.title]) input.classList.add("mandatory-input");
			});
		} else {
			let action = {
				type: medAlert.type,
				name: medAlert.name,
				parameters: {
					ui: medAlert.ui,
					label: medAlert.label,
					MedAlertId: medAlert.MedAlertId,
				}
			};

			if (action) action.id = medAlert.id;

			saveActionParent(action);
			cancelForm();
		};
	};
	
	return(
		<div className="choose-action-card">
		{!action ? <Button className="go-back-action" size="small" onClick={goBack}> <span className="icon-back"><ArrowBackIosNewRoundedIcon style={{fontSize: "14px"}} /></span> <span className="back">{language["common.goBack"]} </span></Button> : <> </>}
			<h4>{language["medAlert"]} </h4>
			<small id="mandatory-fields" className="mandatory-color hide">Vous n'avez pas rempli tous les champs obligatoires</small>

			<div>
				<Input placeholder="Nom *" label="name" className="workflow-input check-value" value={medAlert.name} onChange={handleState('name')} onClick={handleMandatory}	/>
				<Input placeholder="Label" label="label" className="workflow-input" value={medAlert.label} onChange={handleState('label')} />

				<h5> {language["medAlert.type"]} </h5>
				<RadioGroup label="type" name="Type d'alerte" value={medAlert.ui} className="radio-workflow">
					<div>
						<Radio value="badge-success" className="radio-form" onChange={handleState('ui')}>{language["medAlert.success"]}</Radio>
						<Radio value="badge-warning" className="radio-form" onChange={handleState('ui')}>{language["medAlert.warning"]}</Radio>
					</div>
					<div>
						<Radio value="badge-info" className="radio-form" onChange={handleState('ui')}>{language["medAlert.info"]}</Radio>
						<Radio value="badge-danger" className="radio-form" onChange={handleState('ui')}>{language["medAlert.danger"]}</Radio>
					</div>
				</RadioGroup>


				<h5> {language["medAlert"]} * </h5>
				<Select label="alert" title="MedAlertId" className="workflow-input check-value" value={medAlert.MedAlertId} onChange={handleState('MedAlertId')} onClick={handleMandatory} >
					{medAlerts.map(alert => (
						<Option key={alert.id} value={alert.id}>{alert.label} </Option>
					))}
				</Select>

				<div className="button-div">
					<Button type="primary" size="small" onClick={saveAction}>Ok</Button>
				</div>
			</div>
		</div>
	)};

export default MedAlert;