import React from "react";
import {Route, Switch} from "react-router-dom";

import ListProjects from '../components/projects/List/ListProjects';
import DisplayProject  from "../components/projects/Display/DisplayProject";
import CreateProject from '../components/projects/Create/CreateProject';
import ListUsers from '../components/users/List/ListUsers';
import DisplayUser from "../components/users/Display/DisplayUser";
import CreateUser from '../components/users/Create/CreateUser';
import CreateWorkflow from "../components/workflows/Create/CreateWorkflow";
import UpdateWorkflow from "../components/projects/UpdateWorkflow/UpdateWorkflow";

const App = () => {
  return (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={[`/projects/list`, '/']} exact component={ListProjects}/>
      <Route path={`/projects/create`} exact component={CreateProject}/>
      <Route path={`/project/:id`} exact component={DisplayProject}/>
      <Route path={`/users/list`} component={ListUsers}/>
      <Route path={`/users/create`} component={CreateUser}/>
      <Route path={`/user/:id`} component={DisplayUser}/>
      <Route path={`/project/:id/create-workflow`} component={CreateWorkflow}/>
      <Route path={`/project/:id/update-workflow/:workflowid`} component={UpdateWorkflow}/>
    </Switch>
  </div>
  )
  };

export default App;
