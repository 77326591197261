import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import ReactFlow, { removeElements, addEdge, Background, Handle } from 'react-flow-renderer';
import { BasicModal, ChooseAction, MedAlert, SendSmsDoctor, CopilotConversationStart, SendSmsPatient, ConditionVariables, 
	ConditionMedEventLocation, ActionCard, AddIcon, FlowCondition, UpdateActionCard, language, ConfirmModal } from '../_index';
import { Button, message } from 'antd';

const initialElements = [
  {
    id: 'Start0',
    type: 'input',
    data: { label: 'Start' },
    position: { x: 300, y: 25 },
    style: { 
      width: "25rem",
      borderRadius: "10px",
      border: "#038FDE 1px solid",
      boxShadow: "0 0 5px 5px fade(@black-color, 03%)",
    },
  }
];

const Workflow = forwardRef(({ medAlerts, locations, getElementsParents, saveActionParent, workflow, updateActionParent, deleteActionParent, project, stopLoadedParent }, ref) => {
  const modalRef = useRef();
  const confirmRef = useRef();

  const [elements, setElements] = useState(initialElements);
  const [content, setContent] = useState("");

  useEffect(() => {
  // if workflow is imported: loaded = true;
    if (workflow.loaded) {
      createEdge()
    } else {
      let elementsArray = [...elements];
      elementsArray.forEach((el, i) => {

        if (el.id[0] !== "e"){
          let action = workflow.actions.filter(action => action.id === el.id)[0];
          if (action && action.type !== "internal.start"){
            elementsArray[i] = {
              id: el.id,
              data: {name: action.name,  label: <ActionCard action={action} deleteActionParent={onElementsRemove} medAlerts={medAlerts} showModalParent={preShowModal} locations={locations} project={project} /> },
              position: el.position,
              className:"workflow-card"
            }
          };
        };
      });

      setElements(elementsArray);
    };
  }, [workflow]);

  useImperativeHandle(ref, () => ({
    elements
  }), [elements])

  const showModal = (e, type, action) => {
    if (e.target) e.preventDefault();
		if (type === "action") setContent(<ChooseAction project={project} cancelForm={closeModal} openActionParent={openAction} type={action} />)
    else if (type === "condition") setContent(<FlowCondition updateConnectionParent={updateConnection} />)
    else if (type === "update") setContent(<UpdateActionCard cancelForm={closeModal} action={action} goBackParent={goBack} updateActionParent={updateElement} medAlerts={medAlerts} locations={locations} project={project} />)
		
    modalRef.current.showModal();
	};

  const closeModal = () => {
		modalRef.current.closeModal();
	};


// ==================== FLOW FUNCTIONS ==================== //
// ------------------ Add card to flow ------------------ //
  const saveAction = (action) => {
    let item = {
      id: `${action.name}${workflow.actions.length}`,
      data: {name: action.name,  label: <ActionCard action={action} deleteActionParent={onElementsRemove} medAlerts={medAlerts} locations={locations} showModalParent={preShowModal} project={project}/> },
      position: {
        x: 0,
        y: 0,
      },
      className:"workflow-card"
    };
    setElements((els) => els.concat(item));
    action.id = item.id;
    saveActionParent(action);
  };

// ------------------ Delete action card from flow ------------------ //
  const onElementsRemove = (elementsToRemove) => {
    if (elementsToRemove.length) {
      setElements((els) => removeElements(elementsToRemove, els));
      if (elementsToRemove[0].id[0] !== "e") deleteActionParent(elementsToRemove[0].id)
    }
    else {
      let element = elements.filter(el => el.id === elementsToRemove.id)
      setElements((els) => removeElements(element, els));
      deleteActionParent(elementsToRemove.id);
    };
  };

// ------------------ Add "next" or "onFailure" to the edge ------------------ //
  const updateConnection = (type) => {
    let params = JSON.parse(localStorage.getItem('params'));
    params.label = type;
    
    setElements((els) => addEdge(params, els)); 
    closeModal();
  };

// ------------------ Connect two cards ------------------ //
  const onConnect = (params) =>{
    params.id = `e-${+new Date()}`
    params.labelStyle= {
      textTransform: "uppercase",
      fontSize: "16px",
    };    

    let source = workflow.actions.filter(action => action.id === params.source)[0];
    if (source){
      if (source.type === "condition.variable" || source.type === "condition.med-event.location"){
        showModal("e", "condition");
        localStorage.setItem('params', JSON.stringify(params));
      } else {
        setElements((els) => addEdge(params, els)); 
      };
    } else {
      message.error("n'existe pas");
      console.log(params);
    }
  };

// ==================== CREATE FLOW FUNCTIONS ==================== //
// ------------------ Create elements if workflow is imported ------------------ //
  const createEdge = () => {
    		let flowChart = [
    ];

    const promise = new Promise((resolve, reject) => {
      workflow.actions.forEach((action, i) => {
        let item = {
          id: `${action.name}${i}`,
          data: {name: action.name, label:<ActionCard action={action} deleteActionParent={onElementsRemove} medAlerts={medAlerts} showModalParent={preShowModal} locations={locations} project={project} /> },
          position: { x: 400, y: 20},
          className: "workflow-card"
        };
        flowChart.push(item);
				action.id = item.id;
      });
      resolve(flowChart);
    });
    promise.then(res => {
      Object.entries(workflow.connections).forEach(([key, value]) => {
        let action1 = findAction1(res, key);
        if (workflow.connections[key].next && !workflow.connections[key].onFailure){
          let action2 = findAction2(res, key, "next", workflow.connections);
          let item = createItem(action1, action2)
          res.push(item);

          let index = res.map(action => action.id).indexOf(action2.id);
          action2.position.y = action1.position.y + 150 ; 
          res[index] = action2;

        } else if (workflow.connections[key].onFailure && !workflow.connections[key].next ){
          let action2 = findAction2(res, key, "onFailure", workflow.connections);
          let item = createItem(action1, action2, "onFailure");
          res.push(item);

          let index = res.map(action => action.id).indexOf(action2.id);
          action2.position.y = action1.position.y + 150 ; 
          res[index] = action2;

        } else if (workflow.connections[key].next && workflow.connections[key].onFailure){
          let action2 = findAction2(res, key, "next", workflow.connections);
          let action3 = findAction2(res, key, "onFailure", workflow.connections);

          let item1 = createItem(action1, action2, "next");
          let item2 = createItem(action1, action3, "onFailure");
          res.push(item1, item2);

          let index = res.map(action => action.id).indexOf(action2.id);
          action2.position.x = action1.position.x -150 ; action2.position.y = action1.position.y + 200 ; 
          res[index] = action2;

          let index2 = res.map(action => action.id).indexOf(action3.id);
          action3.position.x = action1.position.x + 150 ; action3.position.y = action2.position.y;
          res[index2] = action3;
        };
      });

      setElements(res);
      stopLoadedParent();
    });
  };

  const findAction1 = (array, key) => {
    let action1 = array.filter(item => item.data?.name == key)[0];
    return action1;
  };

  const findAction2 = (array, key, type, connections) => {
    let action2 = array.filter(item => item.data?.name === connections[key][type][0].name)[0];

    return action2;
  };

  const createItem = (action1, action2, label) => {
    let item ={
      id: `e${action1.id}-${action2.id}`,
      source: action1.id,
      target: action2.id,
      labelStyle: {
        textTransform: "uppercase",
        fontSize: "16px",
      }
    };
    if (label) item.label = label;

    return item;
  }
  


// ==================== MODAL FUNCTIONS ==================== //
// ------------------ Display the selection of actions ------------------ //
	const openAction = (value) => {
    let component = components.filter (comp => comp.value === value)[0];
		closeModal();
		
		setContent(component.component);
		modalRef.current.showModal();
	};

// ------------------ Going back to selection of actions ------------------ //
  const goBack = (e) => {
    closeModal();
    showModal(e, "action");
  };

  const preShowModal = (action) => {
    showModal("e", "update", action);
  };

const updateElement = (action) => {
  updateActionParent(action)
};
  
// ==================== save FUNCTIONS ==================== //
  const components = [
		{value: "sms.send.patient", component: <SendSmsPatient cancelForm={closeModal} saveActionParent={saveAction} goBackParent={goBack} />},
		{value: "sms.send.doctor", component: <SendSmsDoctor cancelForm={closeModal} saveActionParent={saveAction}  goBackParent={goBack} />},
		{value: "medAlert", component: <MedAlert cancelForm={closeModal} saveActionParent={saveAction} medAlerts={medAlerts}  goBackParent={goBack} />},
		{value: "copilot.conversation.start", component: <CopilotConversationStart cancelForm={closeModal} saveActionParent={saveAction}  goBackParent={goBack} />},
		{value: "condition.variable", component: <ConditionVariables cancelForm={closeModal} saveActionParent={saveAction}  goBackParent={goBack} />},
		{value: "condition.med-event.location", component: <ConditionMedEventLocation cancelForm={closeModal} saveActionParent={saveAction} locations={locations}  goBackParent={goBack} />}
	];

  return (
    <div className="workflow-stream">
      <div className="main-workflow">
          <ReactFlow
            elements={elements}
            onElementsRemove={onElementsRemove}
            onConnect={onConnect}
            deleteKeyCode={46} /* 'delete'-key */
          >
          <Background
          variant="dots"
          gap={22}
          size={1}
          />
          <Handle
          position="bottom"
           />
          </ReactFlow>
      </div>


      <button onClick={(e) => showModal(e, "action", "action")} className="btn-no-style add-icon-btn add-action" size="large"> <span className="add-icon"><AddIcon /></span>{language["workflow.addAction"]} </button>
      <button onClick={(e) => showModal(e, "action", "condition")} className="btn-no-style add-icon-btn add-action" size="large"> <span className="add-icon"><AddIcon /></span>{language["workflow.addCondition"]} </button>

      <BasicModal ref={modalRef} content={content} />
    </div>
  );
});

export default Workflow;
