import React from "react";
import { connect } from "react-redux";
import { utils } from "../_index";

const ConditionVariablesCard = ({action, variablesData, deleteActionParent, showModalParent, type}) => {

	const showModal = (e) => {
		e.preventDefault();
		showModalParent(action)
	};

	const deleteAction = () => {
		deleteActionParent(action)
	};

	return(
		<div className="action-card">
			<div className="flex-between">
			<i onClick={deleteAction} className="icon icon-close icon-hover pointer icon-delete-action"/>
			<div className="action-title">
				<h3>{action.name} </h3> 
				<span>({action.type})</span>
			</div>
			{type === "update" ? <span/> : <i onClick={(e) => showModal(e, action.type)} className="icon icon-edit icon-hover pointer icon-edit-action"/>}
		</div>

			<div>
				{action.parameters.conditions.map((variable, i) => (
					<div key={`variable-card-${i}`} className="variable-item">
						<h5 className="variable-title">{utils.findVariableName(variable.VariableId, variablesData)}: </h5>
							{ Object.entries(variable).map(([key, value], i) => (
								key !== "VariableId" && value ? (
									<span key={`variable-desc-card-${i}`}>	<span >{utils.findKeyName(key) } {utils.findValue(value)} </span> <br/> </span>
								):( <> </>)
							))}
					</div>
				))}
			</div>			
</div>
	)
};


export default connect (
	(state) => ({
		variablesData: state.workflows.variables
	})
) (ConditionVariablesCard);