import React, { useState , useEffect} from "react";
import { Input, Button, Form } from "antd";
import { language, formItemLayout } from "../_index.js"

const UpdateLocation = ({location, updateItemParent, cancelForm}) => {

	const initState = {
			name: "", 
			comment:"",
			number: "", 
			street: "",
			zipcode:"",
			city: "", 
			state: "",
			country:"",
			phone:"",
	};


	const [form] = Form.useForm();
	const [uLocation, setuLocation] = useState(initState);

	useEffect(() => {
			setuLocation({...location});
			Object.entries(location).map(([key, value]) => {
				form.setFieldsValue({[key]: value})
			});
	}, []);


// ==================== LOCATION FUNCTIONS ==================== //
// ------------------ Handling of the state  ------------------ //
	const handleState = (prop) => (event) => {
		setuLocation({...uLocation, [prop]: event.target.value});
	};

// ------------------ Send updated location to parent  ------------------ //
	const updateLocation = () => {
		if (JSON.stringify(location) !== JSON.stringify(uLocation)){
				let finalLocation = uLocation;
				delete finalLocation.ProjectId;
				delete finalLocation.createdAt;
				delete finalLocation.updatedAt;
				delete finalLocation.id
				updateItemParent(uLocation, location.id);	
		} else {
			cancelForm();
		}
	};

	return (
		<div>
			<div className="center-title-div">
				<h3>{language["common.update"]}: {location.name}</h3>
			</div>

			<Form 
				form={form}
				{...formItemLayout}
				name="update-med-alert"
				onFinish={updateLocation}
				scrollToFirstError
				className="create-location-main inside-modal"
				>
				<Form.Item
					name="name"
					label="Nom"
					className="form-item-space"
          rules={[{required: true, message: language["form.mandatory.name"]},]}
					>
					<Input className="input-form" onChange={handleState('name')} />
				</Form.Item>
				
				<Form.Item
          name="comment"
          label="Commentaire"
          className="form-item-space"
          >
					<Input className="input-form" onChange={handleState('comment')} />
				</Form.Item>
				
				<Form.Item
          name="number"
          label="Numéro"
          className="form-item-space"
          >
					<Input className="input-form" onChange={handleState('number')} />
				</Form.Item>
				
				<Form.Item
          name="street"
          label="Rue"
          className="form-item-space"
          >
					<Input className="input-form" onChange={handleState('street')} />
				</Form.Item>
				
				<Form.Item
          name="zipcode"
          label="Code postal"
          className="form-item-space"
          >
					<Input className="input-form" onChange={handleState('zipcode')} />
				</Form.Item>
				
				<Form.Item
          name="city"
          label="Ville"
          className="form-item-space"
          >
					<Input className="input-form" onChange={handleState('city')} />
				</Form.Item>
				
				<Form.Item
          name="state"
          label="Région"
          className="form-item-space"
          >
					<Input className="input-form" onChange={handleState('state')} />
				</Form.Item>
				
				<Form.Item
          name="country"
          label="Pays"
          className="form-item-space"
          >
					<Input className="input-form" onChange={handleState('country')} />
				</Form.Item>
				
				<Form.Item
          name="phone"
          label="Téléphone"
          className="form-item-space"
          >
						<Input className="input-form" onChange={handleState('phone')} />
					</Form.Item>
					

				<div className="button-div">
					<Form.Item>
						<Button type="primary" size="small" htmlType="submit" >Ok</Button>
					</Form.Item>
								
				</div>
			</Form>
		</div>
	)
};

export default UpdateLocation;