const initialState = {
    phoneNumbers: []
};

export const SAVE_PHONE_NUMBERS_ACTION = "SAVE_PHONE_NUMBERS_ACTION";

export function phoneNumbersReducer(state = initialState, action) {
    switch(action.type){
        case SAVE_PHONE_NUMBERS_ACTION : 
            return action.phoneNumbers
        default: 
            return state
    };
};