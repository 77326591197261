import React, { useEffect , useState, useRef } from "react";
import ReactFlow, { removeElements, addEdge, Background, Handle } from 'react-flow-renderer';
import { AddIcon, ActionCard, ChooseAction, BasicModal, SendSmsDoctor, SendSmsPatient, MedAlert, CopilotConversationStart, ConditionVariables, ConditionMedEventLocation,
  FlowCondition, UpdateActionCard , language, ConfirmModal} from "../_index.js";
import { Button } from "antd";

const initialElements = [
  {
    id: 'Start0',
    type: 'input',
    data: { name: "Start", label: 'Start', type: "internal.start" },
    position: { x: 400, y: 25 },
    className: "workflow-card"
  }
];
const UpdateFlow = ({ project, workflow, medAlerts, locations, deleteActionParent, saveActionParent, getElementsParents, updateActionParent}) => {
  const modalRef = useRef();
  const confirmRef = useRef();

  const [content, setContent] = useState("");
  const [elements, setElements] = useState(initialElements);
  const [loaded, setLoaded] = useState(true);

  useEffect(() => {
    if (loaded) {
      createEdge();
    };
  }, [workflow]);

    
  // ==================== FLOW FUNCTIONS ==================== //
    const createEdge = () => {
      let flowChart = [...initialElements];
  
      const promise = new Promise((resolve, reject) => {
        workflow.actions.map((action,i) => {
          if (action.type !== "internal.start"){
            let item = {
              id: `${action.name}${i}`,
              data: {name: action.name, label: <ActionCard type={"update"} project={project} action={action} deleteActionParent={onElementsRemove} medAlerts={medAlerts} locations={locations} showModalParent={preShowModal} />, type:action.type,},
              position: { x: 400, y: 20},
              className: "workflow-card"
            };
            flowChart.push(item);
          };
        });
        resolve(flowChart);
      });
  
      promise.then(res => {
        Object.entries(workflow.connections).map(([key, value]) => {
          let action1 = findAction1(res, key);
          if (workflow.connections[key].next && !workflow.connections[key].onFailure){
            let action2 = findAction2(res, key, "next");
            let item = action1.data.type.includes('condition') ? createItem(action1, action2, "next") : createItem(action1, action2);
            res.push(item);
  
            let index = res.map(action => action.id).indexOf(action2.id);
            action2.position.y = action1.data.type === "sms.send.patient" || action1.type === "sms.send.doctor" ? action1.position.y + 200 : action1.position.y + 100 ;
            if (action1.position.x !== 400) action2.position.x = action1.position.x
            res[index] = action2;
  
          } else if (workflow.connections[key].onFailure && !workflow.connections[key].next ){
            let action2 = findAction2(res, key, "onFailure");
            let item = action1.data.type.includes('condition') ? createItem(action1, action2, "onFailure") : createItem(action1, action2);
            res.push(item);
  
            let index = res.map(action => action.id).indexOf(action2.id);
            action2.position.y = action1.type === "send.sms.patient" || action1.type === "send.sms.doctor" ? action1.position.y + 200 : action1.position.y + 100 ;
            res[index] = action2;
  
          } else if (workflow.connections[key].next && workflow.connections[key].onFailure){
            let action2 = findAction2(res, key, "next");
            let action3 = findAction2(res, key, "onFailure");
  
            let item1 = createItem(action1, action2, "next");
            let item2 = createItem(action1, action3, "onFailure");
            res.push(item1, item2);
  
            let index = res.map(action => action.id).indexOf(action2.id);
            action2.position.x = action1.position.x -150 ; action2.position.y = action1.position.y + 170 ; 
            res[index] = action2;
  
            let index2 = res.map(action => action.id).indexOf(action3.id);
            action3.position.x = action1.position.x + 150 ; action3.position.y = action2.position.y;
            res[index2] = action3;
          };
        });
        
        setElements(res);
        setLoaded(false);
      });
    };

    const findAction1 = (array, key) => {
      let action1 = array.filter(item => item.data?.name == key)[0];
      return action1;
    };
  
    const findAction2 = (array, key, type) => {
      let action2 = array.filter(item => item.data?.name === workflow.connections[key][type][0].name)[0];
  
      return action2;
    };
  
    const createItem = (action1, action2, label) => {
      let item ={
        id: `e${action1.id}-${action2.id}`,
        source: action1.id,
        target: action2.id,
        labelStyle: {
          textTransform: "uppercase",
          fontSize: "14px",
        }
      }
      if (label) item.label = label;
  
      return item;
    }
    
// ==================== MODAL FUNCTIONS ==================== //
  const showModal = (e, type, action) => {
    if (e.target) e.preventDefault();
		if (type === "action") setContent(<ChooseAction project={project} cancelForm={closeModal} openActionParent={openAction} type={action} />)
    else if (type === "condition") setContent(<FlowCondition updateConnectionParent={updateConnection} />)
    else if (type === "update") setContent(<UpdateActionCard cancelForm={closeModal} action={action} goBackParent={goBack} updateActionParent={updateElement} medAlerts={medAlerts} locations={locations} />)
		
    modalRef.current.showModal();
	};

  const preShowModal = (action) => {
    showModal("e", "update", action);
  };  

  const closeModal = () => {
		modalRef.current.closeModal();
	};

// ==================== FLOW FUNCTIONS ==================== //
// ------------------ Add card to flow ------------------ //
  const saveAction = (action) => {
    let item = {
      id: `${action.name}${workflow.actions.length}`,
      data: {name: action.name,  label: <ActionCard type={"update"} action={action} deleteActionParent={onElementsRemove} medAlerts={medAlerts} locations={locations} showModalParent={preShowModal} project={project} /> },
      position: {
        x: 0,
        y: 0,
      },
      className:"workflow-card"
    };

    setElements((els) => els.concat(item));
    action.id = item.id;
    saveActionParent(action);
};

// ------------------ Delete action card from flow ------------------ //
  const onElementsRemove = (elementsToRemove) => {
    if (elementsToRemove.length) {
      setElements((els) => removeElements(elementsToRemove, els));
      if (elementsToRemove[0].id[0] !== "e") deleteActionParent(elementsToRemove[0].id)
    } else {
      setElements((els) => removeElements([elementsToRemove], els));
      deleteActionParent(elementsToRemove.id);
    };
  };

// // ------------------ Sand updated action to parent ------------------ //
  const updateElement = (action) => {
    updateActionParent(action);
  };

// ------------------ Add "next" or "onFailure" to the edge ------------------ //
  const updateConnection = (type) => {
    let params = JSON.parse(localStorage.getItem('params'));
    params.label = type;
    
    setElements((els) => addEdge(params, els)); 
    closeModal();
  };

// ------------------ Connect two cards: if condition, call updateConnection ------------------ //
  const onConnect = (params) =>{
    params.id = `e-${+new Date()}`    
    let source = workflow.actions.filter(action => action.id === params.source)[0];
    
    if (source.type === "condition.variable" || source.type === "condition.med-event.location"){
      showModal("e", "condition");
      params. labelStyle = {
        textTransform: "uppercase",
        fontSize: "14px",
      };
      localStorage.setItem('params', JSON.stringify(params));
    } else {
      setElements((els) => addEdge(params, els)); 
    };
  };

// ==================== MODAL FUNCTIONS ==================== //
// ------------------ Display the selection of actions ------------------ //
const openAction = (value) => {
  let component = components.filter (comp => comp.value === value)[0];
  closeModal();
  
  setContent(component.component);
  modalRef.current.showModal();
};

// ------------------ Going back to selection of actions ------------------ //
const goBack = (e) => {
  closeModal();
  showModal(e, "action");
};

const checkFlow = () => {
  if (elements.length === 1) {
    confirmRef.current.showModal()
  } else {
    saveWorkflow();
  }
};


const saveWorkflow = () => {
  getElementsParents(elements);
};


const components = [
  {value: "sms.send.patient", component: <SendSmsPatient cancelForm={closeModal} saveActionParent={saveAction} goBackParent={goBack} />},
  {value: "sms.send.doctor", component: <SendSmsDoctor cancelForm={closeModal} saveActionParent={saveAction}  goBackParent={goBack} />},
  {value: "medAlert", component: <MedAlert cancelForm={closeModal} saveActionParent={saveAction} medAlerts={medAlerts}  goBackParent={goBack} />},
  {value: "copilot.conversation.start", component: <CopilotConversationStart cancelForm={closeModal} saveActionParent={saveAction}  goBackParent={goBack} />},
  {value: "condition.variable", component: <ConditionVariables cancelForm={closeModal} saveActionParent={saveAction}  goBackParent={goBack} />},
  {value: "condition.med-event.location", component: <ConditionMedEventLocation cancelForm={closeModal} saveActionParent={saveAction} locations={locations}  goBackParent={goBack} />}
];

  return (
    <div className="workflow-stream">
      <div className="main-workflow">
          <ReactFlow
            elements={elements}
            onElementsRemove={onElementsRemove}
            onConnect={onConnect}
            deleteKeyCode={46} 
          >
          <Background
          variant="dots"
          gap={22}
          size={1}
          />
          <Handle
          position="bottom"
          />
          </ReactFlow>
      </div>


      <button onClick={(e) => showModal(e, "action", "action")} className="btn-no-style add-icon-btn add-action" size="large"> <span className="add-icon"><AddIcon /></span>{language["workflow.addAction"]} </button>
      <button onClick={(e) => showModal(e, "action", "condition")} className="btn-no-style add-icon-btn add-action" size="large"> <span className="add-icon"><AddIcon /></span>{language["workflow.addCondition"]} </button>

      <div className="button-div button-workflow">
        <Button type="primary" size="large" onClick={checkFlow}> {language["common.save"]}</Button>
      </div>
      <BasicModal ref={modalRef} content={content} />
      <ConfirmModal ref={confirmRef} content={ <div>{language["no.flow.update"]}</div>} button={true} confirmParent={saveWorkflow} />
    </div>

  )
};

export default UpdateFlow;