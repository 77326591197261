const formatDate = (date) => {

    let newDate = date.split('T')[0];
    newDate = newDate.split('-');
    newDate = `${newDate[2]}/${newDate[1]}/${newDate[0]}`

    return newDate;
  };

const formatRole = (role) => {
  let newRole;
  switch (role) {
  case 'doctor':
      newRole = "Docteur"
      break;
  case 'admin':
      newRole = "Admin"
      break;
  case 'paramed':
      newRole = "Paramédical"
      break;
  case 'sales':
    newRole = "Sales"
    break;
  default:
      newRole = "NC"
  };

  return newRole;
};

const formatPhoneNumberUser = (number) => {
  let newPhoneNumber;
  if (number[0] === "0"){
    newPhoneNumber = number.substring(1, number.length);
    newPhoneNumber = `+33${newPhoneNumber}`;
  } else if (number[0] === "3") {
    newPhoneNumber = `+${number}`;
  } else {
    newPhoneNumber = number;
  };
  return newPhoneNumber;
};

const formatPhoneNumberTable = (number) => {
  let newPhoneNumber;

  if (number[0] === "0"){
    newPhoneNumber = number.substring(1, number.length);
    newPhoneNumber = `33${newPhoneNumber}`
  } else if (number[0] === "+"){
    newPhoneNumber = number.substring(1, number.length);
  } else {
    newPhoneNumber = number
  };    
  return newPhoneNumber;
};

const formatPhoneNumber = (number) => {
  let newPhoneNumber;

  if (number[0] === "+") {
    newPhoneNumber = number.substring(3, number.length);
    newPhoneNumber = `0${newPhoneNumber}`
  } else if (number[0] === "3") {
    newPhoneNumber = number.substring(2, number.length);
    newPhoneNumber = `0${newPhoneNumber}`    
  } else {
    newPhoneNumber = number
  }

  return newPhoneNumber;
};

const functions = {formatDate, formatRole, formatPhoneNumber,
  formatPhoneNumberTable, formatPhoneNumberUser};
  
export default functions;