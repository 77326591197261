import React, { useState, useEffect } from "react";
import { Form, Input, Button } from "antd";
import { language, formItemLayout } from "../_index.js"

const CreateLocation = ({project, cancelForm, saveItemParent, locationData}) => {

	const initState = {
			name: undefined, 
			comment:undefined,
			number: undefined, 
			street: undefined,
			zipcode:undefined,
			city: undefined, 
			state: undefined,
			country:undefined,
			phone:undefined,
	};	

	const [form] = Form.useForm();
	const [location, setLocation] = useState(initState);

	useEffect(() => {
		if (locationData) setLocation(locationData);
	}, [locationData, project]);


// ==================== FORM FUNCTIONS ==================== //
// ------------------ Handling of the state ------------------ //
	const handleState = (prop) => (event) => {
		setLocation({...location, [prop]: event.target.value });
	};

// ------------------ Send new location to parent ------------------ //
	const saveLocation = () => {
			Object.entries(location).map(([key, value]) => {
					if (!value) delete location[key]
			});
			saveItemParent(location, 'locations');
			setLocation(initState);
			form.setFieldsValue(initState);
			cancelForm();
	};


	return (
		<div>
			<div className="center-title-div">
				<h3 className="center-title"> {language["locations.new"]}</h3>
			</div>

			<Form
				form={form}
				{...formItemLayout}
				name="create-location"
				onFinish={saveLocation}
				scrollToFirstError
				className="create-location-main inside-modal">

				<Form.Item
				label="Nom"
				name="name"
				className="form-item-space"
				rules={[{required: true, message: language["form.mandatory.name"]},]}
				>
					<Input value={location.name} className= "input-form" onChange={handleState('name')} />
				</Form.Item>

				<Form.Item
					label="Commentaire"
					name="comment"
					className="form-item-space"
				>
					<Input label="comment" value={location.comment} className="input-form" onChange={handleState('comment')} /> <br/>
				</Form.Item>
				<Form.Item
					label="Numéro"
					name="comment"
					className="form-item-space"
				>
				<Input label="number" value={location.number} className="input-form" onChange={handleState('number')} /> <br/>
				</Form.Item>

				<Form.Item
					label="Rue"
					name="comment"
					className="form-item-space"
				>
					<Input label="street" value={location.street} className="input-form" onChange={handleState('street')} /> <br/>
				</Form.Item>

				<Form.Item
					label="Code postal"
					name="comment"
					className="form-item-space"
				>
					<Input label="zipcode" value={location.zipcode} className="input-form" onChange={handleState('zipcode')} /> <br/>
				</Form.Item>

				<Form.Item
					label="Ville"
					name="comment"
					className="form-item-space"
				>
					<Input label="city" value={location.city} className="input-form" onChange={handleState('city')} /> <br/>
				</Form.Item>

				<Form.Item
					label="Région"
					name="comment"
					className="form-item-space"
				>
					<Input label="state" value={location.state} className="input-form" onChange={handleState('state')} /> <br/>
				</Form.Item>

				<Form.Item
					label="Pays"
					name="comment"
					className="form-item-space"
				>
					<Input label="country" value={location.country} className="input-form" onChange={handleState('country')} /> <br/>
				</Form.Item>

				<Form.Item
					label="Téléphone"
					name="comment"
					className="form-item-space"
				>
					<Input label="phone" value={location.phone} className="input-form" onChange={handleState('phone')} /> <br/>
				</Form.Item>

			<div className="button-div">
					<Form.Item>
						<Button type="primary" size="small" htmlType="submit" >Ok</Button>
					</Form.Item>
				</div>
			</Form>
		</div>
	)
};

export default CreateLocation;