import React, { useState } from "react";
import { Form, Input, Button, message, Radio } from "antd";
import { language, formItemLayout } from "../_index"

const { TextArea } = Input;
const RadioGroup = Radio.Group;

const CreateMedAlert = ({cancelForm, saveItemParent}) => {

	const initState = {
		label: "", 
		description:" ",
		type: "", 
	};

	const [form] = Form.useForm();
	const [medAlert, setMedAlert] = useState(initState);

// ==================== FORM FUNCTIONS ==================== //
// ------------------ Handling of the state ------------------ //
	const handleState = (prop) => (event) => {
		setMedAlert({...medAlert, [prop]: event.target.value });
	};
	
// ------------------ Send new medAlert to parent ------------------ //
	const saveMedAlert = () => {
			Object.entries(medAlert).map(([key, value]) => {
				if (!value) delete medAlert[key]
		});
		saveItemParent(medAlert, "medAlerts");
		setMedAlert(initState);
		form.setFieldsValue(initState);
		cancelForm();
	};

	return (
		<div>
			<div className="center-title-div">
				<h3>{language["medAlerts.new"]} </h3>
			</div>

			<Form 
			form={form}
			{...formItemLayout}
			name="create-med-alert"
			onFinish={saveMedAlert}
			scrollToFirstError
			className="create-location-main inside-modal">

				<Form.Item 
				label="Nom"
				name="label"
				className="form-item-space"
				rules={[{required: true, message:language["form.mandatory.name"]},]}
				>
					<Input className="input-form" onChange={handleState('label')} />
				</Form.Item>

				<Form.Item
					label="Description"
					name="description"
					className="form-item-space"
				>
					<TextArea className="input-form" onChange={handleState('description')} />
				</Form.Item>
				
				<Form.Item
					label={language["medAlert.type"]}
					name="type"
					className="form-item-space"
						rules={[{required: true, message:language["form.mandatory.type"]},]}
				>
				<RadioGroup className="radio-access" className="radio-med-alert" >
					<Radio value="pined" className="radio-form" onClick={handleState('type')} >Pined</Radio>
					<Radio value="detail" className="radio-form" onClick={handleState('type')} >Detail</Radio>
				</RadioGroup>
				</Form.Item>

				<div className="button-div">
					<Form.Item>
						<Button type="primary" size="small" htmlType="submit" >Ok</Button>
					</Form.Item>
				</div>

			</Form>
		</div>
	)
};

export default CreateMedAlert;