import React from "react";
import { Popover} from "antd";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { CommonActions } from "../_index.js";

const UserProfile = ({deleteTokenComp }) => {

  const history = useHistory();

  const user = JSON.parse(localStorage.getItem('user'));
    
  const logOut = () => {
    deleteTokenComp();
    history.push('/login-bo');
  };

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={logOut}>Déconnexion</li>
    </ul>
  );

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <span className="gx-avatar-name">{user?.firstName} {user?.lastName} <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
      </Popover>
    </div>
  )
};

export default connect(
  (state) => ({ }),
  (dispatch) => ({
    deleteTokenComp: () => dispatch(CommonActions.deleteToken())
  })
) (UserProfile);
