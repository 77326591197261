import React, { useRef, useEffect, useState } from "react"; 
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { message } from "antd";
import { language, BasicModal, ChangeAccess, ConfirmModal, formats, requests, projectsActions } from "../_index.js"

const UserCard = ({user, project, usersData, projectsData, deleteUserProjectComp, access_token}) => {
	const modalRef = useRef();
	const confirmRef = useRef();

	// console.log(usersData);

	const [completeUser, setCompleteUser] = useState({});
	const [userProject, setUserProject] = useState({ access: ""});
	const [content, setContent] = useState('')
	
	useEffect(() => {
		let userOne = usersData.filter(u => u.id === user.id)[0]
		setCompleteUser(userOne);
		if (user.id) {
			requests.request(`/projects/${project.id}/users/${user.id}`, access_token)
			.then(res => {
				setUserProject(res.data);
			})
		};
	}, [projectsData]);


// ==================== MODAL FUNCTIONS ==================== //
	const showModal = (data) => {
		setContent(<ChangeAccess project={project} cancelForm={closeModal} userProject={userProject} user={user} updateAccessParent={updateAccess} />)
		data === "user" ? modalRef.current.showModal() : confirmRef.current.showModal();
	};

	const closeModal = () => {
		modalRef.current.closeModal();
	};

// ==================== USERS FUNCTIONS ==================== //
	const deleteUser = () => {
		requests.requestDelete(`/projects/${project.id}/users/${user.id}`, access_token)
		.then(res => {
			deleteUserProjectComp(res.data.ProjectId, res.data.UserId);
			message.success(`${completeUser.firstName} ${completeUser.lastName} a bien été supprimé(e) du projet`)
		})
		.catch(error => {
			console.log(error.response);
			message.error(`Problème avec la suppression de l'utilisateur: ${error}`);
		})
	};

	const updateAccess = (access) => {
		setUserProject({...userProject, access})
	};


// ==================== GO BACK FUNCTIONS ==================== //
	const saveBack = () => {
		let goback = `/project/${project.id}`;
		localStorage.setItem('goBack', goback);
	};

  return(
		<div className="user-card gx-card-widget gx-card-profile-sm" >
			<div className="user-infos-project" >
				<div className="user-infos-1" onClick={() => showModal("user")}>	
					<div className="user-infos-2a">
						{completeUser.title ? <Link to={`/user/${completeUser.id}`} onClick={saveBack}>{completeUser.title} {completeUser.firstName} {completeUser.lastName} </Link>: ''}
						<p className="gx-mb-0 capitalize">{userProject.access ? userProject.access : language["common.no.access"]} </p>
					</div>
					<div className="user-infos-2b">
						<p className="gx-mb-0">{completeUser.email ? completeUser.email : language["common.no.email"]} </p>
						<p className="gx-mb-0">{completeUser.phone ? formats.formatPhoneNumber(completeUser.phone) : language["common.no.phone"]} </p>
					</div>
				</div>
				<i className="icon icon-trash user-infos-3 icon-hover" onClick={() => showModal("confirm")}/>
			</div>
			
			<BasicModal ref={modalRef} content={content}/>
			<ConfirmModal ref={confirmRef} content={ <div>{language["project.deleteUser"]} </div>} button={true} confirmParent={deleteUser} />
		</div>
)};

export default connect(
	(state) => ({
		usersData: state.users,
		projectsData: state.projects,
		access_token: state.common.token
	}),
	(dispatch) => ({
		deleteUserProjectComp: (projectId, userId) => dispatch(projectsActions.deleteUserProject(projectId, userId))
	})
) (UserCard);