import React from "react";
import { Radio } from "antd";
import { language } from "../_index.js"

const RadioGroup = Radio.Group;

const FlowCondition = ({updateConnectionParent}) => {

  const value = null;

  const connectionType = (e) => {
    updateConnectionParent(e.target.value);
  };
    
  return (
    <div className="inside-modal">
      <h4>{language["workflow.actions.connection"]} </h4>

      <RadioGroup label="type" name="Connection" value={value} onChange={connectionType} className="radio-workflow">
        <Radio className="radio-form" value="next">Next</Radio>
        <Radio className="radio-form" value="onFailure" >onFailure</Radio>
      </RadioGroup>
    </div>
  )
};

export default FlowCondition;